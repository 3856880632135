<template>
  <div class="contact-campaigns">
    <!-- KPIS section -->
    <div class="contact-campaigns__kpis">
      <div class="contact-campaigns__kpis__title">
        {{ $t("CONTACT_DETAILS.CAMPAIGNS.TITLE") }}
      </div>
      <div class="contact-campaigns__kpis__subtitle">
        {{ $t("CONTACT_DETAILS.CAMPAIGNS.SUBTITLE", {channel: channel}) }}
      </div>
      <div
        v-loading="kpisLoading"
        class="contact-campaigns__kpis__cards"
      >
        <!-- open_rate -->
        <KpiCard
          v-if="channel === 'email'"
          icon="drafts"
          :kpi="formatRate(kpis.open_rate)"
          :is-rate="true"
          :name="$t('CONTACT_DETAILS.CAMPAIGNS.OPEN_RATE')"
          :info="$t('CONTACT_DETAILS.CAMPAIGNS.OPEN_RATE_TOOLTIP')"
        />
        <!-- click_rate -->
        <KpiCard
          :img-url="clickLogo"
          :kpi="formatRate(kpis.click_rate)"
          :is-rate="true"
          :name="$t('CONTACT_DETAILS.CAMPAIGNS.CLICK_RATE')"
          :info="$t('CONTACT_DETAILS.CAMPAIGNS.CLICK_RATE_TOOLTIP')"
        />
        <!-- reaction_rate -->
        <KpiCard
          v-if="channel === 'email'"
          icon="bolt"
          :kpi="formatRate(kpis.reaction_rate)"
          :is-rate="true"
          :name="$t('CONTACT_DETAILS.CAMPAIGNS.REACTION_RATE')"
          :info="$t('CONTACT_DETAILS.CAMPAIGNS.REACTION_RATE_TOOLTIP')"
        />
        <!-- commercial_pressure -->
        <KpiCard
          icon="timer"
          :kpi="round(kpis.commercial_pressure)"
          :name="$t('CONTACT_DETAILS.CAMPAIGNS.COMMERCIAL_PRESSURE')"
          :info="$t('CONTACT_DETAILS.CAMPAIGNS.COMMERCIAL_PRESSURE_TOOLTIP')"
        />
      </div>
    </div>
    <!-- Last campaigns -->
    <div class="contact-campaigns__campaigns">
      <div class="contact-campaigns__campaign__header">
        <div
          class="contact-campaigns__campaign__header__description"
        >
          <div
            class="contact-campaigns__campaigns__header__description__title"
          >
            {{ $t("CONTACT_DETAILS.CAMPAIGNS.LAST_CAMPAIGNS", {channel: channel}) }}
          </div>
          <div class="contact-campaigns__campaigns__header__description__subtitle">
            {{ $t("CONTACT_DETAILS.CAMPAIGNS.LAST_CAMPAIGNS_SUBTITLE") }}
          </div>
        </div>
        <div class="contact-campaigns__campaigns__header__period">
          <el-select
            v-model="period"
            filterable
            :popper-append-to-body="false"
            @change="getCampaigns"
          >
            <el-option
              v-for="periodOption in periodOptions"
              :key="periodOption[1]"
              :label="$t(`CONTACT_DETAILS.CAMPAIGNS.PERIOD_SELECT.${periodOption[0]}`)"
              :value="periodOption[1]"
            />
          </el-select>
        </div>
        <div class="contact-campaigns__campaigns__header__search">
          <el-input
            v-model="search"
            clearable
            :placeholder="$t('CONTACT_DETAILS.CAMPAIGNS.SEARCH_PLACEHOLDER')"
            @input="onSearch"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-search"
            />
          </el-input>
        </div>
      </div>
      <!-- table content -->
      <div class="contact-campaigns__campaigns__table">
        <el-table
          v-if="campaigns.length > 0 || campaignsLoading"
          v-loading="campaignsLoading"
          :data="campaigns"
          :row-class-name="isExpandableRow"
        >
          <!-- Expanded row -->
          <el-table-column type="expand">
            <template slot-scope="props">
              <div
                v-for="(key, index) in expandableKeys"
                :key="index"
              >
                <div
                  v-if="props.row[key]"
                  class="contact-campaigns__campaigns__table__expanded-row"
                >
                  {{ getTranslation(key) }}
                  <el-tooltip
                    effect="dark"
                    placement="top"
                    transition=""
                    :open-delay="400"
                    :hide-after="0"
                    :content="props.row[key] | moment('LLL')"
                  >
                    <span>
                      {{ props.row[key] | moment('LL') }}
                    </span>
                  </el-tooltip>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- table data -->
          <el-table-column
            :label="$t('CONTACT_DETAILS.CAMPAIGNS.GROUP')"
            prop="group.name"
            width="90"
          >
            <template slot-scope="scope">
              <el-tooltip
                effect="dark"
                :content="scope.row.group.name"
                placement="top"
              >
                <AvatarHolder
                  :avatar-url="scope.row.group.logo_url"
                  :display-name="scope.row.group.name"
                  :width="25"
                  :height="25"
                  :font-size="8"
                />
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('CONTACT_DETAILS.CAMPAIGNS.IDM')"
            prop="id"
            width="90"
          />
          <el-table-column
            prop="type_id"
            width="30"
          >
            <template slot-scope="scope">
              <el-tooltip
                placement="top"
                :content="getCampaignTypeString(scope.row.type_id)"
              >
                <i
                  v-if="scope.row.type_id === campaignTypes.ONE_SHOT"
                  class="material-icons type desktop-type"
                >
                  send_outline
                </i>
                <i
                  v-else-if="scope.row.type_id === campaignTypes.AUTOMATIC"
                  class="material-icons type desktop-type"
                >
                  cached_outline
                </i>
                <i
                  v-else
                  class="material-icons type desktop-type"
                >
                  settings_outline
                </i>
              </el-tooltip>
              <i
                v-if="scope.row.type_id === campaignTypes.ONE_SHOT"
                class="material-icons type mobile-type"
              >
                send_outline
              </i>
              <i
                v-else-if="scope.row.type_id === campaignTypes.AUTOMATIC"
                class="material-icons type mobile-type"
              >
                cached_outline
              </i>
              <i
                v-else
                class="material-icons type mobile-type"
              >
                settings_outline
              </i>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('CONTACT_DETAILS.CAMPAIGNS.CAMPAIGN_NAME')"
            prop="name"
            width="200"
            show-overflow-tooltip
          />
          <el-table-column
            :label="$t('CONTACT_DETAILS.CAMPAIGNS.SENT_DATE')"
            prop="sent_date"
            width="130"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <el-tooltip
                effect="dark"
                placement="top"
                :open-delay="400"
                transition=""
                :hide-after="0"
                :content="scope.row.sent_date | moment('LLL')"
                :disabled="!scope.row.sent_date"
              >
                <div>
                  {{ scope.row.sent_date | moment('LL') }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
            v-if="channel === 'email'"
            width="100"
            :label="$t('CONTACT_DETAILS.CAMPAIGNS.OPEN')"
            prop="open_date"
          >
            <template slot-scope="scope">
              <i
                v-if="scope.row.open_date"
                class="material-icons positive-done"
              >done</i>
              <i v-else>-</i>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('CONTACT_DETAILS.CAMPAIGNS.CLICK')"
            width="100"
            prop="click_date"
          >
            <template slot-scope="scope">
              <i
                v-if="scope.row.click_date"
                class="material-icons positive-done"
              >done</i>
              <i v-else>-</i>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            :label="$t('CONTACT_DETAILS.CAMPAIGNS.UNSUBSCRIPTION')"
            prop="unsubscribe_date"
          >
            <template slot-scope="scope">
              <i
                v-if="scope.row.unsubscribe_date"
                class="material-icons negative-done"
              >done</i>
              <i v-else>-</i>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            :label="$t('CONTACT_DETAILS.CAMPAIGNS.HARD_BOUNCE')"
            prop="hardbounce_date"
          >
            <template slot-scope="scope">
              <i
                v-if="scope.row.hardbounce_date"
                class="material-icons negative-done"
              >done</i>
              <i v-else>-</i>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            :label="$t('CONTACT_DETAILS.CAMPAIGNS.SOFT_BOUNCE')"
            prop="softbounce_date"
          >
            <template slot-scope="scope">
              <i
                v-if="scope.row.softbounce_date"
                class="material-icons negative-done"
              >done</i>
              <i v-else>-</i>
            </template>
          </el-table-column>
        </el-table>
        <NoData
          v-else-if="campaigns.length === 0 && !campaignsLoading"
          type="alternative"
          :text="$t('CONTACT_DETAILS.CAMPAIGNS.NO_DATA')"
        />
      </div>
      <div class="pagination">
        <span class="total">{{ total }} {{ $t('MISC.RESULTS') }}</span>
        <el-pagination
          v-if="campaigns.length > 0"
          layout="prev, pager, next, sizes"
          class="text-center"
          :total="total"
          :page-size="limit"
          :page-sizes="[10, 20, 30, 40, 50]"
          :current-page.sync="page"
          @current-change="getCampaigns"
          @size-change="onSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from 'lodash-es'
import { AvatarHolder, NoData, KpiCard }  from '@/components'
import config from '@/config'
import clickLogo from '@/assets/img/click.svg'

export default {
  name: 'ContactCampaigns',
  components: { AvatarHolder, NoData, KpiCard },
    props: {
      groupId: { type: String, default: null },
  },
  data() {
    return {
      clickLogo: clickLogo,
      campaignTypes: config.CAMPAIGN_TYPES,
      campaigns: [],
      page: 1,
      limit: 10,
      search: '',
      total: 0,
      expandableKeys: [
        'open_date',
        'click_date',
        'unsubscribe_date',
        'hardbounce_date',
        'softbounce_date'
      ],
      period: config.CAMPAIGN_PERIODS.ALL,
      periodOptions: Object.entries(config.CAMPAIGN_PERIODS),
    }
  },
  computed: {
    kpisLoading () {
      return this.$store.getters['contacts/kpisLoading']
    },
    campaignsLoading () {
      return this.$store.getters['contacts/campaignsLoading']
    },
    kpis () {
      return this.$store.getters['contacts/kpis']
    },
    channel () {
      return this.$route.params.channel === 'mobile' ? 'SMS' : this.$route.params.channel
    }
  },
  watch: {
    groupId () {
      this.getCampaigns()
    }
  },
  created () {
    this.getCampaigns()
  },
  methods: {
    isExpandableRow (object) {
      const expandableValues = Object.values(pick(object.row, this.expandableKeys))
      if (expandableValues.every((val) => val === null)) {
        return 'no-expand'
      }
    },
    getCampaigns () {
      const params = {
        channel: this.$route.params.channel,
        id: this.$route.params.contactId,
        groupId: this.groupId,
        page: this.page,
        search: this.search,
        limit: this.limit,
        fromDate: this.generateFromDate(),
      }
      this.$store.dispatch('contacts/getCampaigns', params)
      .then((res) => {
        this.campaigns = res.objects
        this.total = res.meta.total
      })
      .catch(err => {
        if (err.status === 500) {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        }
      })
    },
    formatRate(number) {
      if (number) {
        return parseFloat((number * 100).toFixed(2))
      }
      return 0
    },
    getTranslation(key) {
      return this.$t(`CONTACT_DETAILS.CAMPAIGNS.${key.toUpperCase()}_EXPANDED`)
    },
    round(number) {
      if (number) {
        return parseFloat(number.toFixed(1))
      }
      return 0
    },
    onSizeChange(newSize) {
      this.limit = newSize
      this.getCampaigns()
    },
    onSearch() {
      this.page = 1
      this.getCampaigns()
    },
    generateFromDate() {
      if (!this.period || this.period === config.CAMPAIGN_PERIODS.ALL) {
        return null
      }
      let currentDate = new Date()
      const fromDate = new Date()
      if (this.period === config.CAMPAIGN_PERIODS.ONE_WEEK){
        fromDate.setDate(currentDate.getDate() - 7)
      } else if (this.period === config.CAMPAIGN_PERIODS.ONE_MONTH){
        fromDate.setMonth(currentDate.getMonth() - 1)
      } else if (this.period === config.CAMPAIGN_PERIODS.THREE_MONTHS){
        fromDate.setMonth(currentDate.getMonth() - 3)
      } else if (this.period === config.CAMPAIGN_PERIODS.SIX_MONTHS){
        fromDate.setMonth(currentDate.getMonth() - 6)
      }
      return fromDate
    },
    getCampaignTypeString(type){
      if(type === config.CAMPAIGN_TYPES.ONE_SHOT){
        return this.$t('CONTACT_DETAILS.CAMPAIGNS.TYPES.ONE_SHOT')
      }

      if(type === config.CAMPAIGN_TYPES.AUTOMATIC){
        return this.$t('CONTACT_DETAILS.CAMPAIGNS.TYPES.AUTOMATIC')
      }

      if(type === config.CAMPAIGN_TYPES.TRANSACTIONAL){
        return this.$t('CONTACT_DETAILS.CAMPAIGNS.TYPES.TRANSACTIONAL')
      }

      return ''
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.contact-campaigns {
  .contact-campaigns__kpis {
    background: $--color-white;
    border: 2px solid $--color-light-gray;
    border-radius: 4px;
    padding: 20px;
    width: 100%;
    .contact-campaigns__kpis__title {
      font-size: 24px;
      padding: 10px 0;
    }
    .contact-campaigns__kpis__subtitle {
      font-size: 14px;
    }
    .contact-campaigns__kpis__cards {
      display: flex;
      margin-top: 20px;
    }
  }

  .contact-campaigns__campaigns {
    background: $--color-white;
    border: 2px solid $--color-light-gray;
    border-radius: 4px;
    padding: 20px;
    width: 100%;
    margin-top: 20px;
    .contact-campaigns__campaign__header{
      display: flex;
      .el-input {
        width: 300px;
        margin: auto;
      }
      .contact-campaigns__campaign__header__description{
        width: calc(100% - 300px);
        .contact-campaigns__campaigns__header__description__title {
          font-size: 24px;
          padding: 10px 0;
        }
        .contact-campaigns__campaigns__header__description__subtitle {
          font-size: 14px;
        }
      }
      .contact-campaigns__campaigns__header__search, .contact-campaigns__campaigns__header__period  {
        padding: 10px 0;
      }
      .contact-campaigns__campaigns__header__period {
        margin-right: 10px;
      }
    }

    .material-icons.type {
      font-size: 18px;
      margin-right: 5px;
      color: $--color-primary;
      vertical-align: middle;
      width: 18px;
    }
    .material-icons.positive-done {
        color: $--icon-color-positive;
    }
    .material-icons.negative-done {
      color: $--icon-color-negative;
    }
    .contact-campaigns__campaigns__table {
      border: solid 1px $--color-medium-gray;
      margin-top: 30px;
      ::v-deep .el-table {
        padding: 10px 20px 0 20px;
        .no-expand .el-icon {
          display: none;
        }
        .no-expand .el-table__expand-icon {
          pointer-events: none;
        }
      }
      .contact-campaigns__campaigns__table__expanded-row {
        margin-bottom: 10px
      }
    }

  }
  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-top: 30px;
    .el-pagination {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      text-align: center;
    }
    .total {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      min-width: 200px;
    }
    ::v-deep .el-pagination__sizes {
      position: absolute;
      right: 0px;
    }
  }
}


@media only screen and (min-width: $--mobile-width) {
  .mobile-type{
    display: none;
  }
}

@media only screen and (max-width: $--mobile-width) {
  .contact-campaigns {
    margin-top: 30px;
    ::v-deep .contact-campaigns__kpis__cards {
      flex-wrap: wrap;
      max-width: 350px;
    }
  }
  .desktop-type{
    display: none;
  }
}
</style>
