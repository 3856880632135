const config = {
  API_URL: '/api',

  CHANNELS: {
    EMAIL: 1,
    SMS: 2
  },

  MOBILE_PREFIX_MODE: {
    UNIQUE: 1,
    MULTIPLE: 2
  },

  LIST_TYPES: {
    STATIC: 1,
    ACTIVE: 2,
    SUBMISSION: 3
  },
  CAMPAIGN_TYPES: {
    ONE_SHOT: 1,
    AUTOMATIC: 2,
    TRANSACTIONAL: 3
  },
  RECURRENCE_MODE: {
    NOW: 1,
    WEEK: 2,
    MONTHLY: 3
  },

  UPLOAD_TYPE: {
    LOCAL: 1,
    REMOTE: 2,
    MANUAL: 3,
    FORCED: 4
  },

  UPDATE_TYPE: {
    MERGE: 1,
    REPLACE: 2,
    APPEND: 3
  },

  RECURRENCE_MONTHLY_MODE: {
    MULTIPLE_TIMES: 1,
    ONE_TIME: 2,
  },

  DATE_OPTIONS: [
    {
      value: 'Day',
      label: 'Day',
      children: [
        {
          value: '{{DD}}',
          label: 'DD',
        }
      ]
    },
    {
      value: 'Month',
      label: 'Month',
      children: [
        {
          value: '{{MM}}',
          label: 'MM',
        }
      ]
    },
     {
      value: 'Year',
      label: 'Year',
      children: [
        {
          value: '{{YY}}',
          label: 'YY',
        },
        {
          value: '{{YYYY}}',
          label: 'YYYY',
        }
      ]
    }
  ],

  REF_ACTION_TYPES: {
    LIST_CREATED: 1,
    LIST_UPDATED: 2,
    LIST_DELETED: 3,
  },

  REF_DATA_TYPES: [{
    value: 1,
    label: 'FIELDS.DATATYPES.INT',
    component: 'el-input-number',
    type: 'number'
  },{
    value: 2,
    label: 'FIELDS.DATATYPES.FLOAT',
    component: 'el-input-number',
    type: 'number'
  },{
    value: 3,
    label: 'FIELDS.DATATYPES.NVARCHAR',
    component: 'el-input',
    type: 'string'
  },{
    value: 4,
    label: 'FIELDS.DATATYPES.DATE',
    component: 'el-date-picker',
    type: 'date',
    children: [{
      value: 'automatic',
      label: 'Automatic',
    },{
      value: '%d/%m/%Y',
      label: 'DD/MM/YYYY'
    },{
      value: '%m/%d/%Y',
      label: 'MM/DD/YYYY'
    },{
      value: '%Y/%m/%d',
      label: 'YYYY/MM/DD'
    },{
      value: '%d%m%Y',
      label: 'DDMMYYYY'
    },{
      value: '%m%d%Y',
      label: 'MMDDYYYY'
    },{
      value: '%Y%m%d',
      label: 'YYYYMMDD'
    },{
      value: '%d-%m-%Y',
      label: 'DD-MM-YYYY'
    },{
      value: '%m-%d-%Y',
      label: 'MM-DD-YYYY'
    },{
      value: '%Y-%m-%d',
      label: 'YYYY-MM-DD'
    },]
  },{
    value: 5,
    label: 'FIELDS.DATATYPES.DATETIME',
    type: 'datetime',
    component: 'el-date-picker',
    children: [{
      value: 'automatic',
      label: 'Automatic',
    },{
      value: '%Y-%m-%d %H:%M:%S',
      label: 'YYYY-MM-DD hh:mm:ss'
    },{
      value: '%Y/%m/%d %H:%M:%S',
      label: 'YYYY/MM/DD hh:mm:ss'
    },{
      value: '%Y%m%d %H:%M:%S',
      label: 'YYYYMMDD hh:mm:ss'
    }]
  },{
    value: 6,
    label: 'FIELDS.DATATYPES.BIT',
    component: 'el-radio',
    type: "boolean"
  }],

  ENTITY_TYPES: {
    CONTACT_LIST: 1,
    CONTACT_LIST_FIELDS: 2,
    IMPORT_LOG: 3,
    EXPORT_LOG: 5,
    API_IMPORT_LOG: 6
  },

  PERMISSIONS: {
    LIST_WRITE_SELF: 'contactly:role:lists:w-self',
    LIST_WRITE: 'contactly:role:lists:w',
    LIST_EXPORT: 'contactly:role:lists:export'
  },
  CONTACT_CARD_STATUS: {
    BLACKLIST: 1,
    HARDBOUNCE: 2,
    FEEDBACKLOOP: 3,
    SPAMTRAP: 4,
  },
  EMAIL_CONTACTABILITY_STATUS: {
    BAD_FORMAT: 1,
    BLACKLIST: 2,
    HARDBOUNCE: 3,
    FEEDBACKLOOP: 4,
    SPAMTRAP: 5,
    OPTOUT: 6,
    OPTIN: 7
  },
  SMS_CONTACTABILITY_STATUS: {
    BAD_FORMAT: 1,
    BLACKLIST: 2,
    HARDBOUNCE: 3,
    OPTOUT: 4,
    OPTIN: 5
  },
  CAMPAIGN_PERIODS: {
    ALL: 1,
    ONE_WEEK: 2,
    ONE_MONTH: 3,
    THREE_MONTHS: 4,
    SIX_MONTHS: 5,
  },

  SWITCH_VIEW:{
    APPS : 'apps',
    CLIENT: 'clients'
  },

  RESERVED_FIELDS: [
    "CDE",
    "CODE_ENVOI",
    "DCALCUL",
    "DMAJ",
    "DRECUEIL",
    "E",
    "ENV",
    "GRP_TEMOIN",
    "I",
    "ID_FROM",
    "ID_MAILING",
    "ID_MAILINGTELMOBILE",
    "ID_TYPECONSENTEMENT",
    "ID_TYPOLOGIE",
    "ID_UE_ENVOI",
    "ID_UT_ENVOI",
    "ID_UTIL",
    "ID_VERSION",
    "ID",
    "IDCOL",
    "IDENVOI",
    "IDU_EMAIL",
    "IDU_TELMOBILE",
    "M",
    "MM_SENDER_DOMAIN",
    "ORDRE",
    "R",
    "RG",
    "ROW_ID",
    "SEGMENT",
    "SID",
    "STATUT_EMAIL",
    "STATUT_SMS",
    "TELMOBILE",
    "UG",
  ],

  RESERVED_FIELDS_REGEX: /^(STAT_DAN_.*|STAT_DAT_.*|SYS_.*)/
}

export default config
