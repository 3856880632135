<template>
  <div class="setup-active-list">
    <div class="info-mandatory">
      <span class="asterisk">*</span> {{ $t('MANDATORY') }}
    </div>
    <NeedHelpModal
      :dialog-visible.sync="showHelpModal"
      width="500px"
      :title="$t('SETUP.FILE_REQUIREMENTS')"
      :sections="helpSections"
      :tip="$t('SETUP.FILE_REQUIREMENTS_TIP')"
    />
    <div v-if="list.errors">
      <div
        v-for="error in list.errors"
        :key="error.error"
      >
        <el-alert
          class="setup-active-list__alert"
          :title="$t('SETUP.UPLOAD.' + error.error, error.additional_info)"
          type="error"
          show-icon
          :closable="false"
        />
      </div>
    </div>
    <el-form
      ref="form"
      class="setup-active-list__form"
      label-position="top"
      :rules="rules"
      :model="fileForm"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="folder">
        <div class="step-subtitle">
          <span class="asterisk">*</span> {{ $t("SETUP.ACTIVE.FILE_FOLDER_TITLE") }}
        </div>
        <div class="step-card">
          <p class="step-paragraph">
            {{ $t("SETUP.ACTIVE.FILE_FOLDER_DESCRIPTION") }}
          </p>
          <el-select
            v-model="fileForm.folder"
            class="setup-active-list__folder"
            filterable
            placeholder=""
            @change="formChange('folder')"
          >
            <el-option
              v-for="folder in s3folders"
              :key="folder"
              :label="folder.split('/').slice(-1)[0]"
              :value="folder"
            />
          </el-select>
          <br>
          <span class="step-paragraph">{{ $t("SETUP.ACTIVE.FILE_FOLDER_HELPER") }}</span>
        </div>
      </el-form-item>

      <el-form-item
        prop="file_pattern"
        class="setup-active-list__file-item"
        style="margin-bottom: 22px"
      >
        <div class="step-subtitle">
          <span class="asterisk">*</span> {{ $t("SETUP.ACTIVE.FILE_PATTERN_TITLE") }}
        </div>
        <div class="step-card">
          <p class="step-paragraph">
            {{ $t("SETUP.ACTIVE.FILE_PATTERN_DESCRIPTION") }}
          </p>
          <el-input
            ref="file"
            v-model="fileForm.file_pattern"
            class="setup-active-list__file-item"
            :disabled="!fileForm.folder"
            @input="isFileDetected = false"
            @change="formChange('file_pattern')"
          >
            <el-button
              v-if="!isFileDetected"
              slot="append"
              type="primary"
              class="rounded-button"
              :disabled="!fileForm.folder || !fileForm.file_pattern"
              :loading="isConfirmingFile"
              @click="confirmFile"
            >
              {{ $t("BUTTON.CONFIRM") }}
            </el-button>
            <el-button
              v-else
              slot="append"
              type="primary"
              class="rounded-button"
            >
              <i class="el-icon-check el-icon-right" />
              {{ $t("BUTTON.DETECTED") }}
            </el-button>
          </el-input>
          <el-cascader
            ref="addDate"
            class="setup-active-list__date"
            :check-strictly="true"
            :placeholder="$t('SETUP.ACTIVE.FILE_ADD_DATE')"
            :options="dateOptions"
            @change="addDateVariable"
          />
          <el-button
            type="text"
            class="need-help"
            @click="showHelpModal = true"
          >
            <i class="material-icons">info_outlined</i>
            <span class="text">{{ $t("MISC.FILE_REQUIREMENTS") }}</span>
          </el-button>
        </div>
      </el-form-item>
      <div
        v-show="fileForm.file_pattern"
        class="panel"
      >
        <span class="step-subtitle">
          {{ $t("SETUP.ACTIVE.FILE_PATTERN_PREVIEW") }}
        </span>
        <br>
        <p class="step-paragraph">
          {{ generateFilepatternPreview() }}
        </p>
      </div>

      <el-alert
        v-show="displayNotFoundFileError"
        class="setup-active-list__alert"
        :title="$t('SETUP.ACTIVE.ERROR_NOT_FOUND_FILE_PATTERN')"
        type="error"
        show-icon
        :closable="false"
      />
        <div class="flex items-center gap-32" v-if="isFileDetected">
          <el-form-item
            prop="delimiter"
            :label="$t('SETUP.UPLOAD.DELIMITER_LABEL')"
          >
            <el-select
              v-model="fileForm.delimiter"
              placeholder=""
              @change="formChange('delimiter')"
            >
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_2')"
                value="&Tab;"
              />
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_3')"
                value=","
              />
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_4')"
                value=";"
              />
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_6')"
                value="|"
              />
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_5')"
                value="1"
                @change="chooseOtherDelimiter"
              />
            </el-select>
            <div
              slot="error"
              class="el-form-item__error"
            >
              {{ $t('SETUP.UPLOAD.ERROR_DELIMITER_EMPTY') }}
            </div>
          </el-form-item>
          <el-form-item
            v-if="isOtherDelimiter()"
            prop="otherDelimiter"
            :label="$t('MISC.CHARACTER')"
            :hide-required-asterisk="true"
          >
            <el-input
              v-model="fileForm.otherDelimiter"
              maxlength="1"
              style="width: 200px;"
              @change="formChange('other-delimiter')"
            />
          </el-form-item>
        </div>

      <el-form-item
        v-if="isFileDetected"
        prop="qualifier"
        :label="$t('SETUP.UPLOAD.TEXT_QUALIFIER_LABEL')"
      >
        <el-input
          v-model="fileForm.qualifier"
          maxlength="1"
          style="width: 228px"
          @change="formChange('qualifier')"
        />
      </el-form-item>

      <el-form-item style="margin-bottom: 12px;">
        <el-checkbox
          v-model="fileForm.hasHeader"
          :label="$t('SETUP.UPLOAD.FIRST_ROW_HEADER_LABEL')"
          :checked="true"
          @change="formChange"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vue from 'vue'
import config from "@/config"
import NeedHelpModal from '@/components/UI/NeedHelpModal'

export default {
  name: "SetupActiveFile",
  components: { NeedHelpModal },
  props: {
    step: { type: Number, required: true },
    list: { type: Object, default: null },
    changeDetected: { type: Boolean, default: false }
  },
  data: function() {
    var validateDelimiter = (rule, value, callback) => {
      if (value == "") {
        callback(new Error(this.$t("ERRORS.FORM_FIELD_REQUIRED")))
      } else {
        callback()
      }
    }
    var validateFilePattern = (rule, value, callback) => {
      if (value == '') {
        callback(new Error(this.$t('ERRORS.FORM_FIELD_REQUIRED')))
      } else if (!value.endsWith('.csv') && !value.endsWith('.txt')) {
        callback(new Error(this.$t('SETUP.ACTIVE.ERROR_EXTENSION_UNSUPPORTED')))
      } else if (!this.isFileDetected) {
        callback(new Error(this.$t("ERRORS.FORM_CONFIRM_FIELD_REQUIRED")))
      } else {
        callback()
      }
    }
    return {
      showHelpModal: false,
      fileForm: {
        folder: "",
        file_pattern: "",
        hasHeader: true,
        delimiter: ",",
        qualifier: "",
        otherDelimiter: ""
      },
      isOtherDelimiterSelected: false,
      isFileDetected: false,
      rules: {
        file_pattern: [{ validator: validateFilePattern, trigger: 'blur' }],
        folder: [
          { required: true, message: this.$t("ERRORS.FORM_FIELD_REQUIRED"), trigger: 'blur' }
        ],
        delimiter: [{ validator: validateDelimiter, trigger: "change" }],
        hasHeader: [{ required: false }],
        otherDelimiter: [
          { required: true, message: this.$t("ERRORS.FORM_FIELD_REQUIRED") }
        ]
      },
      dateOptions: config.DATE_OPTIONS,
      s3folders: [],
      displayNotFoundFileError: false,
      isConfirmingFile: false,
      helpSections: [{
        'title': this.$t('SETUP.UPLOAD.NEED_HELP_TITLE'),
        'content': this.$t('SETUP.UPLOAD.NEED_HELP_INFORMATION')
      }, {
        'title': this.$t('SETUP.FILE_REQUIREMENTS'),
        'content': this.$t('SETUP.FILE_REQUIREMENTS_INFO')
      }]
    }
  },
  created: function() {
    this.$store
      .dispatch("lists/getS3Folders", this.list.id)
      .then(data => {
        this.s3folders = data
        this.initForm()
      })
      .catch(() => {
        this.$notify({
          title: this.$t("ERRORS.UNEXPECTED"),
          message: this.$t("ERRORS.UNEXPECTED_DESC"),
          type: "error"
        })
      })
  },
  methods: {
    isOtherDelimiter() {
      return (this.fileForm.delimiter &&
      this.fileForm.delimiter != ';' &&
      this.fileForm.delimiter != ',' &&
      this.fileForm.delimiter != '|' &&
      this.fileForm.delimiter != '&Tab;')
    },
    handleNext(triggerSaving) {
      // if a change has been detected or the current step is the list's current step validate the form
      if (triggerSaving) {
        return new Promise(resolve => {
          this.$refs.form.validate(validated => {
            if (validated) {
              this.detectColumns()
            }
            resolve(validated)
          })
        })
      } else {
        this.$emit("update:step", 2)
      }
    },
    chooseOtherDelimiter() {
      this.fileForm.delimiter = "1"
    },
    addDateVariable(value) {
      if (!value.length) return
      var cursorPosition = this.$refs.file.$refs.input.selectionStart
      this.fileForm.file_pattern =
        this.fileForm.file_pattern.slice(0, cursorPosition) +
        value[1] +
        this.fileForm.file_pattern.slice(cursorPosition + Math.abs(0))
      this.isFileDetected = false
    },
    confirmFile() {
      this.formChange("file")
      this.isConfirmingFile = true
      this.isFileDetected = false
      this.displayNotFoundFileError = false
      let params = {
        file_name: this.fileForm.file_pattern,
        file_path: this.fileForm.folder
      }
      this.$store
        .dispatch("lists/checkS3File", { listId: this.list.id, params: params })
        .then(data => {
          this.isFileDetected = data["exist"]
          this.displayNotFoundFileError = !data["exist"]
          this.isConfirmingFile = false
          this.$refs["form"].validateField("file_pattern")
        })
        .catch(() => {
          this.isConfirmingFile = false
          this.$notify({
            title: this.$t("ERRORS.UNEXPECTED"),
            message: this.$t("ERRORS.UNEXPECTED_DESC"),
            type: "error"
          })
        })
    },
    escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()[\]{}]/g, "\\$&")
    },
    generateFilepatternPreview() {
      var file_pattern = this.fileForm.file_pattern
      var dateVariableRegex = ""
      const values = Object.values(config.DATE_OPTIONS)
      for (var i = 0; i < values.length; ++i) {
        for (var b = 0; b < values[i].children.length; ++b) {
          dateVariableRegex += `{{${values[i].children[b].label}}}`
          dateVariableRegex += !(
            i == values.length - 1 && b == values[i].children.length - 1
          )
            ? "|"
            : ""
        }
      }
      dateVariableRegex = this.escapeRegExp(dateVariableRegex)
      var dateRegex = new RegExp(dateVariableRegex, "gi")
      var result = file_pattern.replace(dateRegex, function(x) {
        var output = x.slice(2, x.length - 2)
        return Vue.moment().format(output)
      })
      return result
    },
    initForm() {
      this.fileForm.folder = this.list.file_path ? this.list.file_path : ""
      if (this.list.filename_pattern) {
        this.fileForm.file_pattern = this.list.filename_pattern
        this.isFileDetected = true
      }
      this.fileForm.qualifier = this.list.text_qualifier
        ? this.list.text_qualifier
        : ""
      this.fileForm.delimiter = this.list.delimiter || ','
      if (this.isOtherDelimiter()) {
        this.isOtherDelimiterSelected = true
        this.fileForm.otherDelimiter = this.fileForm.delimiter
        this.fileForm.delimiter = "1"
      }
    },
    detectColumns: function() {
      var listId = this.list.id
      this.displayNotFoundFileError = false
      let data = {
        delimiter: this.isOtherDelimiter()
          ? this.fileForm.otherDelimiter
          : this.fileForm.delimiter,
        has_header: this.fileForm.hasHeader,
        text_qualifier: this.fileForm.qualifier
      }
      this.$store
        .dispatch("lists/inferColumns", { listId, data: data })
        .then(() => {
          this.$emit("update:step", 2)
        })
        .catch(response => {
          if (response.exists == false) {
            this.displayNotFoundFileError = true
          } else if (!response.errors) {
            this.$notify({
              title: this.$t("ERRORS.UNEXPECTED"),
              message: this.$t("ERRORS.UNEXPECTED_DESC"),
              type: "error"
            })
          }
        })
    },
    save: function(triggerSaving) {
      if (triggerSaving) {
        return new Promise((resolve, reject) => {
          this.$refs.form.validate(validated => {
            if (validated) {
              var listId = this.list.id
              let data = {
                delimiter: this.isOtherDelimiter()
                  ? this.fileForm.otherDelimiter
                  : this.fileForm.delimiter,
                has_header: this.fileForm.hasHeader,
                text_qualifier: this.fileForm.qualifier
              }
              this.$store
                .dispatch("lists/patchList", { listId, data: data })
                .then(() => resolve(validated))
                .catch(error => reject(error))
            } else {
              resolve(validated)
            }
          })
        })
      }
    },
    formChange: function(property) {
      this.$emit("update:change-detected", true)
      switch (property) {
        case "folder": {
          this.fileForm.file_pattern = ""
          this.isFileDetected = false
          break
        }
        default:
          this.$refs.addDate.$refs.panel.clearCheckedNodes()
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/app.scss";

.setup-active-list {
  width: 616px;
  margin: 50px auto 0 auto;

  &__alert {
    margin: 18px 0px;
    word-break: break-word;

    ::v-deep .el-alert__title {
      font-size: 12px !important;
    }
  }
  &__form {
    .el-form-item__content > p {
      line-height: 20px !important;
    }
  }

  &__folder {
    width: 100%;
  }

  .need-help {
    margin-top: 12px;
    height: inherit !important;
    background-color: transparent !important;
    color: #F47957 !important;
  }
  .step-subtitle {
    font-size: 14px !important;
    color: #606266;
  }

  ::v-deep &__file-item {
    .el-input__inner {
      width: 97%;
    }
    .el-button {
      height: 40px;
      color: $--color-white;
      &.is-disabled {
        background-color: $--color-lighter-orange;
        border-color: $--color-lighter-orange
      }
      background-color: $--color-primary;
      background-color: $--color-primary;
    }
    margin-bottom: 5px;
  }

  &__radio-group {
    margin: 32px 0px;
    ::v-deep .el-radio {
      margin: 6px 0px;
    }
  }

  &__date {
    margin: 15px 10px 0 0;
    width: 200px;
  }
  &__alert {
    margin: 12px 0px;
    word-break: break-word;
  }

  &__file-requirements {
    text-decoration: underline;
  }

  .step-paragraph {
    margin: 6px 0px;
    word-break: break-word;
  }
}

.panel {
  background-color: $--color-light-gray;
  border: 1px solid $--color-medium-gray;
  border-radius: 3px;
  padding: 15px;
}
.asterisk {
  color: #F47957;
}

.info-mandatory {
  font-size: 14px;
  color: #606266;
  text-align: right;
  margin-bottom: 3px;
}

.step-card {
  padding: 5px 20px 20px;
  background: #f9f9f8;
  border-radius: 5px;
}

.rounded-button {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
