<template>
  <div
    class="list-details"
  >
    <!-- SETUP FLOW -->
    <SetupList
      v-if="hasCreationPermission && isContactList"
      :list="list"
      :updating-list="updatingList"
      :show-modal.sync="showSetupModal"
    />

    <!-- (DE)ACTIVATION MODAL -->
    <Modal
      :dialog-visible.sync="showActivationModal"
      width="500px"
      :title="list.active ? $t('DEACTIVATE.TITLE_MODAL') : $t('ACTIVATE.TITLE_MODAL')"
      :subtitle="list.active ? $t('DEACTIVATE.SUBTITLE_MODAL') : $t('ACTIVATE.SUBTITLE_MODAL')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="loadingActivate"
      type="danger"
      :action="toggleActive"
    />

    <!-- LIST DETAILS -->
    <div class="list-details__wrapper">
      <!-- LEFT SIDEBAR -->
      <div class="list-details__left">
        <ListDetailsMain
          v-if="list && !optinsLoading"
          v-loading="loading"
          :list="list"
          :optins="optins"
          :expiration-disabled="list.step && activeIndex === 'rows' && isContactList || list.updating"
        />
      </div>

      <!-- MAIN PANEL -->
      <div class="list-details__views">
        <div class="list-details__nav">
          <!-- TABS -->
          <el-menu
            mode="horizontal"
            class="list-details__tabs"
            :class="{ active: !list.step }"
            :default-active="activeIndex"
            @select="handleSelect"
          >
            <el-menu-item
              :index="availableRoutes[0]"
              :disabled="loading"
            >
              <span v-if="isContactList">
                {{ $t('LIST_DETAILS.NAV.CONTACTS') }}
              </span>
              <span v-else>
                {{ $t('LIST_DETAILS.NAV.SUBMISSIONS') }}
              </span>
            </el-menu-item>
            <el-menu-item
              :index="availableRoutes[1]"
              :disabled="loading"
            >
              {{ $t('LIST_DETAILS.NAV.ACTIVITY') }}
            </el-menu-item>

            <el-menu-item
              v-if="hasExportPermission"
              :index="availableRoutes[2]"
              :disabled="loading"
            >
              {{ $t('LIST_DETAILS.NAV.EXPORTS') }}
              <span
                v-show="newExportAvailable"
                class="list-details__tabs__dot"
              >
                •
              </span>
            </el-menu-item>
          </el-menu>

          <!-- SETTINGS BUTTON -->
          <div class="list-details__nav-buttons">
            <el-button
              v-if="list.type_id === listTypes.ACTIVE && !list.step"
              :disabled="!hasCreationPermission"
              size="medium"
              @click="showEditModal = true"
            >
              <div class="align-items">
                <i class="material-icons">edit</i>
                {{ $t('BUTTON.SETTINGS') }}
              </div>
            </el-button>

            <!-- (DE)ACTIVATION BUTTON -->
            <el-button
              v-if="list.type_id === listTypes.ACTIVE && !list.step"
              :disabled="list.updating || !hasCreationPermission"
              size="medium"
              :loading="loadingActivate"
              @click="showActivationModal = true"
            >
              <div class="align-items">
                <i class="material-icons">power_settings_new</i>
                <span v-if="list.active">{{ $t('BUTTON.ACTIVATED') }}</span>
                <span v-else>{{ $t('BUTTON.DEACTIVATED') }}</span>
              </div>
            </el-button>
          </div>
        </div>

        <!-- MODAL EDIT LIST -->
        <ModalEditList
          :show-modal.sync="showEditModal"
          :list="list"
        />

        <!-- CONTENT -->
        <div
          v-if="list && list.fields"
        >
          <transition>
            <router-view />
          </transition>
        </div>

        <!-- IF LIST IS NOT SETUP YET -->
        <div
          v-if="list.step && activeIndex === 'rows' && isContactList"
          v-loading="loading"
          class="list-details__empty"
        >
          <NoData
            type="settings"
            :width="410"
            :height="410"
            size="large"
            :btn="$t('BUTTON.FINISH_SETUP')"
            :text="$t('SETUP.UNCOMPLETED_MESSAGE')"
            :subtitle="currentStep + '/' + nbSteps + ' ' + $t('SETUP.STEPS_COMPLETED')"
            :action="setup"
            :disabled-btn="!hasCreationPermission"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SetupList,
  ListDetailsMain,
  NoData,
  Modal,
  ModalEditList
} from '@/components'
import config from '@/config'

export default {
  name: 'ListDetails',
  components: {
    SetupList,
    ListDetailsMain,
    NoData,
    Modal,
    ModalEditList
  },
  data () {
    return {
      loadingActivate: false,
      loadingInterval: false,
      loadingExportActivity: false,
      loadingExportFromDate: new Date().toISOString(),
      listTypes: config.LIST_TYPES,
      availableRoutes: ['rows', 'activity', 'exports'],
      showSetupModal: false,
      showEditModal: false,
      showActivationModal: false,
      nbSteps: 0,
      checkStatusInterval: null,
      checkExportInterval: null,
      newExportAvailable: false
    }
  },
  computed: {
    loading: function () {
      return this.$store.getters['lists/loading']
    },
    optinsLoading () {
      return this.$store.getters['groups/loading']
    },
    config: function () {
      return config
    },
    user: function () {
      return this.$store.getters['auth/user']
    },
    activeIndex () {
      return this.availableRoutes.indexOf(this.$route.name) > -1 ?
        this.$route.name :
        this.availableRoutes[0]
    },
    list () {
      return this.$store.getters['lists/list'] || {}
    },
    optins () {
      return this.$store.getters['groups/optins']
    },
    updatingList () {
      return this.$store.getters['lists/updating'] || false
    },
    hasCreationPermission () {
      return this.$store.getters['auth/hasPermissionInGroup'](this.list.group_id, config.PERMISSIONS.LIST_WRITE) ||
        (this.$store.getters['auth/hasPermissionInGroup'](this.list.group_id, config.PERMISSIONS.LIST_WRITE_SELF) &&
          this.user.id === this.list.created_by)
    },
    hasExportPermission () {
      return this.$store.getters['auth/hasPermissionInGroup'](this.list.group_id, config.PERMISSIONS.LIST_EXPORT)
    },
    isContactList () {
      return [this.listTypes.STATIC, this.listTypes.ACTIVE].indexOf(this.list.type_id) > -1
    },
    currentStep() {
      if (!this.list) {
        return 0
      }

      if (this.list.type_id === this.listTypes.ACTIVE) {
        return this.list.step
      }
      return this.list.step > 3 ? 3 : this.list.step
    },
  },
  created () {
    this.initList()
    this.checkStatusInterval = setInterval(() => {
      this.getListStatus()
    }, 10000)
    if (this.$route.name !== 'exports') {
      this.triggerExportActivityCheck()
    }
  },
  beforeRouteLeave (to, from , next) {
    // Remove list in order to remove previous list details when change list
    this.$store.dispatch('lists/resetList')
    if (this.checkStatusInterval) {
      clearInterval(this.checkStatusInterval)
    }
    if (this.checkExportInterval) {
      clearInterval(this.checkExportInterval)
    }
    next()
  },
  methods: {
    initGroupOptins () {
      this.$store.dispatch('groups/getGroupsOptins', this.list.group_id)
        .catch(() => {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        })
    },
    initList () {
      this.$store.dispatch('lists/getList', this.$route.params.listId)
      .then(() => {
        // extract query params
        this.extractQueryParams()
        if (this.list.type_id === this.listTypes.ACTIVE) {
          this.nbSteps = 4
        } else {
          this.nbSteps = 3
        }
        if (this.hasCreationPermission) {
          this.initGroupOptins()
        }
      })
      .catch(e => {
        if (e.status === 403) {
          this.$router.push({ path: '/lists' })
        }
        else {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        }
      })
    },
    triggerExportActivityCheck() {
      // check if has export permission
      this.checkExportInterval = setInterval(() => {
        this.getExportActivity()
      }, 10000)
    },
    getListStatus () {
      if (this.loadingInterval) {
        return false
      }
      this.loadingInterval = true
      this.$store.dispatch('lists/getActiveState', this.$route.params.listId)
      .then((res) => {
        // if the list is no longer being updated, fetch list to get updated data
        if (this.list.updating && res.updating === false) {
          this.$store.dispatch('lists/getList', this.$route.params.listId)
        }
        this.list.updating = res.updating
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
      .finally(() => {
        this.loadingInterval = false
      })
    },
    extractQueryParams () {
      // extract other filters as well
      let queryParams = this.$route.query
      if (queryParams.setup) {
        this.showSetupModal = true
      }
    },
    handleSelect(key) {
      // do not check if there is any available exports if the active tabs is export
      if (key === 'exports') {
        this.newExportAvailable = 0
        clearInterval(this.checkExportInterval)
      } else if (this.$route.name == 'exports') {
        this.triggerExportActivityCheck()
      }
      if (key != this.$route.name ) {
        this.$router.push({ name: key })
      }
    },
    setup () {
      this.showSetupModal = true
    },
    toggleActive () {
      if (!this.hasCreationPermission) {
        return false
      }
      this.loadingActivate = true
      let data = {
        'listId': this.list.id
      }
      let action = 'deactivate'
      if (!this.list.active) {
        action = 'activate'
        data['data'] = {'import': false}
      }
      this.$store.dispatch(`lists/${action}`, data)
      .then((res) => {
        this.list.active = res.active
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
      .finally(() => {
        this.loadingActivate = false
        this.showActivationModal = false
      })
    },
    getExportActivity () {
      if (!this.hasExportPermission) {
        return
      }
      if (this.loadingExportActivity) {
        return false
      }
      this.loadingExportActivity = true
      const params = {
        'from_date': this.loadingExportFromDate
      }
      this.loadingExportActivity = false
      this.$store.dispatch('exports/getExportActivity',
        {'listId': this.$route.params.listId,
        'params': params
      })
      .then((res) => {
        this.newExportAvailable = res.new_exports > 0
        if (this.newExportAvailable) {
          clearInterval(this.checkExportInterval)
        }
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
      .finally(() => {
        this.loadingExportActivity = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.list-details {
  overflow-x: hidden;
  color: $--text-dark;
  font-size: 12px;
  &__empty {
    background: $--color-white;
    padding: 2rem;
    border: 1px solid $--color-medium-gray;
  }
  &__wrapper {
    @include flexbox;
    @include align-items(stretch);
    min-height: calc(100% - #{$--header-height});
    width: 100%;
    height: auto;
  }
  &__left {
    left: 0;
    bottom: 0;
    top: $--header-height;
    position: absolute;
    height: 100%;
    background: $--color-white;
    width: 350px;
    border-right: 1px solid $--color-light-gray;
    overflow: auto;
  }
  &__views {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(stretch);
    position: relative;
    left: 340px;
    padding: 0 3rem 3rem;
    width: calc(100% - 340px);
    div:last-child {
      @include flex(1)
    }
  }
  &__nav {
    margin: 2rem 0 1rem 0;
    position: relative;
    min-height: 50px;
    .el-menu {
      margin-bottom: 0;
      .el-menu-item {
        &:hover, &:focus {
          background-color: transparent;
        }
      }
    }
  }
  &__nav-buttons {
    position: absolute;
    top: 30%;
    right: 0;
    transform: translateY(-50%);
    .el-button {
      padding-right: 30px;
    }
  }
  &__tabs {
    width: 100%;
    &__dot {
      position: absolute;
      right: 10px;
      bottom: 4px;
      color: $--color-primary;
      font-size: 18px;
    }
  }
}

  /* For mobile: */
@media only screen and (max-width: $--mobile-width) {
  .list-details {
    &__wrapper {
      display: block;
    }
    &__left {
      top: 0;
      position: relative;
      width: 100%;
      max-width: none;
      float: none;
      height: auto;
    }
    &__views {
      padding: 1rem;
      margin-bottom: 5rem;
      position: relative;
      left: 0;
      width: 100%;
    }
    &__nav-buttons {
      margin-top: 10px;
      position: relative;
    }
    &__nav {
      margin-bottom: 0;
      .el-menu {
        margin-bottom: 2rem;
      }
    }
  }
}
</style>
