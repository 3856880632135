<template>
  <div class="setup-import">
    <el-form
      ref="form"
      label-position="top"
      class="setup-import__form"
      :model="importForm"
      :rules="rules"
      :hide-required-asterisk="true"
    >
      <!-- IMPORT FREQUENCY -->
      <el-form-item
        prop="recurrenceMode"
        :label="$t('SETUP.SCHEDULE.FREQUENCY')"
      >
        <el-radio-group
          v-model="importForm.recurrenceMode"
          class="setup-import__form__radio-grp"
          @change="onRecurrenceChange()"
        >
          <el-radio-button :label="config.RECURRENCE_MODE.WEEK">
            {{ $t('SETUP.SCHEDULE.FREQUENCY_2') }}
          </el-radio-button>
          <el-radio-button :label="config.RECURRENCE_MODE.MONTHLY">
            {{ $t('SETUP.SCHEDULE.FREQUENCY_3') }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>

      <!-- WEEK DAYS -->
      <el-form-item
        v-if="importForm.recurrenceMode == config.RECURRENCE_MODE.WEEK"
        prop="recurrenceWeekDays"
        :label="$t('SETUP.SCHEDULE.DAYS')"
      >
        <el-checkbox-group
          v-model="importForm.recurrenceWeekDays"
          @change="formChange"
        >
          <el-checkbox label="1">
            {{ $t('SETUP.SCHEDULE.DAYS_1').substring(0,3) }}
          </el-checkbox>
          <el-checkbox label="2">
            {{ $t('SETUP.SCHEDULE.DAYS_2').substring(0,3) }}
          </el-checkbox>
          <el-checkbox label="3">
            {{ $t('SETUP.SCHEDULE.DAYS_3').substring(0,3) }}
          </el-checkbox>
          <el-checkbox label="4">
            {{ $t('SETUP.SCHEDULE.DAYS_4').substring(0,3) }}
          </el-checkbox>
          <el-checkbox label="5">
            {{ $t('SETUP.SCHEDULE.DAYS_5').substring(0,3) }}
          </el-checkbox>
          <el-checkbox label="6">
            {{ $t('SETUP.SCHEDULE.DAYS_6').substring(0,3) }}
          </el-checkbox>
          <el-checkbox label="7">
            {{ $t('SETUP.SCHEDULE.DAYS_7').substring(0,3) }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <!-- MONTHLY  -->
      <el-form-item
        v-if="importForm.recurrenceMode == config.RECURRENCE_MODE.MONTHLY"
        prop="recurrenceMonthlyMode"
        :show-message="true"
      >
        <el-radio
          v-model="importForm.recurrenceMonthlyMode"
          class="setup-import__form__recurrence-radio"
          :label="1"
          @change="formChange('recurrence-monthly-mode-1')"
        >
          {{ $t('SETUP.SCHEDULE.MONTHLY_PART_1') }}
          <el-form-item
            class="setup-import__form__recurrence-radio__recurrence-select"
            prop="recurrenceMonthlyDayOccurence"
            :show-message="true"
          >
            <el-select
              v-model="importForm.recurrenceMonthlyDayOccurence"
              class="setup-import__form__recurrence-radio__recurrence-select"
              size="small"
              :disabled="importForm.recurrenceMonthlyMode != 1"
              @change="formChange()"
            >
              <el-option
                v-for="(key, index) in dayMonthlyOccurence"
                :key="index"
                :label="$t(key)"
                :value="parseInt(index)"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            class="setup-import__form__recurrence-radio__recurrence-select"
            prop="recurrenceMonthlyDay"
            :show-message="true"
          >
            <el-select
              v-model="importForm.recurrenceMonthlyDay"
              size="small"
              :disabled="importForm.recurrenceMonthlyMode != 1"
              @change="formChange()"
            >
              <el-option
                v-for="(n, index) in weekDays"
                :key="index + 1"
                :label="n"
                :value="(index + 1).toString()"
              />
            </el-select>
          </el-form-item>
          {{ $t('SETUP.SCHEDULE.MONTHLY_PART_2') }}
        </el-radio>
        <br>
        <el-radio
          v-model="importForm.recurrenceMonthlyMode"
          class="setup-import__form__recurrence-radio"
          :label="2"
          size="small"
          @change="formChange('recurrence-monthly-mode-2')"
        >
          {{ $t('SETUP.SCHEDULE.MONTHLY_PART_1') }}
          <el-form-item
            class="setup-import__form__recurrence-radio__recurrence-select"
            prop="recurrenceMonthlyDayNumber"
            :show-message="true"
          >
            <el-select
              v-model="importForm.recurrenceMonthlyDayNumber"
              size="small"
              :disabled="importForm.recurrenceMonthlyMode != 2"
              @change="formChange()"
            >
              <el-option
                v-for="n in 31"
                :key="n"
                :label="n"
                :value="n"
              />
            </el-select>
          </el-form-item>
          {{ $t('SETUP.SCHEDULE.MONTHLY_PART_2') }}
        </el-radio>
      </el-form-item>

      <!-- IMPORT TIME -->
      <el-form-item
        prop="recurrenceTime"
        :label="$t('SETUP.SCHEDULE.TIME')"
      >
        <el-col :span="11">
          <el-time-picker
            v-model="importForm.recurrenceTime"
            format="HH:mm"
            value-format="HH:mm"
            @change="formChange()"
          />
        </el-col>
        <el-col :span="2" />
        <el-col :span="11">
          <el-select
            v-model="importForm.selectedTimezone"
            @change="onTimezoneChange"
          >
            <el-option
              v-for="(value, name) in timezones"
              :key="name"
              :label="value"
              :value="name"
            />
          </el-select>
        </el-col>
        <br>
        <p v-show="importForm.selectedTimezone != defaultTimezone">
          {{ previewTime }}  {{ $t('SETUP.SCHEDULE.TIMEZONE_PREVIEW') }}
        </p>
      </el-form-item>
      <el-form-item
        prop="updateTypeId"
      >
        <el-radio
          v-model="importForm.updateTypeId"
          :label="1"
          @change="formChange()"
        >
          {{ $t('SETUP.SCHEDULE.MODE_1') }}
        </el-radio>
        <br>
        <el-radio
          v-model="importForm.updateTypeId"
          :label="2"
          @change="formChange()"
        >
          {{ $t('SETUP.SCHEDULE.MODE_2') }}
        </el-radio>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vue from 'vue'
import config from '@/config'
import tz from '@/assets/data/tz.json'

export default {
  name: 'SetupImport',
  components: { },
  props: {
    step: { type: Number, required: true },
    list: { type: Object, default: null },
    changeDetected: { type: Boolean, default: false }
  },
  data() {
    var validateMonthlyRecurrenceDayNumber = (rule, value, callback) => {
      if (this.importForm.recurrenceMonthlyMode == 2 && !this.importForm.recurrenceMonthlyDayNumber)
      {
        return callback(new Error(this.$t('ERRORS.FORM_FIELD_REQUIRED')))
      }
      return callback()
    }
    var validateMonthlyRecurrenceDay = (rule, value, callback) => {
      if (this.importForm.recurrenceMonthlyMode == 1 && !this.importForm.recurrenceMonthlyDay)
      {
        return callback(new Error(this.$t('ERRORS.FORM_FIELD_REQUIRED')))
      }
      return callback()
    }
    var validateMonthlyRecurrenceDayOccurence = (rule, value, callback) => {
      if (this.importForm.recurrenceMonthlyMode == 1 && !this.importForm.recurrenceMonthlyDayOccurence)
      {
        return callback(new Error(this.$t('ERRORS.FORM_FIELD_REQUIRED')))
      }
      return callback()
    }
    return {
      dayMonthlyOccurence: {
        1: 'SETUP.SCHEDULE.DAY_FIRST',
        2: 'SETUP.SCHEDULE.DAY_SECOND',
        3: 'SETUP.SCHEDULE.DAY_THIRD',
        4: 'SETUP.SCHEDULE.DAY_FOURTH',
        5: 'SETUP.SCHEDULE.DAY_LAST'
      },
      rules: {
        recurrenceMode: [{ required: true, message: this.$t('ERRORS.FORM_FIELD_REQUIRED') }],
        updateTypeId: [{ required: true, message: this.$t('ERRORS.FORM_FIELD_REQUIRED') }],
        recurrenceMonthlyMode: [{ required: true, message: this.$t('ERRORS.FORM_ONE_ITEM_CHECKED_REQUIRED') }],
        recurrenceWeekDays: [{ required: true, message: this.$t('ERRORS.FORM_ONE_ITEM_CHECKED_REQUIRED') }],
        recurrenceMonthlyDayNumber: [{ validator: validateMonthlyRecurrenceDayNumber }],
        recurrenceMonthlyDay: [{ validator: validateMonthlyRecurrenceDay }],
        recurrenceMonthlyDayOccurence: [{ validator: validateMonthlyRecurrenceDayOccurence }]
      },
      weekDays: [
        this.$t('SETUP.SCHEDULE.DAYS_1'),
        this.$t('SETUP.SCHEDULE.DAYS_2'),
        this.$t('SETUP.SCHEDULE.DAYS_3'),
        this.$t('SETUP.SCHEDULE.DAYS_4'),
        this.$t('SETUP.SCHEDULE.DAYS_5'),
        this.$t('SETUP.SCHEDULE.DAYS_6'),
        this.$t('SETUP.SCHEDULE.DAYS_7')
      ],
      importForm: {
        recurrenceMode: '',
        recurrenceMonthlyMode: '',
        recurrenceWeekDays: [],
        recurrenceTime: '',
        recurrenceMonthlyDayOccurence: '',
        recurrenceMonthlyDay: '',
        recurrenceMonthlyDayNumber: '',
        updateTypeId: '',
        selectedTimezone: '',
      },
      defaultTimezone: '',
      previewTime: '',
      timezones: []
    }
  },
  computed: {
    user: function () {
      return this.$store.getters['auth/user']
    },
    config: function () {
      return config
    },
  },
  created: function () {
    this.initTimezones()
    this.initForm()
},
  methods: {
    handleNext (triggerSaving) {
      return new Promise((resolve, reject) => {
        if (triggerSaving) {
          this.$refs['form'].validate((valid) => {
            if (valid) {
              // conflict error
              var finalForm = this.formatForm((this.list.step === this.step))
              this.$store.dispatch('lists/patchList', {listId: this.list.id, data: finalForm})
              .then(() => {
                this.$emit('update:step', 4)
                resolve(valid)
              })
              .catch(() => {
                reject()
                this.$notify({
                  title: this.$t('ERRORS.UNEXPECTED'),
                  message: this.$t('ERRORS.UNEXPECTED_DESC'),
                  type: 'error'
                })
              })
            } else {
              resolve(valid)
            }
          })
        } else {
          this.$emit('update:step', 4)
          resolve()
        }
      })
    },
    handlePrevious () {
      this.$emit('update:step', 2)
    },
    onTimezoneChange () {
      this.formChange()
    },
    initForm () {
      if (!this.list.recurrence_timezone) {
        if (!Object.prototype.hasOwnProperty.call(tz, this.user.timezone)) {
        var guessedTz = Vue.moment.tz.guess()

        // in case moment guessed a tz which is not in the list, fallback to Europe/Paris
        if (!Object.prototype.hasOwnProperty.call(tz, guessedTz)) {
          guessedTz = 'Europe/Paris'
        }

        this.importForm.selectedTimezone = guessedTz
        } else {
          this.importForm.selectedTimezone = this.user.timezone
        }
      } else {
        this.importForm.selectedTimezone = this.list.recurrence_timezone
      }
      this.defaultTimezone = this.importForm.selectedTimezone

      this.importForm.recurrenceMode = (this.list.recurrence_type_id ? this.list.recurrence_type_id : config.RECURRENCE_MODE.WEEK)
      this.importForm.recurrenceTime = (!this.list.recurrence_import_time ? Vue.moment().format('HH:mm') : this.list.recurrence_import_time)
      this.importForm.updateTypeId = (this.list.update_type_id ? this.list.update_type_id : '')
      if (this.list.recurrence_day_number && this.list.recurrence_iso_weekdays != null) {
        // monthly: case every first/second/last xth of the month
        this.importForm.recurrenceMode = config.RECURRENCE_MODE.MONTHLY
        this.importForm.recurrenceMonthlyMode = config.RECURRENCE_MONTHLY_MODE.MULTIPLE_TIMES
        this.importForm.recurrenceMonthlyDayOccurence = this.list.recurrence_day_number
        this.importForm.recurrenceMonthlyDay = this.list.recurrence_iso_weekdays
      } else if (this.list.recurrence_day_number) {
        // monthly: case every xth of the month
        this.importForm.recurrenceMode = config.RECURRENCE_MODE.MONTHLY
        this.importForm.recurrenceMonthlyMode = config.RECURRENCE_MONTHLY_MODE.ONE_TIME
        this.importForm.recurrenceMonthlyDayNumber = this.list.recurrence_day_number
      } else if (this.list.recurrence_iso_weekdays) {
        // weekly: every monday/tuesday.. of the month
        this.importForm.recurrenceMode = config.RECURRENCE_MODE.WEEK
        this.importForm.recurrenceWeekDays = this.list.recurrence_iso_weekdays.split('|')
      }
    },
    initTimezones () {
      import('@/assets/data/tz.json').then((timezones) => {
        this.timezones = {}
        for (var key in timezones.default) {
          this.timezones[key] = "(GMT " + Vue.moment().tz(key).format('Z') + ") " + tz[key]
        }
      })
    },
    formatForm (next) {
      let data = {
        'recurrence_type_id': this.importForm.recurrenceMode,
        'recurrence_import_time': Vue.moment(this.importForm.recurrenceTime, 'HH:mm').format('HH:mm'),
        'recurrence_timezone': this.importForm.selectedTimezone,
        'update_type_id': this.importForm.updateTypeId
      }
      if (next) {
        data['step'] = 4
      }
      switch(this.importForm.recurrenceMode) {
        case(config.RECURRENCE_MODE.MONTHLY): {
          if (this.importForm.recurrenceMonthlyMode == config.RECURRENCE_MONTHLY_MODE.MULTIPLE_TIMES) {
            data['recurrence_iso_weekdays'] = this.importForm.recurrenceMonthlyDay
            data['recurrence_day_number'] = this.importForm.recurrenceMonthlyDayOccurence
          } else if (this.importForm.recurrenceMonthlyMode == config.RECURRENCE_MONTHLY_MODE.ONE_TIME) {
            data['recurrence_day_number'] = this.importForm.recurrenceMonthlyDayNumber
            data['recurrence_iso_weekdays'] = null
          }
          break
        }
        case(config.RECURRENCE_MODE.WEEK): {
          data['recurrence_iso_weekdays'] = this.importForm.recurrenceWeekDays.join('|')
          break
        }
      }
      return data
    },
    save: function (triggerSaving) {
      if (triggerSaving) {
        return new Promise((resolve, reject) => {
          this.$refs['form'].validate((valid) => {
            if (valid) {
              let data = this.formatForm(false)
              this.$store.dispatch('lists/patchList', {listId: this.list.id, data: data})
              .then(() => {
                resolve(valid)
              })
              .catch((error) => {
                reject(error)
              })
            } else {
              resolve(valid)
            }
          })
        })
      }
    },
    formChange: function(property) {
      this.$emit('update:change-detected', true)
      switch (property) {
        case('recurrence-monthly-mode-1'): {
          this.importForm.recurrenceMonthlyDayNumber = ''
          break
        }
        case('recurrence-monthly-mode-2'): {
          this.importForm.recurrenceMonthlyDayOccurence = ''
          this.importForm.recurrenceMonthlyDay = ''
          break
        }
        default:
          break
      }
    },
    onRecurrenceChange: function () {
      this.$refs.form.clearValidate()
      this.formChange()
    }
  }
}
</script>

<style lang="scss" scoped>
.setup-import {
  margin: 24px auto;
  width: 500px;
  &__form {
    ::v-deep .el-checkbox__label {
      font-size:12px;
    }
    &__radio-grp {
      width: 100%;
      ::v-deep .el-radio-button {
        width: 50%;
        &__inner {
          width: 100%;
        }
      }
    }
    &__recurrence-radio {
      margin: 12px 0px;

      &__recurrence-select {
        display: inline-block;
        ::v-deep .el-input {
          width:120px;
          margin: 0px 12px;
          font-weight: 400;
        }
      }
    }
  }
}

// }
</style>
