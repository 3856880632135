<template>
  <el-dialog
    custom-class="create-list"
    :append-to-body="true"
    :fullscreen="true"
    :show-close="false"
    :visible="showModal"
    center
    @open="openModal"
    @close="closeModal"
  >
    <!-- TITLE -->
    <div
      slot="title"
    >
      <div class="modal-title">
        {{ $t('CREATE.TITLE_MODAL') }}
      </div>
    </div>

    <!-- FORM -->

    <el-row
      type="flex"
      justify="center"
    >
      <el-form
        ref="listForm"
        :model="listForm"
        :rules="rules"
        label-position="top"
        class="create-list__form"
      >
        <div class="info">
          <span class="asterisk">*</span> {{ $t('MANDATORY') }}
        </div>

        <!-- name -->
        <el-form-item
          class="asterisk"
          prop="name"
          :label="$t('LIST.NAME')"
          :required="true"
        >
          <el-input
            v-model="listForm.name"
            maxlength="45"
          />
        </el-form-item>

        <!-- group -->
        <el-form-item
          prop="group_id"
          :label="$t('LIST.GROUP')"
          :required="true"
        >
          <el-select
            v-model="listForm.group_id"
            :loading="groupsLoading"
            :no-data-text="$t('MISC.NO_DATA')"
            placeholder=""
            filterable
            :popper-append-to-body="false"
            class="create-list__form__grp-select"
          >
            <el-option
              v-for="group in writableGroups"
              :key="group.id"
              :label="group.name"
              :value="group.id"
            />
          </el-select>
        </el-form-item>

        <!-- list type -->
        <el-form-item
          prop="type_id"
          :label="$t('LIST.TYPE')"
          :required="true"
        >
          <div class="create-list__form__radio-group">
            <div
              class="radio-card"
              :class="{'is-checked': listForm.type_id === listTypes.STATIC}"
            >
              <i class="material-icons">list_alt</i>
              <el-radio
                v-model="listForm.type_id"
                :label="listTypes.STATIC"
              >
                <strong>
                  {{ $t('LISTS.TYPES_REF_FULL.STATIC') }}
                </strong>
                <span class="radio-card__label">
                  {{ $t('LISTS.TYPES_DESC.STATIC') }}
                </span>
              </el-radio>
            </div>

            <div
              class="radio-card"
              :class="{'is-checked': listForm.type_id === listTypes.ACTIVE}"
            >
              <i class="material-icons">sync</i>
              <el-radio
                v-model="listForm.type_id"
                :label="listTypes.ACTIVE"
              >
                <strong>
                  {{ $t('LISTS.TYPES_REF_FULL.ACTIVE') }}
                </strong>
                <span class="radio-card__label">
                  {{ $t('LISTS.TYPES_DESC.ACTIVE') }}
                </span>
              </el-radio>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- FOOTER -->
    <span slot="footer">
      <el-button
        size="medium"
        class="rounded-button"
        @click="closeModal()"
      >
        {{ $t('BUTTON.CANCEL') }}
      </el-button>
      <el-button
        size="medium"
        type="primary"
        class="rounded-button"
        :loading="creatingList"
        @click="submitForm()"
      >
        {{ $t('BUTTON.CONFIRM') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { debounce } from 'lodash-es'
import config from '@/config'
export default {
  name: 'ModalCreateList',
  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      listForm: {
        type_id: config.LIST_TYPES.STATIC,
        group_id: null
      },
      rules: {
        name: [
          { required: true, message: this.$t('ERRORS.FORM_FIELD_REQUIRED') }
        ],
        group_id: [
          { required: true, message: this.$t('ERRORS.FORM_FIELD_REQUIRED') }
        ],
        type_id: [
          { required: true, message: this.$t('ERRORS.FORM_FIELD_REQUIRED') }
        ]
      },
      listTypes: config.LIST_TYPES,
      creatingList: false
    }
  },
  computed: {
    groups () {
      return this.$store.getters['groups/groups'] || []
    },
    writableGroups () {
      return (this.$store.getters['groups/groups']  || []).filter((c) => {
          return c['permissions'].find(x => {
            return (x === config.PERMISSIONS.LIST_WRITE_SELF || x === config.PERMISSIONS.LIST_WRITE)
          }
        )
      })
    },
    groupsLoading () {
      return this.$store.getters['groups/loading']
    },
  },
  methods: {
    closeModal () {
      this.resetField()
      this.$emit('closeModal', '/lists')
    },
    resetField () {
      if (this.$refs['listForm']) {
        this.$refs['listForm'].clearValidate()
      }
      // Wait the end of the modal before cleaning the form
      debounce(() => {
        this.listForm = {
          type_id: config.LIST_TYPES.STATIC,
          group_id: null
        }

        if (this.writableGroups && this.writableGroups.length === 1) {
          this.listForm.group_id = this.writableGroups[0].id
        }
      }, 100)()
    },
    openModal () {
      if (this.$refs['listForm']) {
        this.$refs['listForm'].clearValidate()
      }

      this.listForm = {
        type_id: config.LIST_TYPES.STATIC,
        group_id: null
      }

      if (this.writableGroups && this.writableGroups.length === 1) {
        this.listForm.group_id = this.writableGroups[0].id
      }
    },
    submitForm () {
      this.$refs['listForm'].validate((valid) => {
        if (valid) {
          this.creatingList = true
          this.$store.dispatch('lists/createList', this.listForm).then(list => {
            // change current route to list's details (handle ctrl+R case)
            window.history.pushState({}, window.document.title, `/lists/${list.id}/details`)
            this.creatingList = false
          }).catch((error) => {
            this.creatingList = false
            let msg = {
                title: this.$t('ERRORS.UNEXPECTED'),
                message: this.$t('ERRORS.UNEXPECTED_DESC'),
                type: 'error'
              }
            if (error.status === 409) {
              msg.title = this.$t('ERRORS.LISTS_CONFLICT')
              msg.message = this.$t('ERRORS.LISTS_CONFLICT_DESC')
            }
            this.$notify(msg)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--form-width: 616px;

.create-list-grp-select {
  ::v-deep .el-select-dropdown__list {
    max-width: 100% !important;
  }
}

::v-deep .el-input .el-input__inner {
  border-radius: 4px;
  font-size: 14px;
  color: #303133;
}

::v-deep .create-list {
  background-color: white;

  .el-dialog__header {
    padding-top: 88px;
  }

  .title {
    margin: 10px;
    font-size: 36px;
    font-weight: 300;
  }
  .info {
    color: #606266;
    font-size: 14px;
    text-align: right;
  }
  .asterisk {
    color: #F47957;
  }

  &__exit-btn {
    position: absolute;
    top: 36px;
    right: 44px;
  }

  &__form {
    max-width: $--form-width;
    width: 100%;

    &__grp-select {
      width: 100%;
      ::v-deep .el-select-dropdown {
        .el-select-dropdown__list {
          max-width: 100%;
        }
      }
    }

    &__radio-group {
      @include flexbox;
      justify-content: space-between;
      &:first {
        margin-right: 15px;
      }
    }
  }

  .radio-card {
    width: calc(50% - 12px);
    border-radius: 5px;
    height: 205px;
  }
  .radio-card .el-radio {
    margin-top: 0px;
  }
  .el-radio__label strong {
    font-size: 14px;
    color: #303133;
  }
  .radio-card__label {
    margin-top: 12px;
    font-size: 13px;
    padding: 0 20px;
    height: inherit;
    color: #606266;
  }

  .el-form-item__label {
    font-size: 14px;
    color: #606266;
  }
}

.rounded-button {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  min-width: none;
}

.rounded-button.el-button.el-button--default {
  color: #DC6D4E;
  border-color: #DC6D4E;
}
</style>
