<template>
  <el-dialog
    custom-class="setup-list"
    :append-to-body="true"
    :fullscreen="true"
    :show-close="false"
    :visible="showModal"
    center
    @open="initStep"
    @close="onClose"
  >
    <!-- RESET LIST MODAL -->
    <Modal
      :dialog-visible.sync="showResetListModalOnSave"
      width="500px"
      :title="$t('SETUP.MODAL_RESET_TITLE')"
      :subtitle="$t('SETUP.MODAL_RESET_DESCRIPTION')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="updatingList || creatingList"
      type="danger"
      :action="handleExit"
      :action-args="'save'"
    />
    <Modal
      :dialog-visible.sync="showResetListModalOnNext"
      width="500px"
      :title="$t('SETUP.MODAL_RESET_TITLE')"
      :subtitle="$t('SETUP.MODAL_RESET_DESCRIPTION')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="updatingList || creatingList"
      type="danger"
      :action="next"
    />
    <!-- EXIT WITHOUT SAVING MODAL -->
    <Modal
      :dialog-visible.sync="showExitModalOnExit"
      width="500px"
      :title="$t('SETUP.MODAL_EXIT_TITLE')"
      :subtitle="$t('SETUP.MODAL_EXIT_DESCRIPTION')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="updatingList || creatingList"
      type="danger"
      :action="handleExit"
      :action-args="'exit'"
    />
    <Modal
      :dialog-visible.sync="showExitModalOnPrevious"
      width="500px"
      :title="$t('SETUP.MODAL_EXIT_TITLE')"
      :subtitle="$t('SETUP.MODAL_EXIT_DESCRIPTION')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="updatingList || creatingList"
      type="danger"
      :action="previous"
    />



    <!-- TITLE -->
    <div slot="title">
      <div class="list-header">
        <div class="list-title">
          <el-dropdown
            v-if="list"
            trigger="click"
          >
            <span class="el-dropdown-link pointer">
              {{ list.name }} <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <template #dropdown>
              <el-dropdown-menu class="setup-list-list-info-dropdown">
                <el-dropdown-item>{{ $t('MISC.CREATED_AT') }}: <strong>{{ list.created_at | moment('LLL') }}</strong></el-dropdown-item>
                <el-dropdown-item v-if="list.group">
                  {{ $t('LIST.GROUP') }}: <strong>{{ list.group.name }}</strong>
                </el-dropdown-item>
                <el-dropdown-item>{{ $t('LIST.TYPE') }}: <strong>{{ isActiveList ? $t('LISTS.TYPES_REF_FULL.ACTIVE') : $t('LISTS.TYPES_REF_FULL.STATIC') }}</strong></el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="action">
          <el-button
            v-if="[1, 4].indexOf(listStep) > -1"
            size="medium"
            :loading="gettingList"
            @click="handleExit('exit')"
          >
            {{ $t('BUTTON.EXIT') }}
          </el-button>
          <el-dropdown
            v-else
            @command="handleExit"
          >
            <el-button
              size="medium"
              :loading="gettingList"
            >
              {{ $t('BUTTON.EXIT') }}
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="save">
                {{ $t('BUTTON.SAVE_AND_EXIT') }}
              </el-dropdown-item>
              <el-dropdown-item command="exit">
                {{ $t('BUTTON.EXIT') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>


      <div class="modal-title">
        {{ $t('CREATE.TITLE_MODAL') }}
      </div>
    </div>

    <!-- BODY -->
    <div class="setup-list__body">
      <el-steps
        class="setup-list__body__steps"
        finish-status="success"
        :active="activeStep"
        align-center
      >
        <el-step :title="isActiveList ? $t('SETUP.ACTIVE_FILE_STEP') : $t('SETUP.STATIC_FILE_STEP')" />
        <el-step :title="$t('SETUP.FIELDS_STEP')" />
        <el-step
          v-if="isActiveList"
          :title="$t('SETUP.ACTIVE_SETUP_STEP')"
        />
        <el-step :title="$t('SETUP.ACTIVE_SUMMARY_STEP')" />
      </el-steps>

      <SetupActiveFile
        v-if="isActiveList && (listStep === 1 || listStep === 1.1)"
        ref="step-1"
        :list="list"
        :step.sync="listStep"
        :change-detected.sync="changeDetected"
      />
      <SetupStaticFile
        v-else-if="!isActiveList && (listStep === 1 || listStep === 1.1)"
        ref="step-1"
        :list="list"
        :step.sync="listStep"
        :change-detected.sync="changeDetected"
      />
      <SetupColumnsMapping
        v-else-if="listStep === 2"
        ref="step-2"
        :list="list"
        :step.sync="listStep"
        :loading.sync="loadingSubmit"
        :change-detected.sync="changeDetected"
      />
      <SetupImport
        v-else-if="listStep === 3"
        ref="step-3"
        :list="list"
        :step.sync="listStep"
        :change-detected.sync="changeDetected"
      />
      <SetupSummary
        v-else-if="listStep === 4"
        ref="step-4"
        :list="list"
        :step.sync="listStep"
        :expiration-date-error="expirationDateError"
        @show-expiration-date="setExpirationDateShown"
        @set-expiration-date="setExpirationDate"
      />
    </div>
    <!-- FOOTER -->
    <span
      v-if="!isProcessing"
      slot="footer"
    >
      <el-button
        v-show="listStep > 1"
        :disabled="updatingList || creatingList"
        size="medium"
        class="rounded-button"
        @click="previous()"
      >
        {{ $t('BUTTON.PREVIOUS') }}
      </el-button>

      <el-button
        v-if="[1, 2, 3].indexOf(listStep) > -1"
        :loading="updatingList || loadingSubmit"
        size="medium"
        type="primary"
        class="rounded-button"
        @click="next()"
      >
        {{ $t('BUTTON.NEXT') }}
      </el-button>
      <el-button
        v-else
        :disabled="listStep === 4 && (!list.preview_processing_time || list.preview_processing_time === 0)"
        :loading="creatingList"
        size="medium"
        type="primary"
        class="rounded-button"
        @click="createAndActivateList()"
      >
        {{ isActiveList ? $t('BUTTON.ACTIVATE_LIST') : $t('BUTTON.CREATE_LIST') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  Modal,
  SetupActiveFile,
  SetupColumnsMapping,
  SetupStaticFile,
  SetupImport,
  SetupSummary,
} from '..'
import config from '@/config'
export default {
  name: 'ModalSetupList',
  components: {
    Modal, SetupActiveFile, SetupStaticFile,
    SetupImport, SetupSummary, SetupColumnsMapping,
  },
  props: {
    showModal: { type: Boolean, required: true },
    list: { type: Object, required: true },
    updatingList: { type: Boolean, required: true }
  },
  data() {
    return {
      listTypes: config.LIST_TYPES,
      creatingList: false,
      gettingList: false,
      listStep: 1,
      changeDetected: false,
      showResetListModalOnNext: false,
      expirationDateError: false,
      expirationDateShown: false,
      showResetListModalOnSave: false,
      showExitModalOnExit: false,
      showExitModalOnPrevious: false,
      loadingSubmit: false
    }
  },
  computed: {
    isActiveList() {
      return this.list.type_id === this.listTypes.ACTIVE
    },
    activeStep() {
      let displayStep = Math.floor(this.listStep - 1)
      if (this.list.type_id === this.listTypes.ACTIVE) {
        return displayStep
      }

      // 4 is the latest step but STATIC list doesn't have step 3 so we should decrease
      // if in the latest step to have the correct display behavior on the stepper
      return displayStep >= 3 ? displayStep - 1 : displayStep
    },
    isProcessing() {
      return this.listStep === 1.1 || (!this.list.preview_processing_time && this.listStep === 4)
    }
  },
  methods: {
    next() {
      // show resetListModal if the user resets a crucial step
      if (this.changeDetected && this.list.step != this.listStep &&
        !this.showResetListModalOnNext && this.listStep != 4) {
        this.showResetListModalOnNext = true
        return
      }

      // trigger save if a change has been detected or the current step is the list's step
      var triggerSaving = this.changeDetected || this.list.step == this.listStep
      let ref = "step-" + Math.trunc(this.listStep)
      var promise = this.$refs[ref].handleNext(triggerSaving)
      this.showResetListModalOnNext = false
      if (promise) {
        promise.then((valid) => {
          this.changeDetected = !valid
          this.loadingSubmit = false
        })
      } else {
        this.changeDetected = false
        this.loadingSubmit = false
      }
      return
    },
    previous() {
      if (this.changeDetected && !this.showExitModalOnPrevious) {
        this.showExitModalOnPrevious = true
        return
      }
      let ref = "step-" + Math.trunc(this.listStep)
      this.changeDetected = false
      this.showExitModalOnPrevious = false
      this.$refs[ref].handlePrevious()
      return
    },
    createAndActivateList() {
      let data = {
        'listId': this.list.id,
        'data': {
          'import': true,
        }
      }
      this.creatingList = true
      this.$store.dispatch('lists/activate', data)
        .then(() => {
          this.creatingList = false
          this.handleExit('exit')
          this.$notify({
            title: this.$t('SETUP.SUCCESS_NOTIF_TITLE'),
            message: this.$t('SETUP.SUCCESS_NOTIF_DESCRIPTION'),
            type: 'success'
          })
        })
        .catch(() => {
          this.creatingList = false
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        })
    },
    handleExit: function (command) {
      switch (command) {
        case 'exit':
          if (this.changeDetected && !this.showExitModalOnExit) {
            this.showExitModalOnExit = true
            return
          }
          this.gettingList = true
          this.showExitModalOnExit = false
          this.changeDetected = false
          this.$store.dispatch('lists/getList', this.list.id)
            .then(() => {
              this.gettingList = false
              this.$emit('closeModal', `/lists/${this.list.id}/details`)
            })
            .finally(() => this.gettingList = false)
          break
        case 'save': {
          // show resetListModal if the user resets a step
          if (this.changeDetected && this.list.step != this.listStep && !this.showResetListModalOnSave) {
            this.showResetListModalOnSave = true
            return
          }
          var triggerSaving = this.changeDetected || this.list.step == this.listStep
          let ref = "step-" + Math.trunc(this.listStep)
          this.showResetListModalOnSave = false
          this.$refs[ref].save(triggerSaving)
            .then((res) => {
              this.changeDetected = false

              if (res) {
                this.handleExit('exit')
              }
            })
            .catch((error) => {
              if (error) {
                this.$notify({
                  title: this.$t('ERRORS.UNEXPECTED'),
                  message: this.$t('ERRORS.UNEXPECTED_DESC'),
                  type: 'error'
                })
              }
            })
          break
        }
      }
    },
    initStep: function () {
      this.changeDetected = false
      if (this.list.step) {
        this.listStep = this.list.step
      } else {
        this.listStep = 1
      }
      // fixes a bug with el-dialog
      // (body's scrollbar may be displayed, even if lock-scroll property is true)
      document.body.classList.add('hide-scroll')
    },
    onClose: function () {
      // adapt to the CSS transition's duration
      setTimeout(
        () => document.body.classList.remove('hide-scroll'),
        300
      )
    },
    setExpirationDateShown(shown) {
      this.expirationDateShown = shown
      if (this.expirationDateShown) {
        this.expirationDateError = false
        this.$store.commit('lists/SET_LIST_EXPIRATION_DATE', null)
      }
    },
    setExpirationDate(date) {
      this.expirationDateError = false
      this.$store.commit('lists/SET_LIST_EXPIRATION_DATE', date)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/element-variables.scss";
@import "@/assets/styles/variables.scss";
$--form-width: 1000px;

::v-deep .el-dropdown-menu {
  &__item:hover {
    background-color: $--color-primary-light-9 !important;
  }
}

::v-deep .el-input .el-input__inner {
  border-radius: 4px;
  font-size: 14px;
  color: #303133;
}

::v-deep .el-select-dropdown__item {
  font-size: 14px;
  color: #303133;
}

::v-deep .setup-list {
  background-color: white;

  .el-form-item__label {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    word-break: break-word;
  }

  .setup-list__body__content {
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }

  .el-dialog {
    &__header {
      padding-top: 0;
    }

    &__footer {
      padding-top: 0;
    }
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    height: 88px;
    align-items: center;
  }

  .list-title .el-dropdown-link {
    color: #606266;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    padding-left: 12px;
  }


  .title {
    margin: 10px;
    font-size: 36px;
    font-weight: 300;
  }

  .subtitle {
    margin: 10px;
    font-size: 21px;
    font-weight: 400;
  }

  .setup-list__body__steps .el-step__title {
    font-size: 12px !important;
  }

  .step-paragraph {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #606266;
  }

  .step-subtitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  &__body {
    margin: 0 auto;
    width: $--form-width;

    &__steps {
      margin-top: 12px;
      margin-bottom: 36px;
    }
  }

  .el-dialog__body {
    padding: 0 25px 34px;
  }

  .el-dialog__footer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 50px;

    button {
      margin: 0px 12px;
    }
  }
}

::v-deep .setup-loading {
  text-align: center;

  &__logo {
    margin: 24px 0px 12px 0px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin: 44px 0px 24px 0px;
  }

  &__description {
    font-size: 14px;
    font-weight: 500;
  }
}

.rounded-button {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  min-width: none;
}

.rounded-button.el-button.el-button--default {
  color: #DC6D4E;
  border-color: #DC6D4E;
}
</style>

<style lang="scss">
.setup-list-list-info-dropdown .el-dropdown-menu__item {
  cursor: default;
  font-size: 12px;
  color: #303133;
}
.setup-list-list-info-dropdown .el-dropdown-menu__item:hover {
  background-color: transparent !important;
  cursor: default;
}
</style>
