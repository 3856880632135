<!-- eslint-disable vue/no-v-html -->
<template>
  <el-dialog
    custom-class="dialog-modal"
    :visible="dialogVisible"
    :append-to-body="true"
    :show-close="true"
    width="500px"
    @open="initExpirationDate"
    @close="closeModal"
  >
    <div
      slot="title"
      class="dialog-modal__title"
    >
      <h2>
        {{ $t('EXPIRATION_DATE.TITLE') }}
      </h2>
    </div>
    <div class="dialog-modal__information">
      <label>{{ $t('EXPIRATION_DATE.TITLE') }} :</label>
      <el-date-picker
        v-model="expirationDate"
        :placeholder="$t('EXPIRATION_DATE.PLACEHOLDER')"
        :picker-options="pickerOptions"
        clearable
        @clear="clearExpirationDate"
      />
      <p
        v-if="expirationDate"
        class="expires-information"
        :class="{'expires-soon' : getExpirationDays() < 15 }"
      >
        {{ $t('EXPIRATION_DATE.EXPIRATION_DAYS') }}
        <span>{{ $tc('EXPIRATION_DATE.EXPIRATION_COUNT', getExpirationDays(), { days: getExpirationDays() }) }}</span>
      </p>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="closeModal">
        {{ $t('BUTTON.CANCEL') }}
      </el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="update"
      >
        {{ $t('BUTTON.SAVE') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ModalExpirationDate',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    list: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      expirationDate: '',
      pickerOptions: {
        disabledDate: this.disabledDate
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:dialogVisible', false)
    },
    clearExpirationDate() {
      this.expirationDate = ''
    },
    initExpirationDate () {
      if (this.list.expiration_date) {
        this.expirationDate = moment(this.list.expiration_date).format("YYYY-MM-DD")
      }
    },
    disabledDate(date) {
      const today = new Date()
      return date < today
    },

    getExpirationDays() {
      const today = moment().utc()
      const expirationDate = moment(this.expirationDate)
      if (expirationDate) {
        return expirationDate.diff(today, 'days') + 1
      }
      return 0
    },
    update() {
      let update = null
      if (this.expirationDate) {
        update = moment(this.expirationDate).format('YYYY-MM-DD')
      }
      this.$emit('update-expiration-date', update)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

h2 {
  color: $--color-primary;
  font-weight: 600;
  margin: 0;
}
label {
  font-size: 12px;
  margin-right: 8px;
}
.el-input {
  max-width: 150px;
}
.expires-information {
  font-size: 12px;
  color: $--color-gray;
  span {
    font-weight: 600;
  }
}
.expires-soon {
  span {
    color:  $--color-orange;
  }
}

</style>
