<template>
  <div class="setup-summary">
    <div
      v-if="list && !list.preview_processing_time"
      class="setup-loading"
    >
      <LoadingFile class="setup-loading__logo" />
      <div class="setup-loading__title">
        {{ $t('SETUP.PREVIEW.PROCESSING') }}
      </div>
    </div>
    <div v-else>
      <h3>{{ $t('SETUP.FILE_PARAMETERS') }}</h3>
      <el-row>
        <el-col :span="10">
          <div style="margin-bottom: 15px">
            {{ $t('SETUP.FILE_NAME') }} <b>{{ fileName }}</b>
          </div>
          <div>
            {{ $t('SETUP.PRIMARY_KEY') }} <b>{{ primaryKeys.join(', ') }}</b>
          </div>
        </el-col>
        <el-col :span="14">
          <p
            v-if="expirationDate"
          >
            {{ $t('SETUP.EXPIRATION_DATE') }} <b>{{ expirationDate | moment('L') }}</b>
          </p>
          <!-- monthly: case every first/second/last xth of the month -->
          <div
            v-if="list.recurrence_day_number && list.recurrence_iso_weekdays != null"
          >
            {{
              $t('SETUP.SUMMARY.IMPORT_SETTINGS_MONTHLY_WEEKDAY', { occurence: monthlyRecurrenceWeeks,
                                                                    day: weeklyIsodays.slice(0, -1).toLowerCase(),
                                                                    time: listImportTimeStr}
              )
            }}
          </div>
          <!-- monthly: case every xth of the month -->
          <div
            v-else-if="list.recurrence_day_number"
          >
            monthly: case every xth of the month
            {{
              $t('SETUP.SUMMARY.IMPORT_SETTINGS_MONTHLY_DAY', { day: list.recurrence_day_number, time: listImportTimeStr})
            }}
          </div>
          <!-- weekly: every monday/tuesday.. of the month -->
          <div
            v-else-if="list.recurrence_iso_weekdays"
          >
            {{
              $t('SETUP.SUMMARY.IMPORT_SETTINGS_WEEKLY', { days: weeklyIsodays,
                                                           time: listImportTimeStr})
            }}
          </div>
        </el-col>
      </el-row>
      <hr class="sep">
      <h3>{{ $t('FIELDS.PREVIEW') }}</h3>
      <el-row>
        <el-col :span="10">
          {{ $t('SETUP.NUMBER_OF_CONTACTS') }} <b>{{ list.preview_rows | formatNumber }}</b>
        </el-col>
        <el-col :span="14">
          <div>{{ $t('SETUP.NUMBER_OF_LOST') }} <b>{{ (list.preview_missing_mandatory + list.preview_invalid_type + list.preview_duplicates) | formatNumber }}</b></div>
          <ul>
            <li>
              <div class="flex items-center">
                {{ $t('SETUP.LINES_DUPLICATES') }} <b>&nbsp;{{ list.preview_duplicates | formatNumber }}</b>
                <el-tooltip
                  effect="dark"
                  placement="top"
                  transition=""
                  :open-delay="400"
                  :hide-after="0"
                  :content="$t('SETUP.SUMMARY.PRIMARY_KEY_DUPLICATE')"
                >
                  <i class="material-icons small-help-outline">help_outlined</i>
                </el-tooltip>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                {{ $t('SETUP.LINES_INVALID') }} <b>&nbsp;{{ (list.preview_missing_mandatory + list.preview_invalid_type) | formatNumber }}</b>
                <el-tooltip
                  effect="dark"
                  placement="top"
                  transition=""
                  :open-delay="400"
                  :hide-after="0"
                  :content="$t('SETUP.SUMMARY.INVALID_VALUE_OR_EMPTY')"
                >
                  <i class="material-icons small-help-outline">help_outlined</i>
                </el-tooltip>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
      <hr class="sep">
      <h3>{{ $t('SETUP.COLUMN_MAPPING') }}</h3>

      <el-row style="margin-bottom: 24px;">
        <el-col
          v-if="emailOptin"
          :span="10"
        >
          {{ $t('SETUP.EMAIL_CONSENT') }} <b>{{ emailOptin.name }}</b>
        </el-col>
        <el-col
          v-if="smsOptin"
          :span="10"
        >
          {{ $t('SETUP.SMS_CONSENT') }} <b>{{ smsOptin.name }}</b>
        </el-col>
      </el-row>

      <div
        v-if="list && list.fields"
        class="table-wrapper"
      >
        <el-table
          :data="list.fields"
          stripe
        >
          <el-table-column
            :label="$t('FIELDS.PRIMARY_KEY')"
            width="120"
          >
            <template #default="scope">
              <i
                v-if=" scope.row.primary_key "
                slot="prefix"
                class="material-icons md-24"
              >
                done
              </i>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('FIELDS.MANDATORY')"
            width="130"
          >
            <template #default="scope">
              <i
                v-if=" scope.row.mandatory "
                slot="prefix"
                class="material-icons md-24"
              >
                done
              </i>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('FIELDS.COLUMN_NAME')"
          >
            <template #default="scope">
              {{ scope.row.name_in_db }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('FIELDS.SOURCE')"
          >
            <template #default="scope">
              {{ scope.row.name_in_file }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('FIELDS.PREVIEW')"
          >
            <template #default="scope">
              <div
                v-for="(value, idx) in sampleSplice(scope.row.sample)"
                :key="idx"
              >
                {{ value }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('FIELDS.TYPE')"
            width="160"
          >
            <template #default="scope">
              {{ $t(config.REF_DATA_TYPES[scope.row.datatype_id - 1].label) }}
              <span v-if="[4, 5].includes(scope.row.datatype_id)">
                /
                <span v-if="scope.row.value_format">
                  {{ scope.row.value_format }}
                </span>
                <span v-else>{{ $t('MISC.AUTOMATIC') }}</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import rocketLogo from '@/assets/img/rocket.svg'
import { LoadingFile } from '..'
import { find } from 'lodash-es'

export default {
  name: 'SetupSummary',
  components: {
    LoadingFile
  },
  props: {
    step: { type: Number, required: true },
    list: { type: Object, required: true },
    expirationDateError: { type: Boolean, default: false }
  },
  data () {
    return {
      listTypes: config.LIST_TYPES,
      rocketLogo: rocketLogo,
      emailOptin: null,
      smsOptin: null,
      showExpirationDateForm: false,
      expirationDate: null,
      pickerOptions: {
        disabledDate: this.disabledDate
      },
      weekDays: {
        '1': this.$t('SETUP.SCHEDULE.DAYS_1'),
        '2': this.$t('SETUP.SCHEDULE.DAYS_2'),
        '3': this.$t('SETUP.SCHEDULE.DAYS_3'),
        '4': this.$t('SETUP.SCHEDULE.DAYS_4'),
        '5': this.$t('SETUP.SCHEDULE.DAYS_5'),
        '6': this.$t('SETUP.SCHEDULE.DAYS_6'),
        '7': this.$t('SETUP.SCHEDULE.DAYS_7')
      },
      dayMonthlyOccurence: {
        1: this.$t('SETUP.SCHEDULE.DAY_FIRST').toLowerCase(),
        2: this.$t('SETUP.SCHEDULE.DAY_SECOND').toLowerCase(),
        3: this.$t('SETUP.SCHEDULE.DAY_THIRD').toLowerCase(),
        4: this.$t('SETUP.SCHEDULE.DAY_FOURTH').toLowerCase(),
        5: this.$t('SETUP.SCHEDULE.DAY_LAST').toLowerCase()
      }
    }
  },
  computed: {
    config () {
      return config
    },
    isActiveList () {
      return this.list.type_id === this.listTypes.ACTIVE
    },
    primaryKeys () {
      return this.list.fields.filter(field => {return field.primary_key})
      .map(field => { return field.name_in_db })
    },
    weeklyIsodays () {
      if (this.list.recurrence_iso_weekdays.length > 0) {
        var strWeekdays = this.list.recurrence_iso_weekdays.split('|').sort()
        var isodayString = ''
        strWeekdays.forEach((weekday) => {
          isodayString += this.weekDays[weekday] + ', '
        })
        return isodayString.slice(0, -2)
      }
      return ''
    },
    listImportTimeStr () {
      if (this.list.recurrence_import_time && this.list.recurrence_timezone) {
        var listTz = moment().tz(this.list.recurrence_timezone).format('Z')
        return this.list.recurrence_import_time + ` (GMT ${listTz})`
      }
      return ''
    },
    monthlyRecurrenceWeeks () {
      if (this.list.recurrence_day_number) {
        return this.dayMonthlyOccurence[this.list.recurrence_day_number]
      }
      return ''
    },
    fileName () {
      var filename = this.list.filename_pattern
      return this.isActiveList ? filename : filename.substring(21)
    }
  },
  watch: {
    showExpirationDateForm() {
      if (this.showExpirationDateForm) {
        this.expirationDate = null
      }
    }
  },
  created () {
    this.initOptins()
    .then((optins) => {
      let emailOptinField = this.list.fields.filter(field => { return field.optin_type_id != null && field.name_in_db == 'EMAIL'})
      let smsOptinField = this.list.fields.filter(field => { return field.optin_type_id != null && field.name_in_db == 'MOBILE'})
      if (emailOptinField.length > 0) {
        this.emailOptin = find(optins,{'id': emailOptinField[0].optin_type_id})
      }
      if (smsOptinField.length > 0) {
        this.smsOptin = find(optins, {'id': smsOptinField[0].optin_type_id})
      }

      if (!this.list.preview_processing_time) {
        this.refreshList()
      }
    })
  },
  methods: {
    handlePrevious () {
      var previousStep  = this.isActiveList ? 3 : 2
      this.$emit('update:step', previousStep)
    },
    sampleSplice(sample) {
      if (!sample || sample.length <= 3) {
        return sample
      }
      return sample.slice(0, 3)
    },
    initOptins () {
      return new Promise((resolve, reject) => {
      this.loadingOptins = true
      this.$store.dispatch('groups/getGroupsOptins', this.list.group_id)
        .then((response) => {
          this.loadingOptins = false
          resolve(response.objects)
        })
        .catch(() => {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
          this.loadingOptins = false
          reject()
        })
      })
    },
    moment: function () {
      return moment()
    },
    refreshList () {
      this.$store.dispatch('lists/getList', this.list.id)
      .then((res) => {
        if (!res.preview_processing_time) {
          setTimeout(this.refreshList, 700)
        }
      })
    },
    setExpirationDate(date) {
      const dateStr = moment(date).format("YYYY-MM-DD")
      this.$emit('set-expiration-date', dateStr)
    },
    disabledDate(date) {
      const today = new Date()
      return date < today
    },
    getExpirationDays() {
      const today = moment()
      const expirationDate = moment(this.expirationDate)
      if (expirationDate) {
        return expirationDate.diff(today, 'days') + 1
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--setup-summary-width: 500px;
.setup-summary {
    color: #303133;
    margin: 24px auto;

    h3 {
      margin-bottom: 24px;
    }

    &__logo {
      text-align: center;
    }

    &__title {
      color: $--text-dark;
      font-size: 12px;
      font-weight: 600;
      margin: 24px 0px 24px 0px;
    }

    &__description {
      color: $--text-light;
      font-size: 12px;
      &.expiration-date {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .el-switch {
          margin-right: 10px;
        }
        flex-wrap: wrap;
        .show-expiration {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          i {
            margin-left: 5px;
            font-size: 14px;
          }
        }

      }
      .error-message {
        display: block;
        width: 100%;
        color: $--color-danger;
      }
      .expiration-date-form {
        width: 100%;
        height: 100px;
        border: 1px solid $--color-secondary-light;
        background-color: white;
        padding: 20px;
        .el-input {
          margin-left: 8px;
          max-width: 150px;
        }
        .expires-information {
          font-size: 12px;
          color: $--color-gray;
          span {
            font-weight: 600;
          }
        }
        .expires-soon {
          span {
            color: $--color-orange;
          }

        }
        &.error {
          border-color: $--color-danger;
        }
      }

    }
    &__alert {
      margin: 18px 0px;
      word-break: break-word;

      ::v-deep .el-alert__title {
        font-size: 12px !important;
      }
    }
    &__separator {
      border: none;
      border-bottom: 1px solid $--color-secondary-light;
    }

    &__settings-description {
      margin-top: 24px;
      color: $--text-light;
      font-size: 12px;
      word-break: break-word;
    }

    .sep {
      margin: 32px 0;
      border-top: 1px solid #d9dde3;
    }

  .small-help-outline {
    font-size: 18px;
    margin-left: 6px;
    color: #909399;
    width: 18px;
  }
}
</style>
