<template>
  <el-dialog
    custom-class="create-contact"
    :append-to-body="true"
    :fullscreen="true"
    :show-close="false"
    :visible="showModal"
    center
    @close="closeModal"
  >
    <!------------->
    <!--- TITLE --->
    <!------------->

    <div slot="title">
      <el-button
        size="medium"
        class="create-contact__exit-btn"
        :disabled="loadingPKState"
        @click="closeModal()"
      >
        {{ $t('BUTTON.EXIT') }}
      </el-button>
      <h2
        v-if="create"
        class="title"
      >
        {{ $t('CREATE_CONTACT.TITLE') }}
      </h2>
      <h2
        v-else
        class="title"
      >
        {{ $t('EDIT_CONTACT.TITLE') }}
      </h2>
    </div>

    <!------------------------>
    <!-- Primary keys FORM  -->
    <!------------------------>

    <el-form
      ref="form-primaryKeys"
      v-loading="isLoading"
      class="create-contact__primary-keys"
      :model="primaryKeysForm"
      :rules="itemsPrimaryKeyRules"
      @keydown.native.enter="onPrimaryKeysFilled"
    >
      <i class="el-icon-key pull-right" />
      <div
        v-for="item in formItems.primaryKeys"
        :key="item.id"
      >
        <el-form-item
          :label="item.name_in_db"
          :required="item.mandatory === true"
          :prop="item.name_in_db"
          :class="{ 'is-error': updateExistingContact }"
        >
          <!-- Special rendering for country field -->
          <el-select
            v-if="item.name_in_db === 'COUNTRY_CODE'"
            v-model="primaryKeysForm[item.name_in_db]"
            filterable
            clearable
            :disabled="disablePrimaryKeys || disableCountrySelect"
            @change="validateMobile"
          >
            <el-option
              v-for="(name, codeISO) in countries"
              :key="codeISO"
              :label="name"
              :value="codeISO"
            />
          </el-select>

          <el-input
            v-else-if="item.display.component === 'el-input'"
            v-model="primaryKeysForm[item.name_in_db]"
            :disabled="disablePrimaryKeys"
            :maxlength="getMaxLength(item)"
          />

          <el-input-number
            v-else-if="item.display.component === 'el-input-number'"
            v-model="primaryKeysForm[item.name_in_db]"
            v-model.number="primaryKeysForm[item.name_in_db]"
            :disabled="disablePrimaryKeys"
            :max="getMaxLength(item)"
          />

          <el-date-picker
            v-else-if="item.display.component === 'el-date-picker'"
            v-model="primaryKeysForm[item.name_in_db]"
            :disabled="disablePrimaryKeys"
            :type="item.display.type"
            :value-format="item.display.type === 'datetime' ? 'yyyy-MM-ddTHH:mm:ssZ' : 'yyyy-MM-dd'"
          />

          <el-radio-group
            v-else-if="item.display.component === 'el-radio'"
            v-model="primaryKeysForm[item.name_in_db]"
            :disabled="disablePrimaryKeys"
          >
            <el-radio :label="false">
              0
            </el-radio>
            <el-radio :label="true">
              1
            </el-radio>
            <el-radio
              v-if="!item.mandatory"
              :label="null"
            >
              {{ $t('EDIT_CONTACT.EMPTY_BOOLEAN') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-if="updateExistingContact && !allowUpdateChecked">
        <el-alert
          :title="$t('EDIT_CONTACT.ERROR')"
          type="error"
          show-icon
          :closable="false"
        />
      </div>
      <el-checkbox
        v-if="updateExistingContact"
        v-model="allowUpdateChecked"
        class="create-contact__primary-keys-checkbox"
      >
        {{ $t('EDIT_CONTACT.ALLOW_UPDATE') }}
      </el-checkbox>
      <div v-if="allowUpdateChecked">
        <el-alert
          :title="$t('EDIT_CONTACT.WARNING')"
          type="warning"
          show-icon
          :closable="false"
        />
      </div>
    </el-form>

    <!------------------------>
    <!--- Other keys FORM  --->
    <!------------------------>

    <el-form
      ref="form-otherKeys"
      class="create-contact__other-keys"
      :model="otherKeysForm"
      :rules="itemsOtherKeyRules"
    >
      <div
        v-for="item in formItems.otherKeys"
        :key="item.id"
      >
        <el-form-item
          :label="item.name_in_db"
          :prop="item.name_in_db"
        >
          <!-- Special rendering for country field -->
          <el-select
            v-if="item.name_in_db === 'COUNTRY_CODE'"
            v-model="otherKeysForm[item.name_in_db]"
            filterable
            clearable
            :disabled="disableOtherKeys || loadingPKState || disableCountrySelect"
            @change="validateMobile"
          >
            <el-option
              v-for="(name, codeISO) in countries"
              :key="codeISO"
              :label="name"
              :value="codeISO"
            />
          </el-select>
          <el-input
            v-else-if="item.display.component === 'el-input'"
            v-model="otherKeysForm[item.name_in_db]"
            :disabled="disableOtherKeys || loadingPKState"
            :maxlength="getMaxLength(item)"
          />
          <el-input-number
            v-else-if="item.display.component === 'el-input-number'"
            v-model="otherKeysForm[item.name_in_db]"
            v-model.number="otherKeysForm[item.name_in_db]"
            :disabled="disableOtherKeys || loadingPKState"
            :max="getMaxLength(item)"
          />
          <el-date-picker
            v-else-if="item.display.component === 'el-date-picker'"
            v-model="otherKeysForm[item.name_in_db]"
            :type="item.display.type"
            :disabled="disableOtherKeys || loadingPKState"
            :value-format="item.display.type === 'datetime' ? 'yyyy-MM-ddTHH:mm:ssZ' : 'yyyy-MM-dd'"
          />

          <el-radio-group
            v-else-if="item.display.component === 'el-radio'"
            v-model="otherKeysForm[item.name_in_db]"
            :disabled="disableOtherKeys || loadingPKState"
          >
            <el-radio :label="false">
              0
            </el-radio>
            <el-radio :label="true">
              1
            </el-radio>
            <el-radio
              v-if="!item.mandatory"
              :label="null"
            >
              {{ $t('EDIT_CONTACT.EMPTY_BOOLEAN') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>

    <!-------------->
    <!--- FOOTER --->
    <!-------------->

    <span slot="footer">
      <el-button
        size="medium"
        type="primary"
        :disabled="disableOtherKeys || loadingPKState"
        @click="save()"
      >
        {{ (!create || updateExistingContact) ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import config from '@/config'
import { debounce } from 'lodash-es'
const libphonenumber = require('google-libphonenumber')

export default {
  name: 'ModalEditContact',
  props: {
    create: { type: Boolean, required: true },
    data: { type: Object, default: () => {}},
    showModal: { type: Boolean, required: true },
    list: { type: Object, required: true },
  },
  data: function () {
    return {
      datatypes: config.REF_DATA_TYPES,
      primaryKeysForm: {},
      otherKeysForm: {},
      loadingPKState: false,
      updateExistingContact: false,
      allowUpdateChecked: false,
      primayKeysFilled: false,
      countries: {},
      disableCountrySelect: false
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters['lists/loadingContacts']
    },
    disablePrimaryKeys: function () {
      // disabled
      if (this.create === false) { return true }

      // enabled
      if (this.updateExistingContact === false) { return false }
      if (this.updateExistingContact === true && this.allowUpdateChecked === false) { return false }

      // disabled
      return true
    },
    disableOtherKeys: function () {
      // enabled
      const createNewContact = this.primayKeysFilled === true && this.updateExistingContact === false
      const createExistingContact = this.primayKeysFilled === true && this.updateExistingContact === true && this.allowUpdateChecked === true
      if (this.create === false || createNewContact || createExistingContact) {
        return false
      }
      // disabled
      return true
    },
    formItems: function () {
      let items = this.list.fields
      items.forEach(item => {
        let component = config.REF_DATA_TYPES.filter((d) => d.value === item.datatype_id)
        item.display = component.length > 0 ? component[0] : {}
      })
      let primaryKeys = items
        .filter(item => item.primary_key === true && item.displayed === true)
        .sort((a, b) => (a.displayed_order > b.displayed_order) ? 1 : -1)

      let otherKeys = items
        .filter(item => item.primary_key === false && item.displayed === true)
        .sort((a, b) => (a.displayed_order > b.displayed_order) ? 1 : -1)

      // Add country code select if it is has a default value
      if (this.list.default_country_code) {
        otherKeys.push({
          name_in_db: "COUNTRY_CODE",
          display: {}
          })
      }

      return {primaryKeys: primaryKeys, otherKeys: otherKeys}
    },
    itemsPrimaryKeyRules: function () {
      var rules = {}

      if (this.primaryKeysForm) {
        for (var i = 0; i < this.primaryKeysForm.length; i++) {
          rules[this.primaryKeysForm[i].name_in_db] = this.getFormRules(this.primaryKeysForm[i])
        }
      }
      return rules
    },
    itemsOtherKeyRules: function () {
      var rules = {}

      if (this.formItems.otherKeys) {
        for (var i = 0; i < this.formItems.otherKeys.length; i++) {
          rules[this.formItems.otherKeys[i].name_in_db] = this.getFormRules(this.formItems.otherKeys[i])
        }
      }
      return rules
    }
  },
  watch: {
    updateExistingContact(newValue)  {
      if (newValue === false) {
        this.otherKeysForm = this.initFormData(false, {})
      }
    },
    primaryKeysForm: {
      deep: true,
      handler() {
        this.debouncedCheckPrimaryKeysFilled()
      }
    }
  },
  created () {
    this.debouncedCheckPrimaryKeysFilled = debounce(() => {
      this.onPrimaryKeysFilled()
    }, 900)

    this.initCountries()
    this.primaryKeysForm = this.initFormData(true, this.data)
    this.otherKeysForm = this.initFormData(false, this.data)
  },
  methods: {
    initCountries () {
      import(`@/assets/data/countries/countries_${this.$i18n.locale}.json`).then((countries) => {
        this.countries = countries.default
      })
    },
    validateMobile () {
      let mobileField = this.$refs['form-primaryKeys'].fields.find((f) => f.prop === 'MOBILE')

      if (!mobileField) {
        mobileField = this.$refs['form-otherKeys'].fields.find((f) => f.prop === 'MOBILE')
      }
      mobileField.validate()
    },
    getFormRules (item) {
      let rules = [{
        required: item.mandatory === true,
        type: item.display.component === 'el-date-picker' ? 'string': item.display.type,
        message: item.name_in_db + this.$t('EDIT_CONTACT.ERROR_FIELD_EMPTY')
      }]

      if (item.name_in_db === 'EMAIL') {
        rules.push({validator: this.validateEmailFormat})
      }

      if (item.name_in_db === 'MOBILE') {
        rules.push({validator: this.validateMobileFormat})
      }
      return rules
    },
    getMaxLength (item) {
      if (item.name_in_db === 'EMAIL') {
        return 250
      }
      if (item.name_in_db === 'MOBILE') {
        return 20
      }
      if (item.display.component === 'el-input-number') {
        return 999999999
      }
      return 4000
    },
    validateEmailFormat (rule, value, callback) {
      const recipientRegEx = /^[-a-zA-Z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-zA-Z0-9!#$%&'*+/=?^_`{|}~]+)*@(([a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9]+)?){1,63}\.)+([a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9]+)?){2,63}$/
      let isValidEmail = value === undefined || recipientRegEx.test(value)
      if (!isValidEmail) {
        callback(new Error(this.$t('ERRORS.INVALID_EMAIL_FORMAT')))
      } else {
        callback()
      }
    },
    validateMobileFormat (rule, value, callback) {
      const countryCode = 'COUNTRY_CODE' in this.primaryKeysForm ? this.primaryKeysForm['COUNTRY_CODE'] : this.otherKeysForm['COUNTRY_CODE']
      if (countryCode === undefined && value) {
        callback(new Error(this.$t('ERRORS.MISSING_COUNTRY_CODE')))
        return
      }
      let isValidPhoneNumber = true
      const phoneUtils = libphonenumber.PhoneNumberUtil.getInstance()
      try {
        var number = phoneUtils.parse(value, countryCode)
        isValidPhoneNumber = phoneUtils.isValidNumber(number)
      }
      catch (e) {
        isValidPhoneNumber = false
      }
      if (!isValidPhoneNumber && value) {
        callback(new Error(this.$t('ERRORS.INVALID_PHONE_NUMBER_FORMAT')))
      } else {
        callback()
      }
    },
    replaceUndefinedValues: function () {
      // necessary beacause input-number's value is undefined if no data is filled
      let form = { ...this.otherKeysForm }
      Object.keys(form).forEach((key) => {
        if (form[key] === undefined) {
          form[key] = null
        }
      })
      return form
    },
    initFormData: function (isPrimary, contactData) {
      const keys = this.list.fields
        .filter(item => item.primary_key === isPrimary)
        .map(f => f.name_in_db)

      let form = {}

      const dateTypes = config.REF_DATA_TYPES
        .filter(c => c.component === 'el-date-picker')
        .map(c => c.value)

      const dateKeys = this.list.fields
        .filter(item => keys.indexOf(item.name_in_db) > -1 && dateTypes.indexOf(item.datatype_id) > -1)
        .map(f => f.name_in_db)

      form = this.fillForm(contactData, keys, dateKeys)
      form = this.formatFormDates(form, dateKeys)
      form = this.addCountryToForm(form, isPrimary)

      return form
    },
    fillForm(contactData, formKeys, dateKeys) {
      let form = {}

      formKeys.forEach(key => {
        let defaultValue = undefined
        if (dateKeys.includes(key)) {
          defaultValue = null
        }
        form[key] = contactData[key] !== undefined ? contactData[key] : defaultValue
      })
      return form
    },
    formatFormDates(form, dateKeys) {
      dateKeys.forEach(key => {
        if (form[key] === null) return
        form[key] = new Date(form[key])
      })
      return form
    },
    addCountryToForm(form, isPrimary) {
      if (this.list.default_country_code && !isPrimary) {
        form.COUNTRY_CODE = this.list.default_country_code
        this.disableCountrySelect = true
      }
      return form
    },
    closeModal () {
      this.$emit('refresh')
      this.$emit('update:showModal', false)
    },
    onPrimaryKeysFilled(event) {
      if (event) { event.preventDefault() }
      let fields = this.$refs['form-primaryKeys'].fields
      const notValid = fields.find((f) => f.validateState !== 'success')
      this.primayKeysFilled = notValid === undefined
      if (this.primayKeysFilled) {
        this.checkIfContactExists()
      }
    },
    checkIfContactExists(){
      this.loadingPKState = true
      const params = {
        ...this.primaryKeysForm
      }

      this.$store.dispatch('lists/retrieveContacts', { listId: this.list.id, params: params, storeContacts: false })
        .then((res) => {
          this.updateExistingContact = res.meta.total > 0

          // Fill form with existing contact data
          if (this.updateExistingContact) {
            this.otherKeysForm = this.initFormData(false, res.objects[0])
          }
        })
        .finally(() => {
          this.loadingPKState = false
        })
    },
    save() {
      this.$refs['form-otherKeys'].validate((valid) => {
        if (valid) {
          // Replace undefined with null without mutation
          const otherKeys = this.replaceUndefinedValues()

          const queryData = {
            'contact_data': {
              ...this.primaryKeysForm,
              ...otherKeys
            },
            'import_type_id': config.UPLOAD_TYPE.MANUAL,
            'update_type_id': config.UPDATE_TYPE.MERGE,
          }
          let action = this.updateExistingContact || !this.create ? 'edit' : 'create'
          this.$emit('import', this.list.id, queryData, action)
        }
        return
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--mobile-width: 500px;

::v-deep .create-contact {
  background: $--color-bg-light-gray;

  .el-dialog__header {
    padding-top: 48px;
  }
  .el-from {
    width: 500px;
  }
  .el-dialog__body {
    padding: 25px 35px 25px;
  }
  .title {
    margin: 10px;
    font-size: 36px;
    font-weight: 300;
  }
  .subtitle {
    margin: 10px;
    font-size: 21px;
    font-weight: 400;
  }
  &__exit-btn {
    position: absolute;
    top: 36px;
    right: 44px;
  }
  &__primary-keys {
    background: lighten($--color-tertiary, 49%);
    border: solid 1px;
    border-radius: 3px;
    border-color: lighten($--color-tertiary, 28%);
    padding: 30px;
    margin: 0 33%;
      .el-icon-key {
        width: 30px;
        overflow: hidden;
        &::before {
          @include rotate(90);
          display: inline-block;
          font-size: 1.2rem;
          color: $--color-tertiary;
        }
      }
  }
  &__primary-keys-checkbox {
    padding-top: 20px;
    padding-bottom: 20px;
    color: $--color-text-regular
  }
  &__other-keys {
    padding: 30px;
    margin: 0 33%;
    .is-disabled {
      .el-input__inner {
        background: $--color-input-modal-disabled;
      }
    }
  }
}

::v-deep .el-loading-mask {
  background: lighten($--color-tertiary, 49%);
  border: solid 1px;
  border-radius: 3px;
  border-color: lighten($--color-tertiary, 28%);
  opacity: 0.7;
}

::v-deep .setup-loading {
  text-align: center;
  &__logo {
    margin: 24px 0px 12px 0px;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    margin: 44px 0px 24px 0px;
  }
  &__description {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
