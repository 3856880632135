<template>
  <div class="contact-details-main">
    <!-- CONTACT DETAILS MAIN HEADER -->
    <div class="contact-details-main__header">
      <el-button
        class="contact-details-main__header__back el-button--small"
        @click="backToSearch"
      >
        <i class="el-icon-arrow-left" />
        {{ $t('BUTTON.BACK_SEARCH') }}
      </el-button>
    </div>

    <!-- CONTACT DETAILS MAIN INFOS -->
    <div class="contact-details-main__infos">
      <div
        class="contact-details-main__infos__channel-img"
        :style="styles"
      >
        <img
          :src="ImgUrl"
          :style="imgStyles"
          alt="logo"
        >
      </div>
      <div class="contact-details-main__infos__title">
        {{ $t('CONTACT_CARDS.CONTACT', {'channel': capitalizeFirstLetter(channel)}) }}
      </div>
      <div>
        {{ contact.email || '+' + contact.mobile }}
      </div>

      <div class="contact-details-main__infos__reaction">
        <i class="material-icons">event</i>
        <span v-if="kpis.last_reaction_date">
          <el-tooltip
            effect="dark"
            placement="top"
            transition=""
            :open-delay="400"
            :hide-after="0"
            :content="kpis.last_reaction_date | moment('LLL')"
          >
            <span>{{ $t('MISC.LAST_REACTION') }} {{ kpis.last_reaction_date | moment('LL') }}</span>
          </el-tooltip>
        </span>
        <span v-else>
          {{ $t('MISC.NO_REACTION') }}
        </span>
      </div>
    </div>

    <!-- CONTACT DETAILS CONTACTABILITY STATUS -->
    <div class="contact-details-main__status">
      <span class="contact-details-main__status__title">
        {{ $t('CONTACT_CARDS.CONTACTABILITY_STATUS') }}
      </span>
      <el-tooltip
        effect="dark"
        placement="top"
        :content="$t('CONTACT_CARDS.CONTACTABILITY_STATUS_TOOLTIP')"
      >
        <i class="material-icons help">help_outline</i>
      </el-tooltip>
      <StatusTag
        class="contact-details-main__status__tag"
        :status="contact.status_id"
      />
      <el-button
        type="text"
        class="text-secondary"
        @click="showStatusModal=true"
      >
        {{ $t('CONTACT_CARDS.VIEW_STATUS') }}
      </el-button>
    </div>

    <!-- CONTACT DETAILS GROUPS -->
    <div class="contact-details-main__groups">
      <span class="contact-details-main__groups__title">
        {{ $t('MISC.GROUPS') }}
      </span>
      <el-tooltip
        effect="dark"
        :content="$t('CONTACT_CARDS.GROUPS_TOOLTIP')"
        placement="top"
      >
        <i class="material-icons help">help_outline</i>
      </el-tooltip>
      <div class="contact-details-main__groups__avatars">
        <div
          v-for="group in displayedGroups"
          :key="group.name"
        >
          <el-tooltip
            effect="dark"
            placement="top"
            :content="group.name"
          >
            <AvatarHolder
              :avatar-url="group.logo_url || null"
              :display-name="group.name"
              :width="25"
              :height="25"
              :font-size="8"
            />
          </el-tooltip>
        </div>
        <el-button
          v-if="hiddenGroupsCount"
          type="text"
          class="text-secondary"
          @click="showGroupsModal = true"
        >
          {{ `+${hiddenGroupsCount}` }}
        </el-button>
      </div>
      <div class="contact-details-main__groups_show">
        <el-button
          type="text"
          class="text-secondary"
          @click="showGroupsModal = true"
        >
          {{ $t('CONTACT_CARDS.VIEW_GROUPS') }}
        </el-button>
      </div>
    </div>
    <ModalGroups
      :dialog-visible.sync="showGroupsModal"
      :groups="orderedGroups"
    />
    <ModalContactabilityStatus
      :dialog-visible.sync="showStatusModal"
      :channel="channel"
      :contact="contact"
      :is-confirmation-modal="isConfirmationModal"
      @action="action"
      @switchModal="isConfirmationModal = !isConfirmationModal"
    />
  </div>
</template>

<script>
import emailImage from '@/assets/img/email-active.svg'
import mobileImage from '@/assets/img/mobile-active.svg'
import background from '@/assets/img/channel-bg.svg'
import {
  StatusTag,
  AvatarHolder,
  ModalGroups,
  ModalContactabilityStatus,
} from '@/components'
import config from '@/config'
import { orderBy } from 'lodash-es'

export default {
  name: 'ContactDetailsMain',
  components: {
    StatusTag,
    AvatarHolder,
    ModalGroups,
    ModalContactabilityStatus,
  },
  data () {
    return {
      backgroundUrl: background,
      groupsDisplayLimit: 5,
      showGroupsModal: false,
      showStatusModal: false,
      isConfirmationModal: false,
      loadingAction: false
    }
  },
  computed: {
    contact () {
      return this.$store.getters['contacts/contact']
    },
    kpis () {
      return this.$store.getters['contacts/kpis']
    },
    isBlacklisted () {
      return this.contact.status_id === config.CONTACT_CARD_STATUS.BLACKLIST
    },
    channel () {
      return this.$route.params.channel
    },
    isEmail () {
      return this.channel === 'email'
    },
    ImgUrl () {
      if (this.isEmail) { return emailImage }
      return mobileImage
    },
    styles () {
      let css = 'background-image: url(' + this.backgroundUrl + ');'
      css += 'width: 80px; height: 80px'
      return css
    },
    imgStyles () {
      return this.isEmail ? 'width: 40px; height: 40px': 'width: 50px; height: 50px'
    },
    orderedGroups () {
      return orderBy(this.contact.groups, [gr => gr.name.toLowerCase()])
    },
    displayedGroups () {
      if (!this.contact.groups) { return [] }
      return this.orderedGroups.slice(0, this.groupsDisplayLimit)
    },
    hiddenGroupsCount () {
      if (!this.contact.groups) { return 0 }
      return this.contact.groups.slice(this.groupsDisplayLimit).length
    }
  },
  methods: {
    action (value) {
      switch (value) {
        case 'BLACKLIST': {
          this.updateStatus('blacklistContact')
          break
        }
        case 'UNBLACKLIST': {
          this.updateStatus('unblacklistContact')
          break
        }
        case 'HARDBOUNCE': {
          this.updateStatus('unhardbounce')
          break
        }
      }
    },
    backToSearch () {
      this.$router.push({path: '/contacts'})
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    updateStatus (method) {
      this.$store
        .dispatch(`contacts/${method}`, { channel: this.channel, id: this.contact.id})
        .then(() => {
          const blacklistKey = config.CONTACT_CARD_STATUS.BLACKLIST.toString()
          const hardbounceKey = config.CONTACT_CARD_STATUS.HARDBOUNCE.toString()
          const newEntry = {
              action_date: moment().format(),
              action_value: null,
              contact_id: this.contact.id
            }
          // refresh status data after blacklist
          switch (method) {
            case 'unblacklistContact': {
              const status = Object.assign({}, this.contact.status)
              delete (status[blacklistKey])
              const statusId = Object.keys(status).length > 0 ? parseInt(Object.keys(status).sort()[0]): null
              this.setContactStatus(status, statusId, moment().format())
              newEntry.action_type = 'unblacklist'
              this.$notify({
                title: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.STATUS_SUCCESS'),
                message: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.UNBLACKLIST_SUCCESS'),
                type: 'success'
              })
              break
            }
            case 'blacklistContact': {
              const status = Object.assign({}, this.contact.status)
              status[blacklistKey] = moment().format()
              this.setContactStatus(status, config.CONTACT_CARD_STATUS.BLACKLIST, null)
              newEntry.action_type = 'blacklist'
              this.$notify({
                title: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.STATUS_SUCCESS'),
                message: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.BLACKLIST_SUCCESS'),
                type: 'success'
              })
              break
            }
            case 'unhardbounce': {
              const status = Object.assign({}, this.contact.status)
              delete (status[hardbounceKey])
              const statusId = Object.keys(status).length > 0 ? parseInt(Object.keys(status).sort()[0]): null
              this.setContactStatus(status, statusId, moment().format())
              newEntry.action_type = 'dehardification'
              this.$notify({
                title: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.STATUS_SUCCESS'),
                message: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.NPAI_REMOVED_SUCCESS'),
                type: 'success'
              })
              break
            }
          }

          this.contact.change_history.unshift(newEntry)
          this.isConfirmationModal = false
        })
        .catch(() => {
          switch (method) {
            case 'blacklistContact': {
              this.$notify({
                title: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.STATUS_SUCCESS'),
                message: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.BLACKLIST_FAIL'),
                type: 'error'
              })
              break
            }
            case 'unblacklistContact': {
              this.$notify({
                title: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.STATUS_SUCCESS'),
                message: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.UNBLACKLIST_FAIL'),
                type: 'error'
              })
              break
            }
            case 'unhardbounce': {
              this.$notify({
                title: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.STATUS_SUCCESS'),
                message: this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CONFIRM.NPAI_REMOVED_FAIL'),
                type: 'error'
              })
              break
            }
          }
        })
    },
    setContactStatus (status, statusId, unblacklistDate) {
      this.$set(this.contact, 'status_id', statusId)
      this.$set(this.contact, 'status', status)
      this.$set(this.contact, 'unblacklist_date', unblacklistDate)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.contact-details-main {
  position: relative;
  .material-icons.help {
    font-size: 15px;
    position: relative;
    top: 4px;
    margin-left: 5px;
    color: $--color-secondary;
    cursor: default;
  }
  .contact-details-main__header {
    padding: 2rem 2rem 1rem 2rem;
    border-bottom: 1px solid $--color-light-gray;
    .contact-details-main__header__back {
      padding: 9px 15px 9px 10px;
      .el-icon-arrow-left {
        margin-right: 5px;
      }
    }
  }
  .contact-details-main__status {
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid $--color-light-gray;
    .contact-details-main__status__title {
      font-weight: 600;
    }
    .contact-details-main__status__tag {
      margin: 10px auto 0;
    }
  }
  .contact-details-main__infos {
    text-align: center;
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $--color-light-gray;
    .contact-details-main__infos__channel-img {
      text-align: center;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      @include flex-direction(column);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 20px auto;
    }
    .contact-details-main__infos__title {
      font-weight: 600;
      margin: 10px;
    }
    .contact-details-main__infos__reaction {
      margin: 10px;
      display: inline-flex;
      align-items: center;
      > i.material-icons {
        color: $--color-primary;
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }
  .contact-details-main__groups {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $--color-light-gray;
    .contact-details-main__groups__title {
      font-weight: 600;
    }
    .contact-details-main__groups__avatars {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        margin: 5px 5px 0 0;
      }
    }
    .contact-details-main__groups_show {
      display: block;
      width: 100%;
    }
  }
}


/* For mobile: */
@media only screen and (max-width: 900px) {
  .contact-details-main {
    .contact-details-main__header {
      border-bottom: none;
      padding: 20px 20px 0;
      text-align: right;
    }
    &__content {
      padding: 1rem;
    }
    &__type {
      @include flexbox;
      @include align-items(center);
    }
    &__name {
      margin-right: 150px;
    }
  }
}
</style>
