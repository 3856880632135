<template>
  <!-- STEP 1.1 LOADING -->
  <div
    v-if="!list || !list.fields || list.fields.length === 0"
    class="setup-loading"
  >
    <LoadingFile class="setup-loading__logo" />
    <div class="setup-loading__title">
      {{ $t('SETUP.UPLOAD.PROCESSING_FILE_TITLE') }}
    </div>
    <div class="setup-loading__description">
      {{ $t('SETUP.UPLOAD.PROCESSING_FILE_DESCRIPTION') }}
    </div>
  </div>
  <div
    v-else
    class="setup-columns-mapping"
  >
    <div class="info-mandatory">
      <span class="asterisk">*</span> {{ $t('MANDATORY') }}
    </div>
    <h3>
      <span class="asterisk">*</span>
      {{ $t('SETUP.CONTACT_CHANNEL') }}
      <el-tooltip
        effect="dark"
        placement="top"
        transition=""
        :open-delay="400"
        :hide-after="0"
        :content="$t('SETUP.CONTACT_CHANNEL_TOOLTIP')"
      >
        <i class="material-icons form-item-help">help_outlined</i>
      </el-tooltip>
    </h3>
    <el-form label-position="top">
      <el-alert
        v-show="displayRequiredFieldsError && !hasOptin"
        class="setup-fields__alert"
        :title="$t('SETUP.FIELDS.ERROR_MISSING_REQUIRED_MAPPING')"
        type="error"
        show-icon
        :closable="false"
      />
      <el-row>
        <el-col :span="6">
          <el-form-item :label="contact_channels[0].label">
            <el-select
              :value="contact_channels[0].field"
              placeholder=""
              clearable
              filterable
              @input="contactChannelUpdate(contact_channels[0], 'field', $event)"
            >
              <el-option
                v-for="field in nonOptinFields(contact_channels[0].optin)"
                :key="field.id"
                :label="field.name_in_file"
                :value="field.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item :label="$t('CONTACT_DETAILS.CONTACTABILITY.OPTINS')">
            <el-select
              :value="contact_channels[0].optin"
              style="width: 360px"
              placeholder=""
              clearable
              filterable
              :loading="loadingOptins"
              @input="contactChannelUpdate(contact_channels[0], 'optin', $event)"
            >
              <el-option
                v-for="optin in email_optins"
                :key="optin.id"
                :label="optin.name"
                :value="optin.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item :label="contact_channels[1].label">
            <el-select
              :value="contact_channels[1].field"
              placeholder=""
              clearable
              filterable
              @input="contactChannelUpdate(contact_channels[1], 'field', $event)"
            >
              <el-option
                v-for="field in nonOptinFields(contact_channels[1].optin)"
                :key="field.id"
                :label="field.name_in_file"
                :value="field.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item :label="$t('CONTACT_DETAILS.CONTACTABILITY.OPTINS')">
            <el-select
              :value="contact_channels[1].optin"
              style="width: 360px"
              placeholder=""
              clearable
              filterable
              :loading="loadingOptins"
              @input="contactChannelUpdate(contact_channels[1], 'optin', $event)"
            >
              <el-option
                v-for="optin in mobile_optins"
                :key="optin.id"
                :label="optin.name"
                :value="optin.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div
        v-if="contact_channels[1].field && contact_channels[1].optin"
        class="country-code-wrapper"
      >
        <div class="phone-number-settings">
          {{ $t("SETUP.PHONE_NUMBER_SETTINGS") }}
        </div>
        <el-row>
          <el-col :span="11">
            <el-form-item :label="$t('SETUP.NUMBER_OF_COUNTRIES')">
              <el-select
                v-model="contact_channels[1].mobile_prefix_mode"
                placeholder=""
              >
                <el-option
                  :value="config.MOBILE_PREFIX_MODE.UNIQUE"
                  :label="$t('SETUP.UNIQUE_COUNTRY')"
                />
                <el-option
                  :value="config.MOBILE_PREFIX_MODE.MULTIPLE"
                  :label="$t('SETUP.MULTIPLE_COUNTRIES')"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item
              v-if="contact_channels[1].mobile_prefix_mode == config.MOBILE_PREFIX_MODE.UNIQUE"
              :label="$t('SETUP.COUNTRY_OF_DESTINATION')"
            >
              <el-select
                v-model="contact_channels[1].mobile_prefix_isocode"
                style="width: 300px"
                placeholder=""
                clearable
                filterable
              >
                <el-option
                  v-for="(country, isocode) in countries"
                  :key="isocode"
                  :label="country"
                  :value="isocode"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="contact_channels[1].mobile_prefix_mode == config.MOBILE_PREFIX_MODE.MULTIPLE"
              :label="$t('SETUP.COUNTRY_COLUMN')"
            >
              <el-select
                v-model="contact_channels[1].mobile_prefix_field"
                style="width: 300px"
                placeholder=""
                clearable
                filterable
              >
                <el-option
                  v-for="field in nonOptinFields(contact_channels[1].mobile_prefix_field)"
                  :key="field.name_in_file"
                  :label="field.name_in_file"
                  :value="field.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <el-divider />
    <h3>{{ $t('SETUP.COLUMN_MAPPING') }}</h3>

    <el-alert
      v-show="displayRequiredPrimaryKeyError && !hasPrimaryKey"
      class="setup-fields__alert alert-mapping"
      :title="$t('SETUP.FIELDS.ERROR_MISSING_PRIMARY_KEY')"
      type="error"
      show-icon
      :closable="false"
    />

    <el-alert
      v-show="duplicatedNames.length > 0"
      class="setup-fields__alert alert-mapping"
      :title="$t('SETUP.FIELDS.ERROR_DUPLICATED_NAMES', { keys: duplicatedNames.join(', ') })"
      type="error"
      show-icon
      :closable="false"
    />

    <el-alert
      v-show="useReservedNames.length > 0"
      class="setup-fields__alert alert-mapping"
      :title="$t('SETUP.FIELDS.ERROR_RESERVED_KEY', { keys: useReservedNames.join(', ')})"
      type="error"
      show-icon
      :closable="false"
    />

    <div class="table-wrapper">
      <el-table
        :data="mappings"
        stripe
      >
        <el-table-column width="120">
          <template #header>
            {{ $t('FIELDS.PRIMARY_KEY') }}
            <el-tooltip
              effect="dark"
              placement="top"
              transition=""
              :open-delay="400"
              :hide-after="0"
              :content="$t('SETUP.PRIMARY_KEY_TOOLTIP')"
            >
              <i class="material-icons form-item-help">help_outlined</i>
            </el-tooltip>
          </template>
          <template #default="scope">
            <!-- v-model="fields[scope.$index].primary_key" -->
            <div>
              <el-checkbox
                :value="scope.row.primary_key"
                :disabled="scope.row.id === countryCodeField"
                @change="value => fields[scope.$index].primary_key = value"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column width="130">
          <template #header>
            {{ $t('FIELDS.MANDATORY') }}
            <el-tooltip
              effect="dark"
              placement="top"
              transition=""
              :open-delay="400"
              :hide-after="0"
              :content="$t('SETUP.MANDATORY_TOOLTIP')"
            >
              <i class="material-icons form-item-help">help_outlined</i>
            </el-tooltip>
          </template>
          <template #default="scope">
            <el-checkbox
              :value="scope.row.mandatory"
              :disabled="scope.row.primary_key === true || scope.row.id === countryCodeField"
              @change="value => fields[scope.$index].mandatory = value"
            />
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            {{ $t('FIELDS.COLUMN_NAME') }}
            <el-tooltip
              effect="dark"
              placement="top"
              transition=""
              :open-delay="400"
              :hide-after="0"
              :content="$t('SETUP.COLUMN_NAME_TOOLTIP')"
            >
              <i class="material-icons form-item-help">help_outlined</i>
            </el-tooltip>
          </template>
          <template #default="scope">
            <el-input
              :class="{ 'is-error': duplicatedNames.includes(scope.row.name_in_db) || useReservedNames.includes(scope.row.name_in_db) }"
              :value="scope.row.name_in_db"
              :maxlength="255"
              :disabled="(parseInt(mappings[scope.$index].optin_type_id) && mappings[scope.$index].optin_type_id !== 0) || mappings[scope.$index].id === countryCodeField"
              @input="value => columnNameCallback(value, scope.$index)"
            />
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            {{ $t('FIELDS.SOURCE') }}
            <el-tooltip
              effect="dark"
              placement="top"
              transition=""
              :open-delay="400"
              :hide-after="0"
              :content="$t('SETUP.SOURCE_TOOLTIP')"
            >
              <i class="material-icons form-item-help">help_outlined</i>
            </el-tooltip>
          </template>
          <template #default="scope">
            <div>
              {{ scope.row.name_in_file }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('FIELDS.PREVIEW')"
        >
          <template #default="scope">
            <div
              v-for="(value, idx) in sampleSplice(scope.row.sample)"
              :key="idx"
            >
              {{ value }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template #header>
            {{ $t('FIELDS.TYPE') }}
            <el-tooltip
              effect="dark"
              placement="top"
              transition=""
              :open-delay="400"
              :hide-after="0"
              :content="$t('SETUP.TYPE_TOOLTIP')"
            >
              <i class="material-icons form-item-help">help_outlined</i>
            </el-tooltip>
          </template>
          <template #default="scope">
            <el-cascader
              :value="formatValueDataTypeId(scope.row)"
              :disabled="(scope.row.optin_type_id !== null && scope.row.optin_type_id !== undefined && scope.row.optin_type_id !== 0) || scope.row.id === countryCodeField"
              :options="datatypeOptions"
              class="setup-columns__fields-desc__form__select-datatype"
              @input="updateDataTypeId(fields[scope.$index], $event)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import config from '@/config'
export default {
  name: 'SetupColumnsMapping',
  components: {  },
  props: {
    list: { type: Object, required: true },
    step: { type: Number, required: true },
  },
  data() {
    return {
        sendForm: false,
        displayRequiredFieldsError: false,
        displayRequiredPrimaryKeyError: false,
        datatypeOptions: [],
        countries: [],
        listTypes: config.LIST_TYPES,
        fields: [],
        email_optins: [],
        mobile_optins: [],
        contact_channels: [
          {"field_name": "EMAIL", "label": this.$t("MISC.EMAIL"), "field": null, "optin": null, "optins": []},
          {"field_name": "MOBILE", "label": this.$t("MISC.PHONE_NUMBER"), "field": null, "optin": null, "optins": [], "mobile_prefix_mode": config.MOBILE_PREFIX_MODE.UNIQUE, "mobile_prefix_isocode": "FR", "mobile_prefix_field": null},
        ],
        loadingOptins: null
    }
  },
  computed: {
    config () {
      return config
    },
    selectable_columns () {
      var country_code_field = this.contact_channels.filter((contact_channel) => contact_channel.mobile_prefix_field || null)[0] || null
      return country_code_field != null ? [country_code_field.name_in_file].concat(this.non_optin_fields) : this.non_optin_fields
    },
    isActiveList() {
      return this.list.type_id === this.listTypes.ACTIVE
    },
    mappings () {
      var fields = JSON.parse(JSON.stringify(this.fields))
      var contact_channels = this.contact_channels

      for (var u = 0; u < fields.length; u++) {
        fields[u].optin_type_id = 0
      }

      for (var j = 0; j < contact_channels.length; j++) {
        for (var i = 0; i < fields.length; i++) {
          if (fields[i].primary_key) {
            fields[i].mandatory = true
          }

          if (contact_channels[j].field === fields[i].id && contact_channels[j].optin) {
            fields[i].name_in_db = contact_channels[j].field_name
            fields[i].optin_type_id = contact_channels[j].optin
            fields[i].datatype_id = 3
          }
        }
      }

      if (this.countryCodeField) {
        for (var k = 0; k < fields.length; k++) {
          const mobileContactsField = this.fields.find(f => f.id === contact_channels[1].field)

          if (mobileContactsField && this.countryCodeField === fields[k].id) {
            fields[k].primary_key = mobileContactsField.primary_key
            fields[k].mandatory = mobileContactsField.mandatory || mobileContactsField.primary_key
            fields[k].name_in_db = "COUNTRY_CODE"
            fields[k].datatype_id = 3
          }
        }
      }

      return fields
    },
    hasPrimaryKey () {
      return this.mappings.find(f => f.primary_key) !== undefined
    },
    hasOptin () {
      return this.mappings.find(f => f.optin_type_id) !== undefined
    },
    duplicatedNames () {
      const names = this.mappings.map(f => f.name_in_db)
      return names.filter((item, index) => names.indexOf(item) !== index)
    },
    useReservedNames () {
      return this.mappings.map(f => f.name_in_db).filter((item) => config.RESERVED_FIELDS.includes(item) || item.match(config.RESERVED_FIELDS_REGEX))
    },
    countryCodeField () {
      if (this.contact_channels[1].field && this.contact_channels[1].optin && this.contact_channels[1].mobile_prefix_mode === config.MOBILE_PREFIX_MODE.MULTIPLE) {
        return this.contact_channels[1].mobile_prefix_field
      }
      return null
    }
  },
  created () {
    this.datatypeOptions = []
    var optObj = null
    config.REF_DATA_TYPES.forEach(option => {
      optObj = Object.assign({}, option)
      optObj['label'] = this.$t(option['label'])
      this.datatypeOptions.push(optObj)
    })

    this.initCountries()
    this.initFields()
    this.initFormOptins()
    this.initOptins()
  },
  methods: {
    nonOptinFields (currentOptin) {
      return this.mappings.filter((field) => !field.optin_type_id || (currentOptin && field.optin_type_id === currentOptin)).sort(function (a, b) {
        const aln = a.name_in_file.trim().toLowerCase()
        const bln = b.name_in_file.trim().toLowerCase()

        if (aln < bln) {
          return -1
        }

        if (aln > bln) {
          return 1
        }

        return 0
      })
    },
    initFields(){
      var fields = new Array()
      this.list.fields.forEach((field) => {
        field.name_in_db = this.columnNameFormat(field.name_in_db)
        fields.push(field)
      })
      this.fields = fields
    },
    initFormOptins(){
      var fields = this.fields
      this.contact_channels.forEach((cc) => {
        for (var i = 0; i < fields.length; i++) {
          if (fields[i].name_in_db === cc.field_name) {
            cc.field = fields[i].id
            cc.optin = fields[i].optin_type_id
          }
        }
      })

      if (this.list.default_country_code) {
        this.contact_channels[1].mobile_prefix_mode = config.MOBILE_PREFIX_MODE.UNIQUE
        this.contact_channels[1].mobile_prefix_isocode = this.list.default_country_code
      } else {
        for (var j = 0; j < fields.length; j++) {
          if (fields[j].name_in_db === "COUNTRY_CODE") {
            this.contact_channels[1].mobile_prefix_mode = config.MOBILE_PREFIX_MODE.MULTIPLE
            this.contact_channels[1].mobile_prefix_field = fields[j].id
          }
        }
      }
    },
    columnNameFormat(columnName){
      return columnName.replace(/[^a-zA-Z0-9/ _]/g,'').replace(/ /g,"_").toUpperCase()
    },
    columnNameCallback(value, index){
      this.fields[index].name_in_db = this.columnNameFormat(value)
    },
    sampleSplice(sample) {
      if (!sample || sample.length <= 3) {
        return sample
      }
      return sample.slice(0, 3)
    },
    contactChannelUpdate(contactChannel, prop, newValue) {
      const currentIsValid = contactChannel.field && contactChannel.optin
      // Unselect or change field, clear primary key
      if (currentIsValid && (!newValue || (prop === 'field' && newValue !== contactChannel.field))) {
        const field = this.fields.find(f => f.id === contactChannel.field)

        if (field) {
          field.primary_key = false
        }
      }

      contactChannel[prop] = newValue

      // Ensure field has primary key if valid
      if (contactChannel.field && contactChannel.optin) {
        const field = this.fields.find(f => f.id === contactChannel.field)

        if (field) {
          field.primary_key = true
        }
      }
    },
    initOptins () {
      return new Promise((resolve, reject) => {
      this.loadingOptins = true
      this.$store.dispatch('groups/getGroupsOptins', this.list.group_id)
        .then((response) => {
          var optins = response.objects
          this.email_optins = optins.filter(optin => optin.channel_id == config.CHANNELS.EMAIL).sort(function (a, b) {
            const aln = a.name.trim().toLowerCase()
            const bln = b.name.trim().toLowerCase()

            if (aln < bln) {
              return -1
            }

            if (aln > bln) {
              return 1
            }

            return 0
          })
          this.mobile_optins = optins.filter(optin => optin.channel_id == config.CHANNELS.SMS).sort(function (a, b) {
            const aln = a.name.trim().toLowerCase()
            const bln = b.name.trim().toLowerCase()

            if (aln < bln) {
              return -1
            }

            if (aln > bln) {
              return 1
            }

            return 0
          })
          this.loadingOptins = false
          resolve()
        })
        .catch(() => {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
          this.loadingOptins = false
          reject()
        })
      })
    },
    initCountries () {
      import(`@/assets/data/countries/countries_${this.$i18n.locale}.json`).then((countries) => {
        this.countries = countries.default
      })
    },
    handleNext () {
      this.sendForm = true
      this.displayRequiredFieldsError = !this.contact_channels[0].optin && !this.contact_channels[1].optin

      const field = this.mappings.find(field => field.primary_key)
      this.displayRequiredPrimaryKeyError = !field ? true : false

      if (this.displayRequiredFieldsError || this.displayRequiredPrimaryKeyError || this.duplicatedNames.length > 0 || this.useReservedNames.length > 0) {
        return
      }

      return new Promise((resolve) => {
        this.$emit('update:loading', true)
        let nextStep = this.isActiveList ? 3 : 4
        let data = {
          'listId': this.list.id,
          'data': {
            'fields': this.mappings,
            'default_country_code': this.contact_channels[1].field && this.contact_channels[1].mobile_prefix_mode === config.MOBILE_PREFIX_MODE.UNIQUE ? this.contact_channels[1].mobile_prefix_isocode : null,
            'step': nextStep
          }
        }
        // next case
        this.$store.dispatch('lists/validateColumns', data)
        .then(() => {
          this.$emit('update:step', nextStep)
          resolve(true)
        })
        .catch(() => {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
          resolve(false)
        })
        .finally(() => {
          this.$emit('update:loading', false)
        })
      })
    },
    handlePrevious () {
      this.$emit('update:step', 1)
    },
    confirmSubStep0 () {
      var columnsRef = this.$refs['setup-columns-list'].$refs
      var validatePromises = []
      for (var ref in columnsRef) {
        if (!ref.startsWith('setup-field-')) { continue }
        let ref_value = columnsRef[ref][0]
        validatePromises.push(ref_value.validate())
      }
      return validatePromises
    },
    formatValueDataTypeId: function (field) {
      const res = [field.datatype_id]
      if (field.value_format) {
        res.push(field.value_format)
      } else if ([4, 5].includes(field.datatype_id)) {
        res.push("automatic")
      }
      return res
    },
    updateDataTypeId: function (field, newValue) {
      field.datatype_id = newValue[0]
      field.value_format = newValue.length > 1 && newValue[1] === "automatic" ? null : newValue[1]
    },
    save: function () {
      return new Promise((resolve) => {
          this.displayRequiredFieldsError = !this.contact_channels[0].optin && !this.contact_channels[1].optin

          // if everything is valid patch the list
          // allFormValid
          if (!this.displayRequiredFieldsError) {
            this.$emit('update:loading', true)
            let data = {
              'listId': this.list.id,
              'data': {
                'fields': this.mappings,
                'default_country_code': this.contact_channels[1].field && this.contact_channels[1].mobile_prefix_mode === config.MOBILE_PREFIX_MODE.UNIQUE ? this.contact_channels[1].mobile_prefix_isocode : null,
                'step': 2
              }
            }
            // next case
            this.$store.dispatch('lists/validateColumns', data)
            .then(() => { resolve(true) })
            .catch(() => {
              this.$notify({
                title: this.$t('ERRORS.UNEXPECTED'),
                message: this.$t('ERRORS.UNEXPECTED_DESC'),
                type: 'error'
              })
            })
            .finally(() => {
              this.$emit('update:loading', false)
            })
          } else {
            resolve(false)
          }
        })
    },
  }
}
</script>

<style lang="scss">
.el-alert.alert-mapping {
  margin-bottom: 20px;
}

.el-input.is-error > .el-input__inner {
  border-color: #f56c6c;
}

.table-wrapper {
  padding: 20px;
  border: 1px solid #DCDFE6;
  border-radius: 3px;
  background: white;
}

.el-table .el-table__row:last-child td {
  border: 0;
}

.table-wrapper .el-table--group::after, .table-wrapper .el-table--border::after, .table-wrapper .el-table::before {
  display: none;
}

.table-wrapper .el-table__header-wrapper th > .cell {
  font-size: 12px;
  font-weight: 400;
  color: #606266;
  display: flex;
  align-items: center;
}

.table-wrapper .el-table td div {
  font-size: 14px;
}

.table-wrapper .el-checkbox__input.is-disabled.is-checked {
  .el-checkbox__inner {
    background-color: #c9c9c9;
    border-color: #c9c9c9;

    &:after {
      border-color: white;
    }
  }
}
.setup-columns-mapping {
  .country-code-wrapper {
    width: 590px;
    background-color: #F3F3F5;
    padding: 16px;
    border-radius: 4px;

    .el-form-item {
      margin-bottom: 0;
    }
  }

  h3 {
    color: #303133;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  .asterisk {
    color: #F47957;
  }

  h3 .asterisk {
    margin-right: 5px;
  }
  h3 .form-item-help {
    margin-left: 5px;
  }

  .info-mandatory {
    font-size: 14px;
    color: #606266;
    text-align: right;
    margin-bottom: 3px;
  }

  .form-item-help {
    font-size: 18px;
    margin-left: 3px;
    color: #909399;
    width: 18px;
  }
}

.phone-number-settings {
  border-bottom: 1px solid #DCDFE6;
  padding-bottom: 8px;
  color: #606266;
}
</style>
