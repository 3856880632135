<template>
  <div
    v-if="!list.step || !isContactList"
    class="list-rows"
  >
    <!-- DELETE MODAL -->
    <Modal
      :dialog-visible.sync="showDeleteModal"
      width="500px"
      :title="isContactList ? $tc('LIST_DETAILS.ACTIONS.DELETE_CONTACT_TITLE_MODAL', selected.length) : $tc('LIST_DETAILS.ACTIONS.DELETE_SUBMISSION_TITLE_MODAL', selected.length)"
      :subtitle="isContactList ? $tc('LIST_DETAILS.ACTIONS.DELETE_CONTACT_SUBTITLE_MODAL', selected.length) : $tc('LIST_DETAILS.ACTIONS.DELETE_SUBMISSION_SUBTITLE_MODAL', selected.length)"
      :btn="$t('BUTTON.CONFIRM')"
      type="danger"
      :action="deleteSelectedRows"
    />

    <!-- HEADER ACTION -->
    <transition
      name="slide-down"
      mode="out-in"
    >
      <div
        v-if="selected.length > 0 && hasCreationPermission"
        key="selected"
        class="list-rows__header-selected"
      >
        <!-- SELECTED COUNT -->
        <el-badge
          :value="selected.length"
          class="el-badge--tertiary"
        />
        {{ $tc('HOME.SELECTED_ITEMS', selected.length) }}

        <!-- BUTTONS -->
        <div class="pull-right">
          <el-tooltip
            v-show="selected.length > 0"
            effect="dark"
            placement="top"
            :content="activeListsEditButtonTooltip"
            :disabled="!isActiveListWithReplace && selected.length < 2"
          >
            <!-- # edit button # -->
            <span
              v-if="isContactList"
              style="margin-right:5px"
            >
              <el-button
                v-if="selected.length > 0"
                type="primary"
                size="medium"
                class="el-button--primary"
                :disabled="list.updating || selected.length > 1 || isActiveListWithReplace"
                @click="onEditContact()"
              >
                <div class="align-items">
                  <i class="material-icons">edit</i>
                  {{ $t('BUTTON.EDIT') }}
                </div>
              </el-button>
            </span>
          </el-tooltip>
          <!-- # delete button # -->
          <el-button
            type="secondary"
            size="medium"
            :disabled="list.updating"
            @click="showDeleteModal = true"
          >
            <div class="align-items">
              <i class="material-icons">delete</i>
              {{ $t('BUTTON.DELETE') }}
            </div>
          </el-button>
        </div>
      </div>

      <div
        v-else
        key="filters"
        class="list-rows__header-filters"
      >
        <Filters
          :search-enabled="false"
          :selected-filters="selectedFilters"
          @unselect-filter="unselectFilter"
          @unselect-all="unselectAllFilters"
        >
          <!-- Email status -->
          <FilterCascade
            name="channel-status"
            :color="filterChannelStatusColor"
            :values="dataChannelStatus.selected"
            :options="dataChannelStatus.options"
            :filterable="false"
            @updated="updateSelectedChannelStatus"
          />

          <template
            v-slot:rightSide
          >
            <!-- Search -->
            <div class="search">
              <el-input
                v-model="searchQuery"
                class="filter-search-custom"
                :placeholder="$t('LIST_DETAILS.SEARCH_PLACEHOLDER')"
                clearable
                maxlength="255"
                @input.native="typingSearch"
                @clear="clearSearch"
              >
                <i
                  slot="prefix"
                  class="material-icons md-24"
                >
                  search
                </i>
              </el-input>
            </div>

            <!-- Action button -->
            <el-dropdown
              size="default"
              type="primary"
              trigger="click"
              @command="handleCommand"
            >
              <span class="el-dropdown-link">

                {{ $t('LIST_DETAILS.ACTIONS_LIST') }}
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu
                slot="dropdown"
              >
                <el-tooltip
                  effect="dark"
                  placement="top"
                  :content="$t('LIST_DETAILS.ACTIONS.ACTIVE_LISTS_DISABLED_NEW_TOOLTIP')"
                  :disabled="!isActiveListWithReplace"
                >
                  <div v-if="isContactList">
                    <span>
                      <el-dropdown-item
                        command="addOneContact"
                        :disabled="list.updating || !hasCreationPermission || isActiveListWithReplace"
                      >
                        <!-- # edit one # -->
                        <i class="font-15 material-icons">
                          person_add
                        </i>
                        {{ $t('LIST_DETAILS.ACTIONS.IMPORT_ONE') }}
                      </el-dropdown-item>

                    </span>
                  </div>
                </el-tooltip>
                <el-dropdown-item
                  v-if="isContactList"
                  :disabled="list.updating || !hasCreationPermission"
                  command="importFile"
                >
                  <!-- # import file # -->
                  <i class="font-15 material-icons">
                    get_app
                  </i>
                  {{ $t('LIST_DETAILS.ACTIONS.IMPORT_FILE') }}
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="hasCreationPermission && isContactList"
                  command="editColumns"
                  @click="editMode = true"
                >
                  <!-- # edit columns # -->
                  <i class="font-15 material-icons">
                    edit
                  </i>
                  {{ $t('LIST_DETAILS.ACTIONS.EDIT_COLUMNS') }}
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="hasCreationPermission"
                  command="exportList"
                  @click="exportList"
                >
                  <!-- # export # -->
                  <i class="font-15 material-icons">
                    file_upload
                  </i>
                  {{ $t('LIST_DETAILS.ACTIONS.EXPORT') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </Filters>
      </div>
    </transition>

    <!-- LIST ROWS -->
    <div
      class="list-rows__content"
    >
      <!-- DATA -->
      <div
        class="list-rows__table"
      >
        <!-- UPDATING NOTIFICATION -->
        <Notification
          v-if="(listUpdating || listActivating) && isContactList"
          icon="el-icon-refresh"
          :text="$t('LIST_DETAILS.NOTIFICATIONS.UPDATING')"
        />

        <el-table
          v-if="listRows.length && !listLoading"
          ref="list-row-table"
          v-loading="(loading && isUpdating) || (loading && listRows.length !== 0)"
          :data="listRows"
          :disabled="!userCanEdit"
          row-class-name="list-rows__table-row"
          cell-class-name="list-rows__table-cell"
          style="width: 100%"
          :fit="true"
          @selection-change="(val) => selected = val"
        >
          <el-table-column
            v-if="hasCreationPermission"
            type="selection"
            align="center"
            :selectable="userCanEdit"
          />
          <!-- for this column size, we have to adapt it if there is a optin_type_id https://gitlab.numberly.in/team-crm-automation/qa/contactly/-/issues/607 -->
          <el-table-column
            v-for="(field, index) in displayedFields"
            :key="index"
            :min-width="field.optin_type_id ? '320' : '230'"
          >
            <template slot="header">
              <div
                class="list-rows__table-header-cell"
                :class="{ 'is-primary': field.primary_key }"
              >
                <el-tooltip
                  effect="dark"
                  :content="field.name_in_file"
                  placement="top"
                >
                  <div>
                    <span>
                      {{ field.name_in_file }}
                    </span>
                    <span
                      v-show="field.optin_type_id"
                      class="list-rows__table-header-cell__contactability"
                    >
                      {{ displayContactabilityField(field) }}
                    </span>
                    <i
                      v-if="field.primary_key"
                      class="el-icon-key"
                    />
                  </div>
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="scope">
              <div
                :class="{
                  'is-primary': field.primary_key,
                }"
              >
                <span v-if="!isContactabilityStatusFields(field.name_in_db)">
                  {{ scope.row[field.name_in_db] }}
                </span>
                <span v-else>
                  <span
                    class="contactability-status-dot"
                    :style="getContactabilityStatusStyle(field.name_in_db, scope.row[field.name_in_db])"
                  >
                    •
                  </span>
                  {{ formatContactabilityStatusFields(field.name_in_db, scope.row[field.name_in_db]) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="displayedFields && list.type_id === listTypes.SUBMISSION"
            min-width="200"
          >
            <template slot="header">
              <div
                class="list-rows__table-header-cell"
              >
                <div>
                  <span>
                    {{ $t('FIELDS.COLLECTION_DATE') }}
                  </span>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                {{ scope.row.dRecueil | moment('DD MMM YYYY, HH:mm') }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- NO ROWS -->
      <NoData
        v-if="!searchQuery && listRows.length === 0 && !loading && !fetchRowsError && !listLoading"
        type="items"
        :text="$t('LIST_DETAILS.NO_DATA.DEFAULT')"
      />

      <!-- LOADING ROWS -->
      <div
        v-if="(listRows.length === 0 && loading) || listLoading"
        class="list-rows__loader"
      >
        <div>
          <div v-loading="true" />
          <p v-if="loading">
            {{ $t('LIST_DETAILS.FETCHING_ROWS') }}
          </p>
        </div>
      </div>

      <!-- LOADING ROWS ERROR-->
      <div
        v-else-if="fetchRowsError"
        class="list-rows__error"
      >
        <div>
          <i class="material-icons">
            error_outline
          </i>
          <p>
            {{ $t('LIST_DETAILS.FETCHING_ROWS_ERROR') }}
          </p>
          <el-button
            type="text"
            @click="debouncedGetRows()"
          >
            {{ $t('BUTTON.RELOAD') }}
          </el-button>
        </div>
      </div>

      <!-- NO MATCHING ROWS BY SEARCH QUERY -->
      <NoData
        v-if="searchQuery && listRows.length === 0 && !loading && !fetchRowsError"
        type="alternative"
        :text="$t('LIST_DETAILS.NO_DATA.WITH_SEARCH')"
      />
      <!-- PAGINATION -->
      <div
        v-if="rows && rows.meta && listRows.length > 0 && !listLoading"
        class="pagination"
      >
        <div>
          <el-pagination
            :page-size="limit"
            :pager-count="5"
            layout="prev, pager, next"
            :total="rows.meta.total"
            :current-page.sync="page"
            class="text-center"
            @current-change="debouncedGetRows"
          />
        </div>
        <div class="pagination__count">
          <span v-if="page === 1">
            {{ page }}-{{ limit > rows.meta.total ? rows.meta.total : limit }} {{ $t('MISC.OF') }} {{ rows.meta.total }}
          </span>
          <span v-if="page !== 1 && rows.meta.total > limit">
            {{ (page - 1) * limit + 1 }}-{{ page * limit > rows.meta.total ? rows.meta.total : page * limit }} {{ $t('MISC.OF') }} {{ rows.meta.total }}
          </span>
        </div>
      </div>
    </div>

    <!-- EDIT COLUMNS PANEL -->
    <el-drawer
      :title="$t('LIST_DETAILS.EDIT_COLUMNS.TITLE')"
      :visible.sync="editMode"
      direction="rtl"
      size="470px"
      :before-close="handleCloseDrawer"
      class="edit-drawer"
      :destroy-on-close="true"
      :wrapper-closable="false"
    >
      <EditColumnsPanel
        :list="list"
        :visible.sync="editMode"
      />
    </el-drawer>

    <!-- MODAL EXPORT LIST -->
    <ModalExportList
      v-if="list"
      :show-modal.sync="showExportModal"
      :list="list"
      :client-name="user.client.name"
      :filters="getRowsQueryParameters()"
    />

    <!-- MODAL IMPORT CONTACTS -->
    <ModalImportContacts
      v-if="list && showImportModal"
      :show-modal.sync="showImportModal"
      :list="list"
    />
    <ModalEditContact
      v-if="list && showCreateModal"
      :create="!editContact"
      :data="editContact ? selected[0]: {}"
      :list="list"
      :show-modal.sync="showCreateModal"
      @import="importContact"
      @refresh="debouncedGetRows"
    />
  </div>
</template>

<script>
import { debounce as _debounce } from 'lodash-es'
import config from '@/config'
import { orderBy, remove } from 'lodash-es'
import contactabilityStatusColors from '@/assets/styles/variables.scss'
import {
  filterChannelStatus,
} from '@/mixins'
import { Filters, FilterCascade } from '@/components/Common/Form'
import {
  NoData,
  Notification,
  EditColumnsPanel,
  ModalExportList,
  ModalEditContact,
  ModalImportContacts,
  Modal
} from '@/components'

export default {
  name: 'ListRows',
  components: {
    NoData,
    Notification,
    EditColumnsPanel,
    ModalExportList,
    ModalEditContact,
    ModalImportContacts,
    Modal,
    Filters,
    FilterCascade
  },
  mixins: [filterChannelStatus],
  props: {
    search: { type: String, default: () => '' }
  },
  data() {
    return {
      listTypes: config.LIST_TYPES,
      fetchRowsError: false,
      page: 1,
      isUpdating: false,
      editContact: false,
      limit: 10,
      searchQuery: this.search,
      loading: false,
      editMode: false,
      showEditButton: false,
      showExportModal: false,
      showCreateModal: false,
      showImportModal: false,
      displayedFields: null,
      listRows: [],
      typingTimer: null,
      isTyping: false,
      selected: [],
      showDeleteModal: false,
      selectedEmailStatus: [],
      selectedSMSStatus: [],
      dataChannelStatus: {
        options: [],
        selected: []
      },
      dataSMSStatus: {
        options: [],
        selected: []
      },
    }
  },
  computed: {
    activeListsEditButtonTooltip() {
      return this.isActiveListWithReplace ? this.$t('LIST_DETAILS.ACTIONS.ACTIVE_LISTS_DISABLED_UPDATE_TOOLTIP') : this.$t('EDIT_CONTACT.SELECT_ONE_CONTACT')
    },
    user: function () {
      return this.$store.getters['auth/user']
    },
    list () {
      return this.$store.getters['lists/list'] || {}
    },
    listActivating () {
      return (this.$store.getters['lists/list'] && this.listRows.length === 0 ? this.$store.getters['lists/willBeProcess'].includes(this.$store.getters['lists/list'].id): false)
    },
    listUpdating () {
      return (this.$store.getters['lists/list'] ? this.$store.getters['lists/list'].updating : false)
    },
    listLoading () {
      return this.$store.getters['lists/loading']
    },
    rows () {
      return this.$store.getters['lists/contacts'] || {}
    },
    hasCreationPermission () {
      return this.$store.getters['auth/hasPermissionInGroup'](this.list.group_id, 'contactly:role:lists:w') ||
        (this.$store.getters['auth/hasPermissionInGroup'](this.list.group_id, 'contactly:role:lists:w-self') &&
          this.user.id === this.list.created_by)
    },
    isActiveListWithReplace () {
      return this.list.type_id === 2 && this.list.update_type_id === 2
    },
    isContactList () {
      return [this.listTypes.STATIC, this.listTypes.ACTIVE].indexOf(this.list.type_id) > -1
    },
    emailStatus() {
      return Object.keys(config.EMAIL_CONTACTABILITY_STATUS).map(
        t => ({
          id: `EMAIL_${config.EMAIL_CONTACTABILITY_STATUS[t]}`,
          name: this.$t(`CONTACT_DETAILS.CONTACTABILITY.STATUS.EMAIL_${config.EMAIL_CONTACTABILITY_STATUS[t]}`) + ` ${this.$t('MISC.EMAIL')}`
        })
      )
    },
    smsStatus() {
      return Object.keys(config.SMS_CONTACTABILITY_STATUS).map(
        t => ({
          id: `MOBILE_${config.SMS_CONTACTABILITY_STATUS[t]}`,
          name: this.$t(`CONTACT_DETAILS.CONTACTABILITY.STATUS.SMS_${config.SMS_CONTACTABILITY_STATUS[t]}`) + ` ${this.$t('MISC.MOBILE')}`
          })
        )
    },
    selectedFilters () {
      return [
        ...this.dataChannelStatus.selected.map(status => [status[1]]).map((tag, index) => ({
          type: 'channel-status', color: this.filterChannelStatusColor, tag, index
        })),
      ]
    },
    hasEmailContactabilityField () {
      if (this.list && this.list.fields) {
        return this.list.fields.some(e => e.name_in_db == 'EMAIL')
      }
      return false
    },
    hasSMSContactabilityField () {
      if (this.list && this.list.fields) {
        return this.list.fields.some(e => e.name_in_db == 'MOBILE')
      }
      return false
    },
    channelStatus () {
      let channelsData = []
      if (this.hasEmailContactabilityField) {
        channelsData.push({
          "id": "EMAIL",
          "name": this.$t("MISC.EMAIL"),
          "status": this.emailStatus
        })
      }
      if (this.hasSMSContactabilityField) {
        channelsData.push({
          "id": "MOBILE",
          "name": this.$t("MISC.MOBILE"),
          "status": this.smsStatus
        })
      }
      return channelsData
    },
  },
  watch: {
    rows (value) {
      this.updateRows(value.objects)
    },
    list(newValue, oldValue) {
      if (Object.keys(oldValue).length === 0) {
        this.orderFields()
        this.debouncedGetRows()
      }
    },
    listUpdating (value) {
      if (value) {
        this.isUpdating = true
      } else if (this.isUpdating) {
        this.debouncedGetRows()
        this.isUpdating = false
      }
    },
    editMode (value) {
      if (!value) {
        this.handleCloseDrawer()
      }
    }
  },
  created () {
    this.debouncedGetRows = _debounce(() => {
      this.getRows()
    }, 500)
    this.dataChannelStatus = this.initSelectedChannelStatus(this.channelStatus)
    if (this.$route.query.s) {
      this.searchQuery = this.$route.query.s
    }
    if (!this.listLoading) {
        this.debouncedGetRows()
    }
    this.isUpdating = this.list.updating
  },
  methods: {
    orderFields () {
      this.loading=false
      var contactabilityStatusFields = []
      if (this.hasEmailContactabilityField) {
          contactabilityStatusFields.push({
            "displayed": true,
            "displayed_order": -2,
            "name_in_db": "STATUT_EMAIL",
            "name_in_file": this.$t("CONTACT_DETAILS.CONTACTABILITY.STATUS_FIELDS.EMAIL"),
            "optin_type_id": null,
            "primary_key": false,
          })
      }
      if (this.hasSMSContactabilityField) {
          contactabilityStatusFields.push({
            "displayed": true,
            "displayed_order": -1,
            "name_in_db": "STATUT_SMS",
            "name_in_file": this.$t("CONTACT_DETAILS.CONTACTABILITY.STATUS_FIELDS.SMS"),
            "optin_type_id": null,
            "primary_key": false,
          })
      }
      this.displayedFields = orderBy(this.list.fields.concat(contactabilityStatusFields), ['primary_key', 'displayed_order'], ['desc', 'asc'])
      this.displayedFields = remove(this.displayedFields, (item) => {
        return item.displayed
      })
    },
    importContact(listId, queryData, action) {
      this.$store.dispatch('lists/importContacts', { listId, 'data': queryData })
      .then(() => {
        // Notify success
        this.$notify({
          title: this.$t('MISC.SUCCESS'),
          message: action === 'create' ? this.$t('CREATE_CONTACT.SUCCESS') : this.$t('EDIT_CONTACT.SUCCESS'),
          type: 'success'
        })
        this.showCreateModal = false
      })
      .catch((err) => {
        if (err.status === 409) {
          // Notify error
          this.$notify({
            title: this.$t('MISC.ERROR'),
            message: this.$t('ERRORS.IMPORT_IN_PROGRESS'),
            type: 'error'
          })
        }
      })
      .finally(() => {
        this.$refs['list-row-table'].clearSelection()
      })
    },
    onEditContact() {
      this.showCreateModal = true
      this.editContact = true
    },
    typingSearch () {
      this.isTyping = true
      this.page = 1
      const query = this.getRowsQueryParameters()
      this.$router.push({ path: this.$route.path, query: query}).catch(() => {})
      this.debouncedGetRows()
    },
    clearSearch () {
      this.searchQuery = ''
      this.page = 1
      const query = this.getRowsQueryParameters()
      this.$router.push({ path: this.$route.path, query: query}).catch(() => {})
      this.debouncedGetRows()
    },
    displayContactabilityField(field) {
      return this.$t('SETUP.FIELDS.CONTACTABILITY_FIELD', {field: field.name_in_db.toLowerCase()})
    },
    getRows () {
      this.loading = true
      this.fetchRowsError = false


      // format request data
      const params = {
        'page': this.page,
        'limit': this.limit
      }
      if (this.searchQuery !== '') {
        params.search = this.searchQuery
      }

      if (this.dataChannelStatus.selected.length > 0) {
        params.STATUT_EMAIL = this.filterSelectedChannelValues("EMAIL", this.dataChannelStatus.selected)
        params.STATUT_SMS = this.filterSelectedChannelValues("MOBILE", this.dataChannelStatus.selected)
      }
      return this.$store.dispatch('lists/retrieveContacts',
        { listId: this.$route.params.listId, params: params, storeContacts: true })
      .then((res) => {
        this.listRows = res.objects
        this.fetchRowsError = false
      })
      .catch((error) => {
        this.fetchRowsError = error
      })
      .finally(() => {
        this.loading = false
      })
    },
    filterSelectedChannelValues (channel, values) {
      return values.filter((elem) => elem[1].id.startsWith(`${channel}_`)).map(elem => elem[1].id.replace(`${elem[0].id}_`, "")).join(',') || undefined
    },
    updateRows (rows) {
      this.loading = true
      this.orderFields()
      this.listRows = rows
    },
    handleCloseDrawer () {
      this.loading = true
      this.editMode = false
      this.displayedFields = null
      setTimeout(() => {
        this.orderFields()
      }, 200)
    },
    exportList () {
      this.showExportModal = true
    },
    selectImport (type) {
      if (type === 'file') {
        // Modal import rows
        this.showImportModal = true
      } else if (type === 'one'){
        this.showCreateModal = true
        this.editContact = false
      }
    },
    handleCommand (type) {
      switch (type) {
        case 'addOneContact':
          this.showCreateModal = true
          this.editContact = false
          break
        case 'importFile':
          // Modal import rows
          this.showImportModal = true
          break
        case 'editColumns':
          this.editMode = true
          break
        case 'exportList':
          this.exportList()
          break
      }
    },
    userCanEdit () {
      return this.hasCreationPermission
    },
    deleteSelectedRows () {
      let ids = this.selected.map(row => row['Row_id'])
      this.loading = true
      let data = {
        listId: this.$route.params.listId,
        deleteIds: ids
      }
      this.$store.dispatch('lists/bulkDeleteContacts', data)
      .then(() => {
        this.showDeleteModal = false
        // Notify success
        this.$notify({
          title: this.$t('MISC.SUCCESS'),
          message: this.$tc('DELETE_CONTACT.SUCCESS', ids.length),
          type: 'success'
        })
        this.selected = []
        this.debouncedGetRows()
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
        this.loading = false
      })
    },
    isContactabilityStatusFields (fieldDBName) {
      return ["STATUT_EMAIL", "STATUT_SMS"].indexOf(fieldDBName) >= 0
    },
    formatContactabilityStatusFields (fieldDBName, value) {
      if(!value){
        return this.$t(`CONTACT_DETAILS.CONTACTABILITY.STATUS.NULL`)
      }

      if (fieldDBName === "STATUT_EMAIL") {
        return this.$t(`CONTACT_DETAILS.CONTACTABILITY.STATUS.EMAIL_${value}`)
      } else {
        return this.$t(`CONTACT_DETAILS.CONTACTABILITY.STATUS.SMS_${value}`)
      }
    },
    getContactabilityStatusStyle (fieldDBName, value) {
      let channel = fieldDBName === "STATUT_EMAIL" ? "email" : "sms"
      return 'color:' + contactabilityStatusColors[`${channel}ContactabilityStatus${value}`] + ';'
    },
    unselectAllFilters () {
      this.dataChannelStatus.selected = []
      this.$router.push({ path: this.$route.path }).catch(() => {})
      this.debouncedGetRows()
    },
    updateSelectedChannelStatus (values) {
      this.dataChannelStatus.selected = values
      const query = this.getRowsQueryParameters()
      this.$router.push({ path: this.$route.path, query: query}).catch(() => {})
      this.debouncedGetRows()
    },
    unselectFilter (filter) {
      switch (filter.type) {
        case 'channel-status': {
          this.updateSelectedChannelStatus(
            this.dataChannelStatus.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
      }
    },
    getRowsQueryParameters() {
      return {
        's':  this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'status': this.dataChannelStatus.selected.length > 0 ? this.dataChannelStatus.selected.map(elem => elem[1].id).join(',') : undefined,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.list-rows {
  max-width: 100%;
  overflow: hidden;
  margin-top: 2rem;

  ::v-deep &__loader {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    height:250px;
    div p {
      color: $--color-primary;
      font-size: 12px;
    }
    .el-loading-mask {
      top:-45px;
    }
  }

  ::v-deep &__error {
    height:250px;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    > div {
      text-align: center;
      width: 300px;
      i {
        color: $--color-danger;
      }
      p {
        color: $--color-danger;
        font-size: 12px;
        margin-bottom:0;
      }
    }
  }
  &__header-filters,
  &__header-selected {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    & > div:last-child {
      flex-grow: 1;
      text-align: right;
    }
  }
  &__header-selected {
    font-size: $--font-size-extra-small;
    text-transform: uppercase;
    ::v-deep .el-badge {
      margin-right: 5px;
      .el-badge__content {
        display: inline;
        background-color: $--color-primary;
        border-radius: 2px !important;
      }
    }
    .el-button {
      padding-right: 30px;
    }
  }
  &__header-filters {
    .dropdown-arrow.el-dropdown {
      margin: 0 20px;
      display: inline-flex;
      align-items: center;
    }
    .el-link {
      top: -1px;
      &:hover {
        &:after {
          display: none;
        }
      }
    }
  }
  &__content {
    width: 100%;
    overflow: hidden;
    background: $--color-white;
    border: 2px solid $--color-light-gray;
    border-radius: 4px;
    margin: 1.1rem 0 3rem 0;
  }
  &__table {
    min-height: 100px;
    max-width: 100%;
    ::v-deep .el-table {
      th {
        height: 60px;
        .cell {
          @include flexbox;
          @include align-items(center);
          position: absolute;
          height: 100%;
          top: 0;
        }
      }
      &__empty-block {
        display: none;
      }
    }
    .el-dropdown-link {
      cursor: pointer;
    }
    ::v-deep .no-data-container {
      > p {
        margin: 20px auto;
        line-height: normal;
        color: $--text-dark;
      }
    }
  }
  ::v-deep &__table-row {
    padding: 0;
  }
  ::v-deep &__table-cell {
    height: inherit;
    padding: 0;
    position: relative;
    .cell {
      @include flexbox;
      @include align-items(stretch);
      @include justify-content(stretch);
      height: 100%;
      width: 100%;
      > div {
        width: 100%;
        padding: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        span.contactability-status-dot {
          vertical-align: bottom;
          font-size: 27px;
        }
        &.is-primary {
          background: lighten($--color-tertiary, 49%);
        }
        &.is-empty {
          white-space: pre-wrap;
        }
      }
    }
  }
  ::v-deep &__table-header-cell {
    @include flexbox;
    @include align-items(center);
    position: relative;
    line-height: normal;
    white-space: normal;
    padding: 20px 15px 10px;
    width: 100%;
    height: 100%;
    text-overflow: hidden;
    font-weight: 600;
    &__contactability {
        color: $--color-text-secondary;
    }
    .el-tooltip {
      @include flexbox;
    }
    div {
      line-height: normal;
      padding: 0;
    }
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 15px;
    }
    &.is-primary {
      background: lighten($--color-tertiary, 49%);
      position: relative;
      span {
        padding-right: 15px;
        display: block;
      }
      .el-icon-key {
        width: 30px;
        overflow: hidden;
        &::before {
          @include rotate(90);
          display: inline-block;
          font-size: 1.2rem;
          color: $--color-tertiary;
        }
      }
    }
  }
  &__icon {
    transform: rotate(90deg);
  }
}
  /* For mobile: */
@media only screen and (max-width: $--mobile-width) {
  .list-rows {
    &__header {
      display: block;
      margin-bottom: 0;
      @include flexbox;
      @include justify-content(space-between);
      width: 100%;
      padding: 15px 5px;
      .el-link {
        margin: 0
      }
    }
    &__content {
      margin-top: 10px;
    }
  }
}
</style>
