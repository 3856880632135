import { render, staticRenderFns } from "./SetupColumnsMapping.vue?vue&type=template&id=7712b233"
import script from "./SetupColumnsMapping.vue?vue&type=script&lang=js"
export * from "./SetupColumnsMapping.vue?vue&type=script&lang=js"
import style0 from "./SetupColumnsMapping.vue?vue&type=style&index=0&id=7712b233&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports