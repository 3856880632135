<template>
  <!-- STEP 1 UPLOAD FILE -->
  <div
    v-if="step === 1.0"
    class="setup-list-file"
  >
    <el-row
      class="setup-list-file__choices"
      style="width:100%;"
    >
      <div class="info-mandatory">
        <span class="asterisk">*</span> {{ $t('MANDATORY') }}
      </div>
      <div
        class="step-paragraph"
        style="margin-bottom:10px;"
      >
        <span class="asterisk">*</span> {{ $t('SETUP.UPLOAD.FILE_SOURCE_LABEL') }}
      </div>
      <div class="radio-group">
        <div
          class="radio-card"
          :class="{'is-checked': uploadView === 1}"
        >
          <el-radio
            v-model="uploadView"
            :label="1"
            @change="handleImportTypeChange"
          >
            <strong>
              {{ $t('SETUP.UPLOAD.FILE') }}
            </strong>
            <span class="radio-card__label">
              {{ $t('SETUP.UPLOAD.FILE_LABEL') }}
            </span>
          </el-radio>
        </div>

        <div
          class="radio-card"
          :class="{'is-checked': uploadView === 2}"
        >
          <el-radio
            v-model="uploadView"
            :label="2"
            @change="handleImportTypeChange"
          >
            <strong>
              {{ $t('SETUP.UPLOAD.S3') }}
            </strong>
            <span class="radio-card__label">
              {{ $t('SETUP.UPLOAD.S3_LABEL') }}
            </span>
          </el-radio>
        </div>
      </div>
    </el-row>

    <!-- ERRORS -->
    <div v-if="list.errors">
      <div
        v-for="error in list.errors"
        :key="error.error"
      >
        <el-alert
          v-if="error.display_step === step"
          class="setup-list-file__alert"
          :title="$t('SETUP.UPLOAD.' + error.error, error.additional_info)"
          type="error"
          show-icon
          :closable="false"
        />
      </div>
    </div>
    <NeedHelpModal
      :dialog-visible.sync="showHelpModal"
      width="500px"
      :title="$t('SETUP.FILE_REQUIREMENTS')"
      :sections="helpSections"
      :tip="$t('SETUP.FILE_REQUIREMENTS_TIP')"
    />
    <div
      v-if="uploadView === config.UPLOAD_TYPE.LOCAL"
      class="setup-list-file__local"
    >
      <!-- UPLOAD FORM -->
      <div class="step-paragraph">
        <span class="asterisk">*</span>
        {{ $t('SETUP.UPLOAD.TYPE_LABEL') }}
      </div>
      <el-upload
        ref="upload"
        class="setup-list-file__uploader"
        action="REQUIRED_BY_ELEMENT_BUT_USELESS"
        :http-request="uploadFile"
        :disabled="!!fileList.length"
        :limit="1"
        :show-file-list="false"
        :on-change="handleFileChange"
        :on-success="afterUpload"
        :on-error="onFileError"
        :auto-upload="false"
        :file-list="fileList"
        :class="{'selected': fileList.length}"
        accept=".csv, .txt"
        drag
      >
        <div class="uploader-label">
          <div v-if="!fileList.length">
            <span>
              {{ $t('SETUP.UPLOAD.FILE_1') }}
            </span>
            <div class="uploader-label__click">
              {{ $t('SETUP.UPLOAD.FILE_2') }}
            </div>
          </div>
          <div v-else>
            <div style="display: flex; align-items: center; gap: 3px;">
              <i
                class="material-icons"
                style="color: #f47957;"
              >insert_drive_file</i>
              <strong>{{ fileList[0].name }}</strong>
            </div>
            <el-button
              type="text"
              size="default"
              style="font-weight: 400;font-size: 14px"
              @click="removeFile"
            >
              {{ $t('SETUP.UPLOAD.SELECT_ANOTHER_FILE') }}
            </el-button>
          </div>
        </div>
      </el-upload>
      <div
        v-if="!fileList.length"
        slot="tip"
        class="el-upload__tip setup-list-file__label"
      >
        <el-button
          type="text"
          class="need-help"
          @click="showHelpModal = true"
        >
          <i class="material-icons">info_outlined</i>
          <span class="text">{{ $t('MISC.FILE_REQUIREMENTS') }}</span>
        </el-button>
      </div>
      <el-alert
        v-if="showColumnNameTooLongErr"
        class="setup-list-file__alert"
        :title="$t('SETUP.UPLOAD.ERROR_COLUMN_NAME_TOO_LONG')"
        type="error"
        show-icon
        :closable="false"
      />
    </div>


    <div v-else-if="uploadView === config.UPLOAD_TYPE.REMOTE">
      <span class="step-subtitle">
        {{ $t("SETUP.UPLOAD.FILE_FOLDER_TITLE") }}
      </span>
      <br>
      <p class="step-paragraph upload-file-folder">
        {{ $t("SETUP.UPLOAD.FILE_FOLDER_DESCRIPTION") }}
      </p>
      <el-select
        v-model="remoteFilePath"
        class="setup-list-file__s3"
        clearable
        filterable
        placeholder="Select"
        :loading="s3FileListLoading"
        @change="handleS3FileChange"
      >
        <el-option
          v-for="item in s3FileList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div class="setup-list-file__help">
        <el-button
          type="text"
          class="need-help"
          @click="showHelpModal = true"
        >
          <i class="material-icons">info_outlined</i>
          <span class="text">{{ $t('MISC.FILE_REQUIREMENTS') }}</span>
        </el-button>
      </div>
    </div>


    <!-- INFO -->
    <el-alert
      v-if="edition"
      class="setup-list-file__alert info"
      type="warning"
      show-icon
      :closable="false"
    >
      <div slot="title">
        <span>
          {{ $t('IMPORT_CONTACTS.NOTIFICATIONS.ALL_COLUMNS') }}
        </span>
        <el-button
          type="text"
          class="setup-list-file__column-requirements"
          @click="showColumnRequirementModal = true"
        >
          {{ $t('MISC.COLUMN_REQUIREMENTS') }}
        </el-button>
      </div>
    </el-alert>
    <!-- NO FILE SELECTED -->
    <el-alert
      v-if="fileList.length === 0 && noFileSelected"
      class="setup-list-file__alert"
      :title="$t('SETUP.FILE_REQUIRED')"
      type="error"
      show-icon
      :closable="false"
    />

    <!-- DELIMITER FORM -->
    <div
      v-show="showOptions"
      class="setup-list-file__options"
    >
      <el-form
        ref="form"
        label-position="top"
        :validate-on-rule-change="false"
        :rules="rules"
        :model="delimiterForm"
      >
        <div class="flex items-center gap-32">
          <el-form-item
            prop="delimiter"
            class="delimiter-form-item"
          >
            <template v-slot:label>
              <span>{{ $t('SETUP.UPLOAD.DELIMITER_LABEL') }}</span>

              <el-tooltip
                effect="dark"
                placement="top"
                transition=""
                :open-delay="400"
                :hide-after="0"
                :content="$t('SETUP.UPLOAD.DELIMITER_TOOLTIP')"
              >
                <i class="material-icons">help_outlined</i>
              </el-tooltip>
            </template>
            <el-select
              v-model="delimiterForm.delimiter"
              placeholder=""
              style="width: 184px"
              @change="formChange"
            >
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_2')"
                value="&Tab;"
              />
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_3')"
                value=","
              />
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_4')"
                value=";"
              />
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_6')"
                value="|"
              />
              <el-option
                :label="$t('SETUP.UPLOAD.DELIMITER_OPTION_5')"
                value="1"
                @change="chooseOtherDelimiter()"
              />
            </el-select>
            <div
              slot="error"
              class="el-form-item__error"
            >
              {{ $t('SETUP.UPLOAD.ERROR_DELIMITER_EMPTY') }}
            </div>
          </el-form-item>
          <el-form-item
            v-if="isOtherDelimiter()"
            prop="otherDelimiter"
            :label="$t('MISC.CHARACTER')"
            :hide-required-asterisk="true"
          >
            <el-input
              v-model="delimiterForm.otherDelimiter"
              maxlength="1"
              style="width: 200px;"
              @change="formChange"
            />
          </el-form-item>
        </div>

        <el-form-item
          prop="qualifier"
          class="delimiter-form-item"
        >
          <template v-slot:label>
            <span>{{ $t('SETUP.UPLOAD.TEXT_QUALIFIER_LABEL') }}</span>

            <el-tooltip
              effect="dark"
              placement="top"
              transition=""
              :open-delay="400"
              :hide-after="0"
              :content="$t('SETUP.UPLOAD.TEXT_QUALIFIER_TOOLTIP')"
            >
              <i class="material-icons">help_outlined</i>
            </el-tooltip>
          </template>
          <el-input
            v-model="delimiterForm.qualifier"
            maxlength="1"
            @change="formChange"
          />
        </el-form-item>
        <el-form-item
          v-if="action === 'importContacts'"
          prop="updateTypeId"
          style="margin-bottom: 18px;"
          :label="$t('SETUP.UPLOAD.UPDATE_TYPE_LABEL')"
        >
          <el-radio-group
            v-model="delimiterForm.updateTypeId"
            :disabled="isActiveList"
          >
            <el-radio
              :label="1"
            >
              {{ $t('SETUP.SCHEDULE.MODE_1') }}
            </el-radio>
            <br>
            <el-radio
              :label="2"
            >
              {{ $t('SETUP.SCHEDULE.MODE_2') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item style="margin-bottom: 12px;">
          <el-checkbox
            v-model="delimiterForm.HasHeader"
            :label="$t('SETUP.UPLOAD.FIRST_ROW_HEADER_LABEL')"
            :checked="true"
            :disabled="action === 'importContacts'"
            @change="formChange"
          />
        </el-form-item>

        <el-form-item
          v-if="action !== 'importContacts'"
          style="margin-bottom: 10px;"
          class="form-item-wrapper"
        >
          <el-checkbox
            v-model="delimiterForm.expiration"
            :label="$t('SETUP.UPLOAD.EXPIRATION_DATE_LABEL')"
            :checked="false"
            @change="formChange"
          />

          <el-tooltip
            effect="dark"
            placement="top"
            transition=""
            :open-delay="400"
            :hide-after="0"
            :content="$t('SETUP.UPLOAD.EXPIRATION_DATE_TOOLTIP')"
          >
            <i class="material-icons form-item-help">help_outlined</i>
          </el-tooltip>
        </el-form-item>

        <div v-if="delimiterForm.expiration && action !== 'importContacts'">
          <el-row>
            <el-col :span="10">
              <el-form-item style="margin-bottom: 0">
                <el-select
                  v-model="delimiterForm.expirationType"
                  @change="expirationChange"
                >
                  <el-option
                    label="Date"
                    :value="1"
                  />
                  <el-option
                    :label="$t('SETUP.UPLOAD.EXPIRATION_CHOOSE_DAYS')"
                    :value="2"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                v-if="delimiterForm.expirationType == 1"
                style="margin-bottom: 0"
              >
                <el-date-picker
                  v-model="delimiterForm.expirationDate"
                  :picker-options="datePickerOptions"
                  :placeholder="$t('SETUP.UPLOAD.EXPIRATION_DATE_PICKER')"
                  @change="expirationChange"
                />
              </el-form-item>

              <el-form-item
                v-if="delimiterForm.expirationType == 2"
                style="margin-bottom: 0"
              >
                <el-input-number
                  v-model="delimiterForm.expirationDays"
                  placeholder="1"
                  :min="1"
                  @change="expirationChange"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <div
            v-if="delimiterForm.expirationDate && (delimiterForm.expirationType === 2 || delimiterForm.expirationType === '2')"
            class="expire-on-info"
          >
            {{ $t('SETUP.UPLOAD.EXPIRATION_DATE') }} {{ delimiterForm.expirationDate | moment('LL') }}
          </div>
          <div
            v-else-if="(delimiterForm.expirationType === 1 || delimiterForm.expirationType === '1') && delimiterForm.expirationDays"
            class="expire-on-info"
          >
            {{ $t('SETUP.UPLOAD.EXPIRATION_DAYS', { days: delimiterForm.expirationDays }) }}
          </div>
        </div>
      </el-form>
    </div>
    <ModalColumnRequirements
      width="500px"
      :dialog-visible.sync="showColumnRequirementModal"
      :title="$t('SETUP.COLUMN_REQUIREMENTS')"
      @showListColumns="showListColumnsModal = true"
    />
    <ModalListColumns
      width="500px"
      :dialog-visible.sync="showListColumnsModal"
      :title="$t('SETUP.COLUMN_REQUIREMENTS')"
      :list="list"
      @backToColumnRequirements="showColumnRequirementModal = true"
    />
  </div>

  <!-- STEP 1.1 LOADING -->
  <div
    v-else-if="step === 1.1"
    class="setup-loading"
  >
    <LoadingFile class="setup-loading__logo" />
    <div class="setup-loading__title">
      {{ $t('SETUP.UPLOAD.PROCESSING_FILE_TITLE') }}
    </div>
    <div class="setup-loading__description">
      {{ $t('SETUP.UPLOAD.PROCESSING_FILE_DESCRIPTION') }}
    </div>
  </div>
</template>

<script>
import config from '@/config'
import {
  NeedHelpModal,
  LoadingFile
} from '..'
import ModalColumnRequirements from '@/components/Parts/ModalColumnRequirements'
import ModalListColumns from '@/components/Parts/ModalListColumns'

export default {
  name: 'SetupStaticFile',
  components: {
    NeedHelpModal,
    LoadingFile,
    ModalColumnRequirements,
    ModalListColumns
  },
  props: {
    step: { type: Number, required: true },
    list: { type: Object, default: null },
    action: { type: String, default: 'inferColumns' },
    changeDetected: { type: Boolean, default: false },
    edition: { type: Boolean, default: false }
  },
  data: function () {
    return {
      uploadView: config.UPLOAD_TYPE.LOCAL,
      showOptions: false,
      showColumnRequirementModal: false,
      showListColumnsModal: false,
      showColumnNameTooLongErr: false,
      localFilePath: null,
      remoteFilePath: null,
      noFileSelected: false,
      fileList: [],
      rules: {
        delimiter: [{ required: true }],
        qualifier: [{ required: false }],
        HasHeader: [{ required: false }],
        otherDelimiter: [
          {
            required: true,
            message: this.$t('SETUP.UPLOAD.OTHER_DELIMITER_MESSAGE')
          }
        ],
        updateTypeId: [
          {
            required: true,
            message: this.$t('SETUP.UPLOAD.UPDATE_TYPE_MESSAGE'), trigger: 'blur'
          }
        ]
      },
      delimiterForm: {
        expirationDays: null,
      },
      currentFormsValues: {},
      defaultFormValues: {
        delimiter: ',',
        qualifier: '',
        HasHeader: true,
        otherDelimiter: '',
        updateTypeId: '',
        expiration: false,
        expirationType: 1,
      },
      showHelpModal: false,
      helpSections: [{
        'content': this.$t('SETUP.FILE_REQUIREMENTS_INFO')
      }],
      datePickerOptions: {
        disabledDate (date) {
          return date < new Date()
      }
    }
    }
  },
  computed: {
    config () {
      return config
    },
    isActiveList () {
      return this.list.type_id === this.config.LIST_TYPES.ACTIVE
    },
    s3FileList () {
      return this.$store.getters['lists/staticFiles'].map(x => ({ 'value': x, 'label': x.split("/").pop() }))
    },
    s3FileListLoading () {
      return this.$store.getters['lists/staticFilesLoading']
    },
    keepShowingOptions () {
      return (
        (this.fileList.length && this.uploadView === this.config.UPLOAD_TYPE.LOCAL) ||
        (this.remoteFilePath && this.uploadView === this.config.UPLOAD_TYPE.REMOTE)
      )
    },
    expirationDays () {
      return this.delimiterForm.expirationDays
    }
  },
  created: function () {
    // get available static files
    this.$store.dispatch('lists/getStaticFiles', {listId: this.list.id})

    // init forms
    this.resetDelimiterForm(this.config.UPLOAD_TYPE.LOCAL)
    this.resetDelimiterForm(this.config.UPLOAD_TYPE.REMOTE)

    // if list has already a file, init with list's values
    if (this.list.filename_pattern) {
      this.uploadView = this.list.import_type_id
      const filePath = this.list.file_path + '/' + this.list.filename_pattern
      if (this.uploadView === this.config.UPLOAD_TYPE.LOCAL) {
        this.fileList = [{
          name: this.list.filename_pattern.substring(21)  // remove date prefix
        }]
        this.localFilePath = filePath
      } else {
        this.remoteFilePath = filePath
      }

      this.currentFormsValues[this.uploadView].delimiter = this.list.delimiter
      if (this.isOtherDelimiter()) {
        this.isOtherDelimiterSelected = true
        this.currentFormsValues[this.uploadView].otherDelimiter = this.currentFormsValues[this.uploadView].delimiter
        this.currentFormsValues[this.uploadView].delimiter = '1'
      }

      this.currentFormsValues[this.uploadView].qualifier = this.list.text_qualifier || ''
      this.showOptions = true
    }

    if (this.action == 'importContacts') {
      this.currentFormsValues[this.uploadView].updateTypeId = this.list.update_type_id
    }

    this.delimiterForm = this.currentFormsValues[this.uploadView]
    this.delimiterForm.HasHeader = true
    this.delimiterForm.expirationType = 1
  },
  methods: {
    chooseOtherDelimiter () {
      this.currentFormsValues[this.uploadView].delimiter = '1'
      this.delimiterForm.delimiter = '1'
    },
    isOtherDelimiter () {
      return (this.delimiterForm.delimiter &&
      this.delimiterForm.delimiter != ';' &&
      this.delimiterForm.delimiter != ',' &&
      this.delimiterForm.delimiter != '|' &&
      this.delimiterForm.delimiter != '&Tab;')
    },
    handleImportTypeChange () {
      this.formChange()
      this.delimiterForm = {...this.currentFormsValues[this.uploadView]}
      this.showOptions = this.keepShowingOptions ? true : false
    },
    handleNext (triggerSaving) {
      // if a change has been detected or the current step is the list's current step validate the form
      if (!triggerSaving && this.localFilePath || this.remoteFilePath) {
        this.$emit('update:step', 2)
      } else {
        return new Promise(resolve => {
          this.$refs.form.validate((valid) => {
            if (valid) {
              if (this.uploadView === this.config.UPLOAD_TYPE.LOCAL) {
                if (this.fileList.length) {
                  if (!this.localFilePath) {
                    this.$refs.upload.submit()
                  } else {
                    this.afterUpload()
                  }
                }
              } else {
                if (this.remoteFilePath) {
                  this.afterUpload()
                }
              }
            } else {
              this.noFileSelected = true
            }
            resolve(valid)
          })
        })
      }
    },
    resetDelimiterForm (uploadType) {
      this.currentFormsValues[uploadType] = {...this.defaultFormValues}
      if (this.isActiveList) {
        this.currentFormsValues[uploadType].updateTypeId = this.list.update_type_id
      }
      this.delimiterForm = this.currentFormsValues[uploadType]
      if (this.$refs['form']) {
        this.$refs['form'].clearValidate('updateTypeId')
      }
    },
    handleS3FileChange (value) {
      this.remoteFilePath = value
      this.resetDelimiterForm(this.uploadView)
      this.showOptions = true
    },
    handleFileChange: function (file) {
      this.formChange()
      if (file.raw.type == 'text/plain' || file.name.endsWith('.csv')) {
        this.fileList = [file]
        this.showOptions = true
      } else {
        this.fileList = []
        this.showOptions = false
      }
      this.localFilePath = null
      this.noFileSelected = false
      this.resetDelimiterForm(this.uploadView)
    },
    uploadFile: function (file) {
      var listId = this.list.id
      this.showColumnNameTooLongErr = false
      this.localFilePath = null
      let data = {
        'filename': file.file.name
      }
      this.$emit('update:step', 1.1)
      return new Promise((resolve, reject) => {
        this.$store.dispatch('lists/uploadFile', { listId, file, data })
        .then((response) => {
          this.localFilePath = response.fields.key
          resolve(response)
        })
        .catch((response) => {
          this.catchErrors(response, reject)
        })
      })
    },
    removeFile() {
      this.$refs['upload'].clearFiles()
      this.fileList = []
      this.showOptions = false
      this.localFilePath = null
    },
    onFileError: function () {
      // re-init file's status, so we can upload it again
      // else, it gets stuck
      this.$refs.upload.fileList[0].status = 'ready'
    },
    afterUpload: function () {
      var listId = this.list.id
      const filePath = this.uploadView === this.config.UPLOAD_TYPE.LOCAL ? this.localFilePath : this.remoteFilePath
      let data = {
        'delimiter': (this.isOtherDelimiter() ?
                      this.delimiterForm.otherDelimiter :
                      this.delimiterForm.delimiter),
        'file_path': filePath,
        'import_type_id': this.uploadView,
        'has_header': this.delimiterForm.HasHeader,
        'text_qualifier': this.delimiterForm.qualifier.length > 0 ? this.delimiterForm.qualifier : null
    }

      if (this.delimiterForm.expirationDate){
        data["expiration_date"] = this.delimiterForm.expirationDate
      }
      if (this.action === 'importContacts') {
        data['update_type_id'] = this.delimiterForm.updateTypeId
      }

      this.$store.dispatch('lists/' + this.action, { listId, 'data': data })
      .then(() => {
        this.$emit('update:step', 2)
      })
      .catch(this.catchErrors)
    },
    catchErrors: function(response, reject) {
      if (!response.errors) {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      }
      this.$emit('update:step', 1)
      if (response.status == 400 && response.data.message.includes("column's name superior to")) {
        this.showColumnNameTooLongErr = true
      }
      this.fileList = []

      if (reject) {
        reject(response)
      }
    },
    formChange: function() {
      this.$emit('update:change-detected', true)
    },
    expirationChange: function(value) {
      var today = new Date()
      if (!value) {
        this.delimiterForm.expirationDate = null
        this.delimiterForm.expirationDays = null
        return
      }

      if (this.delimiterForm.expirationType == 1 || this.delimiterForm.expirationType == '1') {
        this.delimiterForm = Object.assign({}, this.delimiterForm, { expirationDays: Math.ceil((this.delimiterForm.expirationDate - today) / (1000 * 60 * 60 * 24)) })
      } else {
        today.setDate(today.getDate() + this.delimiterForm.expirationDays)
        this.delimiterForm = Object.assign({}, this.delimiterForm, { expirationDate: today })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--form-width: 616px;

.setup-list-file {
  margin: 50px auto 0 auto;
  width: $--form-width;

  .setup-list-file__local {
    text-align:center;

    .upload-subtitle {
      margin-bottom: 10px;
      font-size: 16px;
      text-align: left;
    }
    ::v-deep .el-upload {
      text-align: center;
      width: 100%;
      height: 101px;

      &-dragger {
        display: flex;
        width: inherit;
        height: inherit;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: transparent;
        margin-top: 10px;
        .uploader-label {
          div {
            margin-top: 10px;
            color: $--color-black;
            .uploader-label__click {
              color: $--color-primary !important;
            }
          }
        }
      }
    }
  }
  .step-subtitle, .step-paragraph {
    text-align: left;
    font-size: 14px;
    color: #606266;
  }


  .step-paragraph.upload-file-folder {
    color: $--color-gray;
  }

  .setup-list-file {
    &__s3 {
      width:100%;
    }
    &__help {
      display: flex;
      justify-content: center;
    }
  }

  .setup-list-file__choices {
    margin-bottom: 30px;
    width: 100%;

    ::v-deep .el-radio-group {
      width: 100%;
    }
    ::v-deep .el-radio-button {
      width: 50%;
    }

    ::v-deep .el-radio-button__inner {
      width: 100%;
    }
  }

  .setup-list-file__alert {
    text-align: left;
    word-break: normal;
    margin-bottom: 30px;
    margin-top: 30px;
    &.info {
      line-height: 0px;
      padding-top: 15px;
    }
  }

  ::v-deep .setup-list-file__uploader .el-upload-list__item-name {
    font-size: 12px;
  }

  ::v-deep .setup-list-file__uploader .el-upload-dragger {
    font-size: 14px;
  }


  .setup-list-file__button {
    margin-top: 20px;
    width: 120px;
    border-radius: 2px;
  }

  .setup-list-file__options {
    text-align: left;
    margin-top: 30px;

    .el-radio {
      margin-bottom:8px;
    }

  }

  .el-button.setup-list-file__column-requirements {
    text-decoration: underline;
    color: $--color-warning;
    font-size: 13px;
  }

  .radio-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .radio-card {
      width: calc(50% - 12px);
      border-radius: 5px;
      height: 140px;
      .el-radio {
        padding-top: 15px;
      }
    }
  }
  .asterisk {
    color: #F47957;
  }

  .info-mandatory {
    font-size: 14px;
    color: #606266;
    text-align: right;
    margin-bottom: 3px;
  }
  .radio-card {
    width: calc(50% - 12px);
    border-radius: 5px;
    height: 205px;
  }
  .el-radio__label strong {
    font-size: 14px;
    color: #303133;
  }
  .radio-card__label {
    margin-top: 12px;
    font-size: 13px;
    padding: 0 20px 20px;
    height: inherit;
    color: #606266;
  }

  ::v-deep .el-form-item__label {
    font-size: 14px;
    color: #606266;
  }
}

.expire-on-info {
  margin-top: 12px;
  color: #909399;
}

.setup-loading {
  padding-top: 20px;
}

::v-deep .el-form .el-form-item .el-form-item__label,
::v-deep .form-item-wrapper .el-form-item__content {
  display: flex;
  align-items: center;
}
::v-deep .form-item-wrapper .el-form-item__content {
  margin-bottom: 2px;
}

.el-form-item__label .material-icons,
.form-item-help {
  font-size: 18px;
  margin-left: 3px;
  color: #909399;
  width: 18px;
}

.delimiter-form-item {
  max-width: 184px;
}
</style>
