import Vue from 'vue'
import i18n from '../languages/i18n'

Vue.filter('formatNumber', function (value) {
  const num = Number(value)
  if (isNaN(num)) {
    return value
  }

  return new Intl.NumberFormat(i18n.locale ? i18n.locale : 'en-US').format(
    num,
  )
})
